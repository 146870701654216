//
// variables
//
const TopEnv = {};
const CoreEnv = {};

//
// Nil
// javascript representation of empty list( '() )
//
const nil = {
  toString: function () {
    return "nil";
  },
  to_write: function () {
    return "()";
  },
  to_array: function () {
    return [];
  },
  length: function () {
    return 0;
  },
};

//
// #<undef> (The undefined value)
// also used as #<unspecified> values
//
const undef = new Object();
undef.toString = function () {
  return "#<undef>";
};

//
// Configurations
//

// Maximum depth of stack trace
// (You can also set Interpreter#max_trace_size for each Interpreter)
const max_trace_size = 40;

// Stop showing deprecation warning
const suppress_deprecation_warning = false;

// Actual values are set by rollup (see rollup.config.js)
const VERSION = "0.8.0";
const GitCommit = "1a7b75fad71bb75b53a95c65336a18c5f1559fe9";

// utility functions to replace underscore equivalents

// https://underscorejs.org/docs/modules/uniqueId.html
var idCounter = 0;
function uniqueId(prefix) {
  var id = ++idCounter + "";
  return prefix ? prefix + id : id;
}

// https://underscorejs.org/docs/modules/escape.html
const escapeMap = {
  "&": "&amp;",
  "<": "&lt;",
  ">": "&gt;",
  '"': "&quot;",
  "'": "&#x27;",
  "`": "&#x60;",
};

function escape(string) {
  return Object.entries(escapeMap).reduce(
    (string, [target, sub]) => string.replaceAll(target, sub),
    string
  );
}

//
// Scheme symbols
//

const Symbols = {};

class BiwaSymbol {
  constructor(str) {
    this.name = str;
    Symbols[str] = this;
  }

  inspect() {
    return "'" + this.name;
    //return "#<Symbol '"+this.name+"'>";
  }

  toString() {
    return "'" + this.name;
  }

  to_write() {
    return this.name;
  }
}

const Sym = function (name, leaveCase) {
  if (Symbols[name] === undefined) {
    return new BiwaSymbol(name);
  } else if (!(Symbols[name] instanceof BiwaSymbol)) {
    //pre-defined member (like 'eval' in Firefox)
    return new BiwaSymbol(name);
  } else {
    return Symbols[name];
  }
};

const gensym = function (prefix = "__gensym") {
  return Sym(uniqueId(prefix));
};

//
// pause object (facility to stop/resume interpreting)
//
class Pause {
  //new (on_pause: javascript function calling setTimeout, Ajax.Request, ..)
  constructor(on_pause) {
    this.on_pause = on_pause;
  }

  //save state of interpreter
  set_state(intp, x, f, c, s) {
    this.interpreter = intp;
    this.x = x;
    this.f = f;
    this.c = c;
    this.s = s;
  }

  //call this when ready (to fire setTimeout, Ajax.Request..)
  ready() {
    this.on_pause(this);
  }

  //restart calculation
  resume(value) {
    return this.interpreter.resume(true, value, this.x, this.f, this.c, this.s);
  }
}

//
// Port
//

// (eof-object)
const eof = new Object();

class Port {
  constructor(is_in, is_out) {
    this.is_open = true;
    this.is_binary = false; //??
    this.is_input = is_in;
    this.is_output = is_out;
  }

  close() {
    // close port
    this.is_open = false;
  }

  inspect() {
    return "#<Port>";
  }

  to_write() {
    return "#<Port>";
  }
}

//
// string ports (srfi-6)
//
Port.StringOutput = class extends Port {
  constructor() {
    super(false, true);
    this.buffer = [];
  }

  put_string(str) {
    this.buffer.push(str);
  }

  output_string(str) {
    return this.buffer.join("");
  }
};

Port.StringInput = class extends Port {
  constructor(str) {
    super(true, false);
    this.str = str;
  }

  get_string(after) {
    return after(this.str);
  }
};

Port.NullInput = class extends Port {
  constructor() {
    super(true, false);
  }

  get_string(after) {
    // Never give them anything!
    return after("");
  }
};

Port.NullOutput = class extends Port {
  constructor(output_function) {
    super(false, true);
    this.output_function = output_function;
  }

  put_string(str) {}
};

Port.CustomOutput = class extends Port {
  constructor(output_function) {
    super(false, true);
    this.output_function = output_function;
  }

  put_string(str) {
    this.output_function(str);
  }
};

Port.CustomInput = class extends Port {
  constructor(input_function) {
    super(true, false);
    this.input_function = input_function;
  }

  get_string(after) {
    var input_function = this.input_function;
    return new Pause(function (pause) {
      input_function(function (input) {
        pause.resume(after(input));
      });
    });
  }
};

// User must set the current input/output
Port.current_input = new Port.NullInput();
Port.current_output = new Port.NullOutput();
Port.current_error = new Port.NullOutput();

// A Scheme lambda
class Closure {
  // body: Intermediate Language (JS array)
  // freevars: Captured free variables
  // dotpos: The position of `.` in the parameter list. -1 if none
  // expected_args: Expected number of args or `undefined`
  constructor(body, freevars, dotpos, expected_args) {
    this.body = body;
    this.freevars = freevars;
    this.dotpos = dotpos;
    this.expected_args = expected_args;
  }

  to_write() {
    return "#<Closure>";
  }
}

const isClosure = function (obj) {
  return obj instanceof Closure;
};

//

const isNil = function (obj) {
  return obj === nil;
};

const isUndef = function (obj) {
  return obj === undef;
};

const isBoolean = (s) => typeof s === "boolean"; // Return true if arg is either true or false

//isNumber is defined in number.js (Return true if arg is scheme number)

const isString = (s) => typeof s === "string";

const isFunction = (s) => typeof s === "function";

const isSymbol = function (obj) {
  return obj instanceof BiwaSymbol;
};

const isPort = function (obj) {
  return obj instanceof Port;
};

const isVector = function (obj) {
  return obj instanceof Array;
};

// procedure: Scheme closure or JavaScript function
// valid argument for anywhere function is expected
const isProcedure = function (obj) {
  return isClosure(obj) || typeof obj === "function";
};

//
// comaprator
//
// Return true when a < b
const lt = function (a, b) {
  if (typeof a !== typeof b) {
    return compareFn(typeof a, typeof b);
  }
  return a < b;
};

//
// Errors
//

class BiwaError extends Error {
  constructor(msg, form = null) {
    const info = form === null ? "" : `: ${to_write$1(form)}`;
    const message = `${msg}${info}`;
    super(message);
    this.form = form;
  }

  toString() {
    return this.message;
  }
}

class Bug extends BiwaError {
  constructor(msg) {
    super("[BUG] " + msg);
  }
}

// currently used by "raise"
class UserError extends BiwaError {
  constructor(msg) {
    this.message = msg;
  }
}

//
// Set - set of string
// contents must be string (or at least sortable)
//
class BiwaSet {
  constructor(/*args*/) {
    this.arr = [];
    var i;
    for (i = 0; i < arguments.length; i++) this.arr[i] = arguments[i];
  }

  equals(other) {
    if (this.arr.length != other.arr.length) return false;

    var a1 = [...this.arr];
    var a2 = [...other.arr];
    a1.sort();
    a2.sort();
    for (var i = 0; i < this.arr.length; i++) {
      if (a1[i] != a2[i]) return false;
    }
    return true;
  }

  set_cons(item) {
    var o = new BiwaSet(item);
    o.arr = [...this.arr];
    o.arr.push(item);
    return o;
  }

  set_union(/*args*/) {
    var o = new BiwaSet();
    o.arr = [...this.arr];

    for (var k = 0; k < arguments.length; k++) {
      var s2 = arguments[k];
      if (!(s2 instanceof BiwaSet))
        throw new BiwaError("set_union: arguments must be a set");

      for (var i = 0; i < s2.arr.length; i++) o.add(s2.arr[i]);
    }
    return o;
  }

  set_intersect(s2) {
    if (!(s2 instanceof BiwaSet))
      throw new BiwaError("set_intersect: arguments must be a set");

    var o = new BiwaSet();
    for (var i = 0; i < this.arr.length; i++)
      if (s2.member(this.arr[i])) o.add(this.arr[i]);
    return o;
  }

  set_minus(s2) {
    if (!(s2 instanceof BiwaSet))
      throw new BiwaError("set_minus: arguments must be a set");

    var o = new BiwaSet();
    for (var i = 0; i < this.arr.length; i++)
      if (!s2.member(this.arr[i])) o.add(this.arr[i]);
    return o;
  }

  add(item) {
    if (!this.member(item)) {
      this.arr.push(item);
    }
  }

  member(item) {
    for (var i = 0; i < this.arr.length; i++)
      if (this.arr[i] == item) return true;

    return false;
  }

  rindex(item) {
    for (var i = this.arr.length - 1; i >= 0; i--)
      if (this.arr[i] == item) return this.arr.length - 1 - i;

    return null;
  }

  index(item) {
    for (var i = 0; i < this.arr.length; i++) if (this.arr[i] == item) return i;

    return null;
  }

  inspect() {
    return "Set(" + this.arr.join(", ") + ")";
  }

  toString() {
    return this.inspect();
  }

  size() {
    return this.arr.length;
  }
}

//
// Pair
// cons cell
//

class Pair {
  constructor(car, cdr) {
    this.car = car;
    this.cdr = cdr;
  }

  // Returns `this.car.car`. If `err` is given and `this.car` is not a pair,
  // throws `err`.
  caar(err) {
    return this._get(["car", "car"], err);
  }
  cadr(err) {
    return this._get(["cdr", "car"], err);
  }
  cdar(err) {
    return this._get(["car", "cdr"], err);
  }
  cddr(err) {
    return this._get(["cdr", "cdr"], err);
  }
  _get(props, err = "unexpected") {
    let x = this;
    for (const p of props) {
      if (x.hasOwnProperty(p)) {
        x = x[p];
      } else {
        throw err;
      }
    }
    return x;
  }

  first() {
    return this.car;
  }
  second() {
    return this.cdr.car;
  }
  third() {
    return this.cdr.cdr.car;
  }
  fourth() {
    return this.cdr.cdr.cdr.car;
  }
  fifth() {
    return this.cdr.cdr.cdr.cdr.car;
  }

  // returns array containing all the car's of list
  // '(1 2 3) => [1,2,3]
  // '(1 2 . 3) => [1,2]
  to_array() {
    var ary = [];
    for (var o = this; o instanceof Pair; o = o.cdr) {
      ary.push(o.car);
    }
    return ary;
  }

  length() {
    var n = 0;
    for (var o = this; o instanceof Pair; o = o.cdr) {
      n++;
    }
    return n;
  }

  // Return the last cdr
  last_cdr() {
    var o;
    for (o = this; o instanceof Pair; o = o.cdr);
    return o;
  }

  // calls the given func passing each car of list
  // returns cdr of last Pair
  forEach(func) {
    for (var o = this; o instanceof Pair; o = o.cdr) {
      func(o.car);
    }
    return o;
  }

  // Alias of `forEach` (for backward compatibility)
  foreach(func) {
    for (var o = this; o instanceof Pair; o = o.cdr) {
      func(o.car);
    }
    return o;
  }

  // Returns an array which contains the resuls of calling func
  // with the car's as an argument.
  // If the receiver is not a proper list, the last cdr is ignored.
  // The receiver must not be a cyclic list.
  map(func) {
    var ary = [];
    for (var o = this; isPair(o); o = o.cdr) {
      ary.push(func(o.car));
    }
    return ary;
  }

  // Returns a new list made by applying `func` to each element
  mapList(func) {
    return array_to_list(this.map(func));
  }

  async mapAsync(func) {
    const ary = [];
    for (var o = this; isPair(o); o = o.cdr) {
      ary.push(await func(o.car));
    }
    return array_to_list(ary);
  }

  // Destructively concat the given list to the receiver.
  // The receiver must be a proper list.
  // Returns the receiver.
  concat(list) {
    var o = this;
    while (o instanceof Pair && o.cdr != nil) {
      o = o.cdr;
    }
    o.cdr = list;
    return this;
  }

  // returns human-redable string of pair
  inspect(conv) {
    conv || (conv = inspect);
    var a = [];
    var last = this.foreach(function (o) {
      a.push(conv(o));
    });
    if (last != nil) {
      a.push(".");
      a.push(conv(last));
    }
    return "(" + a.join(" ") + ")";
  }

  toString() {
    return this.inspect();
  }

  to_display(to_display) {
    return this.inspect(to_display);
  }

  to_write() {
    return this.inspect(to_write$1);
  }
}
// Note: '() is not a pair in scheme
const isPair = function (obj) {
  return obj instanceof Pair;
};

// Returns true if obj is a proper list
// Note: isList returns true for '()
const isList = function (obj) {
  if (obj === nil) {
    // Empty list
    return true;
  }
  if (!(obj instanceof Pair)) {
    // Argument isn't even a pair
    return false;
  }

  var tortoise = obj;
  var hare = obj.cdr;
  while (true) {
    if (hare === nil) {
      // End of list
      return true;
    }
    if (hare === tortoise) {
      // Cycle
      return false;
    }
    if (!(hare instanceof Pair)) {
      // Improper list
      return false;
    }

    if (hare.cdr === nil) {
      // End of list
      return true;
    }
    if (!(hare.cdr instanceof Pair)) {
      // Improper list
      return false;
    }

    hare = hare.cdr.cdr;
    tortoise = tortoise.cdr;
  }
};

// Creates a list out of the arguments, optionally converting any nested arrays into nested lists if the deep argument is true.
// Example:
//   BiwaScheme.List(1, 2, [3, 4]) ;=> (list 1 2 (vector 3 4))
//   BiwaScheme.deep_array_to_list(1, 2, [3, 4]) ;=> (list 1 2 (list 3 4))
const array_to_list_ = function (ary, deep) {
  var list = nil;
  for (var i = ary.length - 1; i >= 0; i--) {
    var obj = ary[i];
    if (deep && Array.isArray(obj) && !obj.is_vector) {
      obj = array_to_list_(obj, deep);
    }
    list = new Pair(obj, list);
  }
  return list;
};

// Shallow: List(1, 2, [3]) == (list 1 2 (vector 3 4))
const List = function () {
  var ary = Array.from(arguments);
  return array_to_list_(ary, false);
};

// Shallow: array_to_list(1, 2, [3]) == (list 1 2 (vector 3 4))
const array_to_list = function (ary) {
  return array_to_list_(ary, false);
};

// Deep: deep_array_to_list(1, 2, [3, 4]) == (list 1 2 (list 3 4))
// deep_array_to_list([1, 2, 3]) - deep
const deep_array_to_list = function (ary) {
  return array_to_list_(ary, true);
};

const Cons = function (car, cdr) {
  return new Pair(car, cdr);
};

const js_obj_to_alist = function (obj) {
  if (obj === undefined) {
    return nil;
  }
  var arr = [];
  Object.keys(obj).forEach(function (key) {
    arr.push(new Pair(key, obj[key]));
  });
  var alist = array_to_list(arr);
  return alist;
};

const alist_to_js_obj = function (alist) {
  if (alist === nil) {
    return {};
  }
  var obj = {};
  alist.foreach(function (item) {
    obj[item.car] = item.cdr;
  });
  return obj;
};

//
// _writer.js: Functions to convert objects to strings
//

// Truncate long string with '...'
const truncate = function (str, length) {
  const truncateStr = "...";
  return str.length > length ? str.slice(0, length) + truncateStr : str;
};

//
// write
//

const write_simple = function (obj) {
  if (obj === undefined) return "undefined";
  else if (obj === null) return "null";
  else if (typeof obj === "function")
    return (
      "#<Function " +
      (obj.fname
        ? obj.fname
        : obj.toSource
        ? truncate(obj.toSource(), 40)
        : "") +
      ">"
    );
  else if (typeof obj === "string")
    return (
      '"' +
      obj
        .replace(/\\|\"/g, function ($0) {
          return "\\" + $0;
        })
        .replace(/\x07/g, "\\a")
        .replace(/\x08/g, "\\b")
        .replace(/\t/g, "\\t")
        .replace(/\n/g, "\\n")
        .replace(/\v/g, "\\v")
        .replace(/\f/g, "\\f")
        .replace(/\r/g, "\\r") +
      '"'
    );
  else if (Array.isArray(obj))
    return (
      "#(" +
      obj
        .map(function (e) {
          return write_simple(e);
        })
        .join(" ") +
      ")"
    );
  else if (typeof obj.to_write == "function") return obj.to_write();
  else if (isNaN(obj) && typeof obj == "number") return "+nan.0";
  else {
    switch (obj) {
      case true:
        return "#t";
      case false:
        return "#f";
      case Infinity:
        return "+inf.0";
      case -Infinity:
        return "-inf.0";
    }
  }
  return inspect(obj);
};

//
// display
//

const to_display = function (obj) {
  if (typeof obj === "undefined") return "undefined";
  else if (obj === null) return "null";
  else if (obj.to_display) return obj.to_display(to_display);
  else if (typeof obj.valueOf() == "string") return obj;
  else if (obj instanceof BiwaSymbol) return obj.name;
  else if (obj instanceof Array)
    return "#(" + obj.map(to_display).join(" ") + ")";
  else return write_simple(obj);
};

//
// inspect (for debugging)
//

const inspect = function (object, opts) {
  try {
    if (typeof object === "undefined") return "undefined";
    if (object === null) return "null";
    if (object === true) return "#t";
    if (object === false) return "#f";
    if (object.inspect) return object.inspect();
    if (typeof object === "string") {
      return '"' + object.replace(/"/g, '\\"') + '"';
    }
    if (Array.isArray(object)) {
      return "[" + object.map(inspect).join(", ") + "]";
    }

    if (opts && opts["fallback"]) {
      return opts["fallback"];
    } else {
      return object.toString();
    }
  } catch (e) {
    if (e instanceof RangeError) return "...";
    throw e;
  }
};

//
// write/ss (write with substructure)
//

// Uses datum label if cyclic. Otherwise does not
function write(obj) {
  const wstate = _preprocess(obj);
  if (wstate.cyclic) {
    return _write_shared(obj, wstate);
  } else {
    return write_simple(obj);
  }
}

function _preprocess(obj) {
  const state = {
    objs: new Set(),
    shared_objs: new Set(),
    parents: new Set(),
    cyclic: false,
  };
  _gather_information(obj, state);

  // Create initial writer state
  const ids = new Map();
  for (const o of state.shared_objs) {
    ids.set(o, null);
  }
  const wstate = {
    ids: ids,
    last_id: -1,
    cyclic: state.cyclic,
  };
  return wstate;
}

function _gather_information(obj, state) {
  if (state.parents.has(obj)) {
    // Already seen and this is a cyclic object
    state.cyclic = true;
  }
  if (state.shared_objs.has(obj)) {
    return;
  } else if (state.objs.has(obj)) {
    state.shared_objs.add(obj);
    return;
  }
  // Found a new object
  state.objs.add(obj);
  if (isPair(obj)) {
    state.parents.add(obj);
    _gather_information(obj.car, state);
    _gather_information(obj.cdr, state);
    state.parents.delete(obj);
  } else if (isVector(obj)) {
    state.parents.add(obj);
    obj.forEach((item) => {
      _gather_information(item, state);
    });
    state.parents.delete(obj);
  }
}

// Always use datum label
function write_shared(obj) {
  const wstate = _preprocess(obj);
  return _write_shared(obj, wstate);
}

function _write_shared(obj, wstate) {
  let s = "";
  if (wstate.ids.has(obj)) {
    const id = wstate.ids.get(obj);
    if (id === null) {
      // First occurrence of a shared object; Give it a number
      const new_id = wstate.last_id + 1;
      wstate.ids.set(obj, new_id);
      wstate.last_id = new_id;
      s += `#${new_id}=`;
    } else {
      // Already printed. Just show the reference
      return `#${id}#`;
    }
  }
  if (isPair(obj)) {
    const a = [];
    // Note that we cannot use obj.forEach (because it does not stop)
    a.push(_write_shared(obj.car, wstate));
    for (let o = obj.cdr; o !== nil; o = o.cdr) {
      if (!isPair(o) || wstate.ids.has(o)) {
        a.push(".");
        a.push(_write_shared(o, wstate));
        break;
      }
      a.push(_write_shared(o.car, wstate));
    }
    s += "(" + a.join(" ") + ")";
  } else if (isVector(obj)) {
    const a = obj.map((item) => _write_shared(item, wstate));
    s += "#(" + a.join(" ") + ")";
  } else {
    s += write_simple(obj);
  }
  return s;
}

const to_write$1 = write;

//
// equality
//
const eq = function (a, b) {
  return a === b;
};

// TODO: Records (etc.)
const eqv = function (a, b) {
  return a == b && typeof a == typeof b;
};

const equal = function (a, b) {
  return to_write$1(a) == to_write$1(b);
};

//
// Char
//

const Chars = {};

class Char {
  constructor(c) {
    Chars[(this.value = c)] = this;
  }

  to_write() {
    switch (this.value) {
      case "\n":
        return "#\\newline";
      case " ":
        return "#\\space";
      case "\t":
        return "#\\tab";
      default:
        return "#\\" + this.value;
    }
  }

  to_display() {
    return this.value;
  }

  inspect() {
    return this.to_write();
  }
}
Char.get = function (c) {
  if (typeof c != "string") {
    throw new Bug("Char.get: " + inspect(c) + " is not a string");
  }
  if (Chars[c] === undefined) return new Char(c);
  else return Chars[c];
};

const isChar = function (obj) {
  return obj instanceof Char;
};

// The class Call is used to invoke scheme closure from
// library functions.
//
// Call#constructor takes three arguments: proc, args and after.
//   * proc is the scheme closure to invoke.
//   * args is an Array (not list!) of arguments for the invocation.
//   * after is a javascript function which is invoked when
//     returned from the proc.
//
//     after takes two arguments: ar and intp.
//       * ar is an Array which contains the result of the invocation.
//       * intp is an Interpreter which is running.
//
//     If after returns another Call object, another invocation
//     happens. If after returns a normal value, it is the value
//     of the library function.
//
// example:
//   return new Call(proc, [x, y], function(ar){ ar[0] });
//
class Call {
  constructor(proc, args, after) {
    this.proc = proc;
    this.args = args;
    this.after =
      after ||
      function (ar) {
        // just return result which closure returned
        return ar[0];
      };
  }

  inspect() {
    return "#<Call args=" + this.args.inspect() + ">";
  }

  toString() {
    return "#<Call>";
  }

  to_write() {
    return "#<Call>";
  }
}

//
// Iterator - external iterator for Call.foreach
//
const Iterator = {
  ForArray: class {
    constructor(arr) {
      this.arr = arr;
      this.i = 0;
    }
    has_next() {
      return this.i < this.arr.length;
    }
    next() {
      return this.arr[this.i++];
    }
  },

  ForString: class {
    constructor(str) {
      this.str = str;
      this.i = 0;
    }
    has_next() {
      return this.i < this.str.length;
    }
    next() {
      return Char.get(this.str.charAt(this.i++));
    }
  },

  ForList: class {
    constructor(ls) {
      this.ls = ls;
    }
    has_next() {
      return this.ls instanceof Pair && this.ls != nil;
    }
    next() {
      var pair = this.ls;
      this.ls = this.ls.cdr;
      return pair;
    }
  },

  ForMulti: class {
    constructor(objs) {
      this.objs = objs;
      this.size = objs.length;
      this.iterators = objs.map(function (x) {
        return Iterator.of(x);
      });
    }
    has_next() {
      for (var i = 0; i < this.size; i++)
        if (!this.iterators[i].has_next()) return false;

      return true;
    }
    next() {
      return this.iterators.map(function (ite) {
        return ite.next();
      });
    }
  },

  of: function (obj) {
    switch (true) {
      case obj instanceof Array:
        return new this.ForArray(obj);
      case typeof obj == "string":
        return new this.ForString(obj);
      case obj instanceof Pair:
      case obj === nil:
        return new this.ForList(obj);
      default:
        throw new Bug("Iterator.of: unknown class: " + inspect(obj));
    }
  },
};

//
// Call.foreach - shortcut for successive Calls
//
// Some library functions, such as for-each or map,
// call a closure for each element. Call.foreach is
// a utility to help defining such methods.
//
// Call.foreach takes a sequence and some callbacks.
// Sequence is an Array, String, or list.
//
// Example:
//   return Call.foreach(sequence, {
//     // before each call
//     call: function(elem){
//       return new Call(proc, [elem]);
//     },
//     // after each call
//     result: function(value, elem){
//       ary.push(value);
//       // you can return a value to terminate the loop
//     },
//     // after all the calls
//     finish: function(){
//       return ary;
//     }
//   });

Call.default_callbacks = {
  call: function (x) {
    return new Call(this.proc, [x]);
  },
  result: function () {},
  finish: function () {},
};
Call.foreach = function (obj, callbacks, is_multi) {
  is_multi || (is_multi = false);
  ["call", "result", "finish"].forEach(function (key) {
    if (!callbacks[key]) callbacks[key] = Call.default_callbacks[key];
  });

  var iterator = null;
  var x = null;

  var loop = function (ar) {
    if (iterator) {
      var ret = callbacks["result"](ar[0], x);
      if (ret !== undefined) return ret;
    } else {
      // first lap
      if (is_multi) iterator = new Iterator.ForMulti(obj);
      else iterator = Iterator.of(obj);
    }

    if (!iterator.has_next()) {
      return callbacks["finish"]();
    } else {
      x = iterator.next();
      var result = callbacks["call"](x);
      result.after = loop;
      return result;
    }
  };
  return loop(null);
};
Call.multi_foreach = function (obj, callbacks) {
  return Call.foreach(obj, callbacks, true);
};

//
// Syntax
//
class Syntax {
  constructor(sname, func) {
    this.sname = sname;
    this.func = func;
  }

  transform(x) {
    if (!this.func) {
      throw new Bug("sorry, syntax " + this.sname + " is a pseudo syntax now");
    }
    return this.func(x);
  }

  inspect() {
    return "#<Syntax " + this.sname + ">";
  }
}

// A built-in syntax did not have associated Syntax object.
// Following code installed dummy Syntax objects to built-in syntax.
CoreEnv["define"] = new Syntax("define");
CoreEnv["begin"] = new Syntax("begin");
CoreEnv["quote"] = new Syntax("quote");
CoreEnv["lambda"] = new Syntax("lambda");
CoreEnv["if"] = new Syntax("if");
CoreEnv["set!"] = new Syntax("set!");

// A compiled Scheme expression
class VMCode {
  // il: Intermediate Language (JS array)
  constructor(il) {
    if (!isVector(il)) {
      console.error(il);
      throw "not array";
    }
    this.il = il;
  }

  to_write() {
    return "#<VMCode>";
  }
}

///
/// Compiler
///
/// Note: macro expansion is done by Intepreter#expand

class Compiler {
  constructor() {}

  is_tail(x) {
    return x[0] == "return";
  }

  //free: set
  //e: env(= [locals, frees])
  //next: opc
  //ret: opc["refer_*", n, ["argument",
  //          ["refer_*", n, ... ["argument", next]
  collect_free(free, e, next) {
    var vars = free;
    var opc = next;
    var arr = vars.arr;
    for (var i = 0; i < arr.length; i++) {
      opc = this.compile_refer(arr[i], e, ["argument", opc]);
    }
    //Console.puts("collect_free "+free.inspect()+" / "+e.inspect()+" => "+opc.inspect());
    return opc;
  }

  //x: Symbol
  //e: env [set of locals, set of frees]
  //ret: opc
  compile_refer(x, e, next) {
    return this.compile_lookup(
      x,
      e,
      function (n) {
        return ["refer-local", n, next];
      },
      function (n) {
        return ["refer-free", n, next];
      },
      function (sym) {
        return ["refer-global", sym, next];
      }
    );
  }

  compile_lookup(x, e, return_local, return_free, return_global) {
    var locals = e[0],
      free = e[1];
    var n;
    if ((n = locals.index(x)) != null) {
      //Console.puts("compile_refer:"+x.inspect()+" in "+e.inspect()+" results refer-local "+n);
      return return_local(n);
    } else if ((n = free.index(x)) != null) {
      //Console.puts("compile_refer:"+x.inspect()+" in "+e.inspect()+" results refer-free "+n);
      return return_free(n);
    } else {
      var sym = x.name;
      return return_global(sym);
    }
    //throw new BiwaError("undefined symbol `" + sym + "'");
  }

  //generate boxing code (intersection of sets & vars)
  //if no need of boxing, just returns next
  //  sets(Set): assigned variables
  //  vars(List): used variables
  //  next(opc):
  //  ret(opc):
  make_boxes(sets, vars, next) {
    var vars = vars;
    var n = 0;
    var a = [];
    while (vars instanceof Pair) {
      if (sets.member(vars.car)) a.push(n);
      n++;
      vars = vars.cdr;
    }
    var opc = next;
    for (var i = a.length - 1; i >= 0; i--) opc = ["box", a[i], opc];
    return opc;
  }

  // Enumerate variables which (could be assigned && included in v)
  // x: exp
  // v: set(vars)
  // ret: set
  find_sets(x, v) {
    //Console.puts("find_sets: " + to_write(x) + " " + to_write(v))
    var ret = null;
    if (x instanceof BiwaSymbol) {
      ret = new BiwaSet();
    } else if (x instanceof Pair) {
      switch (x.first()) {
        case Sym("define"):
          var exp = x.third();
          ret = this.find_sets(exp, v);
        case Sym("begin"):
          ret = this.find_sets(x.cdr, v); //(ignores improper list)
          break;
        case Sym("quote"):
          ret = new BiwaSet();
          break;
        case Sym("lambda"):
          var vars = x.second(),
            body = x.cdr.cdr;
          if (vars instanceof Pair) {
            // (lambda (...) ...)
            ret = this.find_sets(body, v.set_minus(to_set(vars)));
          } else {
            // (lambda args ...)
            ret = this.find_sets(body, v.set_minus(new BiwaSet(vars)));
          }
          break;
        case Sym("if"):
          var testc = x.second(),
            thenc = x.third(),
            elsec = x.fourth();
          ret = this.find_sets(testc, v).set_union(
            this.find_sets(thenc, v),
            this.find_sets(elsec, v)
          );
          break;
        case Sym("set!"):
          var vari = x.second(),
            xx = x.third();
          if (v.member(vari)) ret = this.find_sets(xx, v).set_cons(vari);
          else ret = this.find_sets(xx, v);
          break;
        case Sym("call/cc"):
          var exp = x.second();
          ret = this.find_sets(exp, v);
          break;
        default:
          var set = new BiwaSet();
          for (var p = x; p instanceof Pair; p = p.cdr) {
            set = set.set_union(this.find_sets(p.car, v));
          }
          ret = set;
          break;
      }
    } else {
      ret = new BiwaSet();
    }

    if (ret == null) throw new Bug("find_sets() exited in unusual way");
    else return ret;
  }

  // find_free(): find free variables in x
  //              these variables are collected by collect_free().
  // x: expression
  // b: set of local vars (= variables which are not free)
  // f: set of free var candidates
  //    (local vars of outer lambdas)
  // ret: set of free vars
  find_free(x, b, f) {
    var ret = null;
    if (x instanceof BiwaSymbol) {
      if (f.member(x)) ret = new BiwaSet(x);
      else ret = new BiwaSet();
    } else if (x instanceof Pair) {
      switch (x.first()) {
        case Sym("define"):
          var exp = x.third();
          ret = this.find_free(exp, b, f);
          break;
        case Sym("begin"):
          ret = this.find_free(x.cdr, b, f); //(ignores improper list)
          break;
        case Sym("quote"):
          ret = new BiwaSet();
          break;
        case Sym("lambda"):
          var vars = x.second(),
            body = x.cdr.cdr;
          if (vars instanceof Pair) {
            // (lambda (...) ...)
            ret = this.find_free(body, b.set_union(to_set(vars)), f);
          } else {
            // (lambda args ...)
            ret = this.find_free(body, b.set_cons(vars), f);
          }
          break;
        case Sym("if"):
          var testc = x.second(),
            thenc = x.third(),
            elsec = x.fourth();
          ret = this.find_free(testc, b, f).set_union(
            this.find_free(thenc, b, f),
            this.find_free(elsec, b, f)
          );
          break;
        case Sym("set!"):
          var vari = x.second(),
            exp = x.third();
          if (f.member(vari)) ret = this.find_free(exp, b, f).set_cons(vari);
          else ret = this.find_free(exp, b, f);
          break;
        case Sym("call/cc"):
          var exp = x.second();
          ret = this.find_free(exp, b, f);
          break;
        default:
          var set = new BiwaSet();
          for (var p = x; p instanceof Pair; p = p.cdr) {
            set = set.set_union(this.find_free(p.car, b, f));
          }
          ret = set;
          break;
      }
    } else {
      ret = new BiwaSet();
    }
    //Console.p("find_free "+x.inspect()+" / "+b.inspect()+" => "+ret.inspect());

    if (ret == null) throw new Bug("find_free() exited in unusual way");
    else return ret;
  }

  // Returns the position of the dot pair.
  // Returns -1 if x is a proper list.
  //
  // eg. (a b . c) -> 2
  find_dot_pos(x) {
    var idx = 0;
    for (; x instanceof Pair; x = x.cdr, ++idx);
    if (x != nil) {
      return idx;
    } else {
      return -1;
    }
  }

  last_pair(x) {
    if (x instanceof Pair) {
      for (; x.cdr instanceof Pair; x = x.cdr);
    }
    return x;
  }

  // Takes an dotted list and returns proper list.
  //
  // eg. (x y . z) -> (x y z)
  dotted2proper(ls) {
    if (ls === nil) return nil;

    var nreverse = function (ls) {
      var res = nil;
      for (; ls instanceof Pair; ) {
        var d = ls.cdr;
        ls.cdr = res;
        res = ls;
        ls = d;
      }
      return res;
    };
    var copy_list = function (ls) {
      var res = nil;
      for (; ls instanceof Pair; ls = ls.cdr) {
        res = new Pair(ls.car, res);
      }
      return nreverse(res);
    };

    if (ls instanceof Pair) {
      var last = this.last_pair(ls);
      if (last instanceof Pair && last.cdr === nil) {
        return ls;
      } else {
        var copied = copy_list(ls);
        this.last_pair(copied).cdr = new Pair(last.cdr, nil);
        return copied;
      }
    } else {
      return new Pair(ls, nil);
    }
  }

  // x: exp(list of symbol or integer or..)
  // e: env (= [locals, frees])
  // s: vars might be set!
  // next: opc
  // ret: opc
  compile(x, e, s, f, next) {
    //Console.p(x);
    var ret = null;

    while (1) {
      if (x instanceof BiwaSymbol) {
        // Variable reference
        // compiled into refer-(local|free|global)
        return this.compile_refer(
          x,
          e,
          s.member(x) ? ["indirect", next] : next
        );
      } else if (x instanceof Pair) {
        switch (x.first()) {
          case Sym("define"):
            ret = this._compile_define(x, next);

            x = ret[0];
            next = ret[1];
            break;

          case Sym("begin"):
            var a = [];
            for (var p = x.cdr; p instanceof Pair; p = p.cdr) a.push(p.car);

            //compile each expression (in reverse order)
            var c = next;
            for (var i = a.length - 1; i >= 0; i--) {
              c = this.compile(a[i], e, s, f, c);
            }
            return c;

          case Sym("quote"):
            if (x.length() < 2)
              throw new BiwaError("Invalid quote: " + x.to_write());

            var obj = x.second();
            return ["constant", obj, next];

          case Sym("lambda"):
            return this._compile_lambda(x, e, s, f, next);

          case Sym("if"):
            if (x.length() < 3 || x.length() > 4)
              throw new BiwaError("Invalid if: " + x.to_write());

            var testc = x.second(),
              thenc = x.third(),
              elsec = x.fourth();
            var thenc = this.compile(thenc, e, s, f, next);
            var elsec = this.compile(elsec, e, s, f, next);
            x = testc;
            next = ["test", thenc, elsec];
            break;

          case Sym("set!"):
            // error-checking: should have only 3 things
            if (x.length() != 3)
              throw new BiwaError("Invalid set!: " + x.to_write());

            var v = x.second(),
              x = x.third();
            var do_assign = this.compile_lookup(
              v,
              e,
              function (n) {
                return ["assign-local", n, next];
              },
              function (n) {
                return ["assign-free", n, next];
              },
              function (sym) {
                return ["assign-global", sym, next];
              }
            );
            next = do_assign;
            break;

          case Sym("call/cc"):
            var x = x.second();
            var arity_of_arg = 1; // Always 1. (lambda (cc) ...)
            var c = [
              "conti",
              this.is_tail(next) ? e[0].size() + 1 : 0, //number of args for outer lambda
              [
                "argument", // Push the continuaion closure onto the stack
                [
                  "constant",
                  arity_of_arg,
                  [
                    "argument",
                    this.compile(
                      x,
                      e,
                      s,
                      f,
                      this.is_tail(next)
                        ? ["shift", arity_of_arg, ["tco_hinted_apply"]]
                        : ["apply"]
                    ),
                  ],
                ],
              ],
            ];

            // Do not push stack frame when call/cc is in a tail context
            return this.is_tail(next) ? c : ["frame", c, next];

          default:
            //apply
            //x = (func 1 2)
            //x.car = func = '(lambda (x) ..) or Symbol
            //x.cdr = args = '(1 2)
            var func = x.car;
            var args = x.cdr;
            var c = this.compile(
              func,
              e,
              s,
              f,
              this.is_tail(next)
                ? ["shift", args.length(), ["tco_hinted_apply"]]
                : ["apply"]
            );

            // VM will push the number of arguments to the stack.
            c = this.compile(args.length(), e, s, f, ["argument", c]);
            for (var p = args; p instanceof Pair; p = p.cdr) {
              c = this.compile(p.car, e, s, f, ["argument", c]);
            }

            // Do not push stack frame for tail calls
            return this.is_tail(next) ? c : ["frame", c, next];
        }
      } else {
        return ["constant", x, next];
      }
    }
    //Console.p("result of " + x.inspect() + ":");
    //Console.p(ret);
    //dump({"ret":ret, "x":x, "e":e, "s":s, "next":next, "stack":[]});
    //      if(ret == null)
    //        throw new Bug("compile() exited in unusual way");
    //      else
    //        return ret;
  }

  // Compile define.
  //
  // 0. (define) ; => error
  // 1. (define a)
  // 2. (define a 1)
  // 3. (define a 1 2) ; => error
  // 4. (define (f x) x), (define (f . a) a)
  // 5. (define 1 2)
  //
  // Note: define may appear in lambda, let, let*, let-values,
  // let*-values, letrec, letrec*. These definitions are local to the
  // <body> of these forms.
  _compile_define(x, next) {
    if (x.length() == 1) {
      // 0. (define)
      throw new BiwaError("Invalid `define': " + x.to_write());
    }

    var first = x.cdr.car;
    var rest = x.cdr.cdr;

    if (first instanceof BiwaSymbol) {
      if (rest === nil) {
        // 1. (define a)
        x = undef;
      } else {
        if (rest.cdr === nil)
          // 2. (define a 1)
          x = rest.car;
        // 3. (define a 1 2)
        else throw new BiwaError("Invalid `define': " + x.to_write());
      }

      if (!TopEnv.hasOwnProperty(first.name)) {
        TopEnv[first.name] = undef;
      }
      next = ["assign-global", first.name, next];
    } else if (first instanceof Pair) {
      // 4. (define (f x) ...)
      // Note: define of this form may contain internal define.
      // They are handled in compilation of "lambda".

      var fname = first.car,
        args = first.cdr;
      var lambda = new Pair(Sym("lambda"), new Pair(args, rest));
      x = lambda;
      if (!TopEnv.hasOwnProperty(first.name)) {
        TopEnv[fname.name] = undef;
      }
      next = ["assign-global", fname.name, next];
    } else {
      // 5. (define 1 2)
      throw new BiwaError("define: symbol or pair expected but got " + first);
    }

    return [x, next];
  }

  // Compiles various forms of "lambda".
  //
  // * (lambda (x y) ...)
  // * (lambda (x y . rest) ...)
  // * (lambda args ...)
  _compile_lambda(x, e, s, f, next) {
    if (x.length() < 3) throw new BiwaError("Invalid lambda: " + x.to_write());

    var vars = x.cdr.car;
    var body = x.cdr.cdr;

    // Handle internal defines
    var tbody = Compiler.transform_internal_define(body);
    if (isPair(tbody) && isSymbol(tbody.car) && tbody.car.name == "letrec*") {
      // The body has internal defines.
      // Expand letrec* macro
      var cbody = Compiler.expand(tbody);
    } else {
      // The body has no internal defines.
      // Just wrap the list with begin
      var cbody = new Pair(Sym("begin"), x.cdr.cdr);
    }

    var dotpos = this.find_dot_pos(vars);
    var proper = this.dotted2proper(vars);
    var free = this.find_free(cbody, to_set(proper), f); //free variables
    var sets = this.find_sets(cbody, to_set(proper)); //local variables

    var do_body = this.compile(
      cbody,
      [to_set(proper), free],
      sets.set_union(s.set_intersect(free)),
      f.set_union(to_set(proper)),
      ["return"]
    );
    var do_close = [
      "close",
      vars instanceof Pair ? vars.length() : 0,
      free.size(),
      this.make_boxes(sets, proper, do_body),
      next,
      dotpos,
    ];
    return this.collect_free(free, e, do_close);
  }

  run(expr) {
    const opc = this.compile(
      expr,
      [new BiwaSet(), new BiwaSet()],
      new BiwaSet(),
      new BiwaSet(),
      ["halt"]
    );
    return new VMCode(opc);
  }
}
// Compile an expression with new compiler
Compiler.compile = function (expr, next) {
  expr = Compiler.expand(expr);
  return new Compiler().run(expr, next);
};

// Expand macro calls in a expression recursively.
//
// x - expression
// flag - used internally. do not specify this
//
// @throws {BiwaError} when x has syntax error
Compiler.expand = function (x, flag /*optional*/) {
  var expand = Compiler.expand;
  flag || (flag = {});
  var ret = null;

  if (x instanceof Pair) {
    switch (x.car) {
      case Sym("define"):
        var left = x.cdr.car,
          exp = x.cdr.cdr;
        ret = new Pair(Sym("define"), new Pair(left, expand(exp, flag)));
        break;
      case Sym("begin"):
        ret = new Pair(Sym("begin"), expand(x.cdr, flag));
        break;
      case Sym("quote"):
        ret = x;
        break;
      case Sym("lambda"):
        var vars = x.cdr.car,
          body = x.cdr.cdr;
        ret = new Pair(Sym("lambda"), new Pair(vars, expand(body, flag)));
        break;
      case Sym("if"):
        var testc = x.second(),
          thenc = x.third(),
          elsec = x.fourth();
        ret = List(
          Sym("if"),
          expand(testc, flag),
          expand(thenc, flag),
          expand(elsec, flag)
        );
        break;
      case Sym("set!"):
        var v = x.second(),
          x = x.third();
        ret = List(Sym("set!"), v, expand(x, flag));
        break;
      case Sym("call-with-current-continuation"):
      case Sym("call/cc"):
        var x = x.second();
        ret = List(Sym("call/cc"), expand(x, flag));
        break;
      default:
        //apply
        var transformer = null;
        if (isSymbol(x.car)) {
          if (TopEnv[x.car.name] instanceof Syntax)
            transformer = TopEnv[x.car.name];
          else if (CoreEnv[x.car.name] instanceof Syntax)
            transformer = CoreEnv[x.car.name];
        }

        if (transformer) {
          flag["modified"] = true;
          ret = transformer.transform(x);

          //            // Debug
          //            var before = to_write(x);
          //            var after = to_write(ret);
          //            if(before != after){
          //              console.log("before: " + before)
          //              console.log("expand: " + after)
          //            }

          var fl;
          for (;;) {
            ret = expand(ret, (fl = {}));
            if (!fl["modified"]) break;
          }
        } else {
          var expanded_car = expand(x.car, flag);
          var expanded_cdr;
          if (!(x.cdr instanceof Pair) && x.cdr !== nil) {
            throw new BiwaError(
              "proper list required for function application " +
                "or macro use: " +
                to_write(x)
            );
          }
          expanded_cdr = array_to_list(
            x.cdr.to_array().map(function (item) {
              return expand(item, flag);
            })
          );
          ret = new Pair(expanded_car, expanded_cdr);
        }
    }
  } else {
    ret = x;
  }
  return ret;
};

// Transform internal defines to letrec*.
//
// Example
//   (let ((a 1))
//     (define (b) a)
//     (b))
//
//   (let ((a 1))
//     (letrec* ((b (lambda () a)))
//       (b)))
//
// x - expression starts with (define ...)
//
// Returns a letrec* expression, or
// just returns x, when x does not contain definitions.

// Returns true if x is a definition
var is_definition = function (x) {
  return isPair(x) && Sym("define") === x.car;
  // TODO: support "begin", nested "begin", "let(rec)-syntax"
};

// Convert function definition to lambda binding
//   (define a ..)         -> (a ..)
//   (define (f) ..)       -> (f (lambda () ..))
//   (define (f x . y) ..) -> (f (lambda (x . y) ..))
//   (define (f . a) ..)   -> (f (lambda a ..))
var define_to_lambda_bind = function (def) {
  var sig = def.cdr.car;
  var body = def.cdr.cdr;

  if (isSymbol(sig)) {
    var variable = sig;

    return new Pair(variable, body);
  } else {
    var variable = sig.car;
    var value = new Pair(Sym("lambda"), new Pair(sig.cdr, body));

    return List(variable, value);
  }
};

Compiler.transform_internal_define = function (x) {
  // 1. Split x into definitions and expressions
  var defs = [],
    item = x;
  while (is_definition(item.car)) {
    defs.push(item.car);
    item = item.cdr;
  }
  var exprs = item;

  // 2. Return x if there is no definitions
  if (defs.length == 0) return x;

  // 3. Return (letrec* <bindings> <expressions>)
  var bindings = List.apply(null, defs.map(define_to_lambda_bind));
  return new Pair(Sym("letrec*"), new Pair(bindings, exprs));
};

// Convert a list to BiwaSet
function to_set(ls) {
  if (ls === nil) {
    return new BiwaSet();
  } else {
    var set = new BiwaSet();
    for (var o = ls; o instanceof Pair; o = o.cdr) {
      set.add(o.car);
    }
    return set;
  }
}

//
// assertions - type checks
//

const make_assert = function (check) {
  return function (/*args*/) {
    // We cannot use callee/caller in ESM (=JS strict mode)
    //var fname = arguments.callee.caller
    //              ? arguments.callee.caller.fname
    //              : "";
    const fname = "";
    check.apply(this, [fname].concat(Array.from(arguments)));
  };
};

const make_simple_assert = function (type, test, _fname) {
  return make_assert(function (fname, obj, opt) {
    const option = opt ? "(" + opt + "): " : "";
    if (!test(obj)) {
      throw new BiwaError(
        option + type + " required, but got " + to_write$1(obj)
      );
    }
  });
};

//
// Hashtable
//
// TODO: Reimplement with JavaScript Map
//
// Based on the base JavaScript Object class, but
//  * Object takes only strings as keys
//  * R6RS hashtable needs its own hash function
// so some hacks are needed.

class Hashtable {
  constructor(_hash_proc, _equiv_proc, mutable) {
    this.mutable = mutable === undefined ? true : mutable ? true : false;

    this.hash_proc = _hash_proc;
    this.equiv_proc = _equiv_proc;

    // Hash (hashed) => (array of (key and value))
    this.pairs_of = {};
  }

  clear() {
    this.pairs_of = {};
  }

  candidate_pairs(hashed) {
    return this.pairs_of[hashed];
  }

  add_pair(hashed, key, value) {
    var pairs = this.pairs_of[hashed];

    if (pairs) {
      pairs.push([key, value]);
    } else {
      this.pairs_of[hashed] = [[key, value]];
    }
  }

  remove_pair(hashed, pair) {
    var pairs = this.pairs_of[hashed];
    var i = pairs.indexOf(pair);
    if (i == -1) {
      throw new Bug("Hashtable#remove_pair: pair not found!");
    } else {
      pairs.splice(i, 1); //remove 1 element from i-th index
    }
  }

  create_copy(mutable) {
    var copy = new Hashtable(this.hash_proc, this.equiv_proc, mutable);
    // clone the pairs to copy
    Object.keys(this.pairs_of).forEach((hashed) => {
      let pairs = this.pairs_of[hashed];
      let cloned = pairs.map((pair) => [...pair]);
      copy.pairs_of[hashed] = cloned;
    });

    return copy;
  }

  size() {
    var n = 0;
    this._apply_pair(function (pair) {
      n++;
    });
    return n;
  }

  keys() {
    return this._apply_pair(function (pair) {
      return pair[0];
    });
  }

  values() {
    return this._apply_pair(function (pair) {
      return pair[1];
    });
  }

  _apply_pair(func) {
    var a = [];
    Object.values(this.pairs_of).forEach(function (pairs) {
      pairs.forEach(function (pair) {
        a.push(func(pair));
      });
    });
    return a;
  }

  to_write() {
    return "#<Hashtable size=" + this.size() + ">";
  }
}

const isHashtable = function (obj) {
  return obj instanceof Hashtable;
};

const isMutableHashtable = function (obj) {
  return obj instanceof Hashtable && obj.mutable;
};

//
// Hash functions
//

Hashtable.equal_hash = function (ar) {
  return to_write$1(ar[0]);
};
Hashtable.eq_hash = Hashtable.equal_hash;
Hashtable.eqv_hash = Hashtable.equal_hash;

Hashtable.string_hash = function (ar) {
  return ar[0];
};

Hashtable.string_ci_hash = function (ar) {
  return typeof ar[0] === "string" ? ar[0].toLowerCase() : ar[0];
};

Hashtable.symbol_hash = function (ar) {
  return ar[0] instanceof BiwaSymbol ? ar[0].name : ar[0];
};

//
// Equivalence functions
//

Hashtable.eq_equiv = function (ar) {
  return eq(ar[0], ar[1]);
};

Hashtable.eqv_equiv = function (ar) {
  return eqv(ar[0], ar[1]);
};

//
// number.js
//

//
// Complex
//
class Complex {
  constructor(real, imag) {
    this.real = real;
    this.imag = imag;
  }

  magnitude() {
    return Math.sqrt(this.real * this.real + this.imag * this.imag);
  }

  angle() {
    return Math.atan2(this.imag, this.real);
  }

  isReal() {
    return this.imag == 0;
  }

  isRational() {
    return this.imag == 0 && isRational(this.real);
  }

  isInteger() {
    return this.imag == 0 && isInteger(this.real);
  }

  toString(radix) {
    if (this.real === 0 && this.imag === 0) return "0";
    var img = "";
    if (this.imag !== 0) {
      if (this.imag > 0 && this.real !== 0) {
        img += "+";
      }
      switch (this.imag) {
        case 1:
          break;
        case -1:
          img += "-";
          break;
        default:
          img += this.imag.toString(radix);
      }
      img += "i";
    }
    var real = "";
    if (this.real !== 0) {
      real += this.real.toString(radix);
    }
    return real + img;
  }
}

Complex.from_polar = function (r, theta) {
  var real = r * Math.cos(theta);
  var imag = r * Math.sin(theta);
  return new Complex(real, imag);
};

Complex.assure = function (num) {
  if (num instanceof Complex) return num;
  else return new Complex(num, 0);
};

//
// Rational (unfinished)
//
class Rational$1 {
  constructor(numerator, denominator) {
    this.numerator = numerator;
    this.denominator = denominator;
  }

  isInteger() {
    // FIXME
  }
}

//
// Predicates
//
const isNumber = function (x) {
  return (
    x instanceof Complex || x instanceof Rational$1 || typeof x == "number"
  );
};
const isComplex = isNumber;

const isReal = function (x) {
  if (x instanceof Complex || x instanceof Rational$1) {
    return x.isReal();
  } else {
    return typeof x == "number";
  }
};

const isRational = function (x) {
  if (x instanceof Complex) {
    return x.isRational();
  } else if (x instanceof Rational$1) {
    return true;
  } else {
    return typeof x == "number";
  }
};

const isInteger = function (x) {
  if (x instanceof Complex || x instanceof Rational$1) {
    return x.isInteger();
  } else {
    return typeof x == "number" && x % 1 == 0;
  }
};

// Raised when input is not terminated
class Unterminated extends BiwaError {}

// Allowed digits in each base
const DIGITS = {
  2: /^[01]+/,
  8: /^[0-7]+/,
  10: /^[0-9]+/,
  16: /^[0-9a-fA-F]+/,
};

// Matching parenthesis/bracket for lists
const PAREN = {
  "(": ")",
  "{": "}",
  "[": "]",
};

// Named characters like `#\newline`
const NAMED_CHARS = {
  alarm: "\x07",
  backspace: "\x08",
  delete: "\x7F",
  escape: "\x1B",
  newline: "\n",
  null: "\x00",
  return: "\x0D",
  space: " ",
  tab: "\t",
};
const NAMED_CHARS_REXP = new RegExp(
  "^(" + Object.keys(NAMED_CHARS).join("|") + ")\\b"
);

// Escape sequences like `\n`, `\t`
const ESCAPE_SEQUENCES = {
  a: "\x07",
  b: "\x08",
  t: "\t",
  n: "\n",
  r: "\x0D",
};

class Parser {
  constructor(txt) {
    // Scheme source text
    this.txt = txt;
    // Current position
    this.i = 0;
    // Case fold flag. Changed by `#!(no-)fold-case` directives in Scheme source text
    this.foldCase = false;
    // For datum labels (`#1=`)
    this.labelledData = [];
  }

  // Inject scheme program into current position
  insert(txt) {
    const orig = this.txt;
    this.txt = orig.slice(0, this.i) + txt + orig.slice(this.i);
  }

  // Returns string representation of `this` for debugging
  inspect() {
    return `#<Parser (${this.i}/${this.txt.length})>`;
  }

  // Read a Scheme value and returns it.
  // Returns `Parser.EOS` if there is no more.
  // Throws `Parser.Unterminated` if the source text ends with an unterminated
  // list, etc.
  getObject() {
    this._skipAtmosphere();
    let ret;
    if (this.done()) {
      ret = Parser.EOS;
    } else {
      switch (this.txt[this.i]) {
        case "#":
          this.i++;
          ret = this._parseSharp();
          break;
        case "(":
        case "[":
        case "{":
          ret = this._parseList();
          break;
        case '"':
          ret = this._parseString();
          break;
        case "|":
          ret = this._parseEnclosedSymbol();
          break;

        // Aliases
        case "'":
          this.i++;
          ret = this._parseQuote("quote");
          break;
        case "`":
          this.i++;
          ret = this._parseQuote("quasiquote");
          break;
        case ",":
          this.i++;
          if (this.txt[this.i] == "@") {
            this.i++;
            ret = this._parseQuote("unquote-splicing");
          } else {
            ret = this._parseQuote("unquote");
          }
          break;

        default:
          ret = this._parseDecimalNumberOrIdentifier();
          break;
      }
    }
    //console.log("getObject", inspect(ret))
    return ret;
  }

  _getObjectOrThrowUnterminated(msg) {
    const v = this.getObject();
    if (v === Parser.EOS) throw new Unterminated(msg);
    return v;
  }

  // Skip whitespaces
  _skipWhitespace() {
    while (this.i < this.txt.length) {
      switch (this.txt[i]) {
        case " ":
        case "\t":
        case "\n":
          i++;
          break;
        default:
          return;
      }
    }
  }

  // Skip whitespace, comment and directive
  // Note: calling this method may change parser state (`this.foldCase`).
  _skipAtmosphere() {
    while (this.i < this.txt.length) {
      switch (this.txt[this.i]) {
        // Whitespace
        case " ":
        case "\t":
        case "\n":
          this.i++;
          break;

        // Line comment
        case ";":
          const m = this.match(/^;[^\n]*(\n|$)/);
          this.i += m[0].length;
          break;

        // Sexp/multiline comment and directives
        case "#":
          if (this.txt[this.i + 1] == ";") {
            this.i += "#;".length;
            this._skipAtmosphere();
            // Drop the value after the `#;` and continue
            this._getObjectOrThrowUnterminated("missing argument for `#;`");
          } else if (this.txt[this.i + 1] == "|") {
            this.i += "#|".length;
            this._skipBlockComment();
          } else if (this.match(/^#!fold-case/)) {
            this.i += "#!fold-case".length;
            this.foldCase = true;
          } else if (this.match(/^#!no-fold-case/)) {
            this.i += "#!no-fold-case".length;
            this.foldCase = false;
          } else {
            return;
          }
          break;

        default:
          return;
      }
    }
  }

  // Skip block comment (`#|...|#`)
  // `#|` must be consumed beforehand
  _skipBlockComment() {
    let level = 1;
    while (this.i < this.txt.length) {
      const mEnd = this.match(/\|#/);
      if (mEnd === null) {
        break;
      }
      // Check nested comment
      const mBegin = /#\|/.exec(this.txt.slice(this.i, mEnd.index));
      if (mBegin) {
        level++;
        this.i += mBegin.index + "#|".length;
        continue;
      } else {
        this.i += mEnd.index + "|#".length;
        level--;
        if (level == 0) {
          // Found matching `|#` for all `#|`s.
          return;
        }
      }
    }
    throw new Unterminated(
      "unterminated block comment (`|#` not found)",
      this.rest()
    );
  }

  // Parse quote, backquote, unquote, unquote-splicing
  _parseQuote(name) {
    this._skipAtmosphere();
    const v = this._getObjectOrThrowUnterminated(`unterminated ${name}`);
    return List(Sym(name), v);
  }

  // Parse stuffs starting with `#` (except those parsed with _skipAtmosphere)
  _parseSharp() {
    switch (this.txt[this.i]) {
      case "t":
        if (this.match(/^true/)) {
          this.i += "true".length;
        } else {
          this.i++;
        }
        return true;
      case "f":
        if (this.match(/^false/)) {
          this.i += "false".length;
        } else {
          this.i++;
        }
        return false;
      case "\\":
        this.i++;
        return this._parseChar();
      case "(":
        this.i++;
        return this._parseVector();
      case "u":
        if (this.match(/^u8\(/)) {
          throw new BiwaError(
            "bytevectors are not supported yet",
            this.rest(-1)
          );
        } else {
          break;
        }
      case "e":
      case "i":
      case "b":
      case "o":
      case "d":
      case "x":
        this.i--; // Unget `#`
        return this._parsePrefixedNumber();
      default:
        if (this.match(/^\d/)) {
          return this._parseDatumLabel();
        } else {
          break;
        }
    }
    throw new BiwaError("unknown #-syntax", this.rest(-1));
  }

  // Parse a character literal after `#\`
  _parseChar() {
    let m = this.match(NAMED_CHARS_REXP);
    if (m) {
      this.i += m[0].length;
      return Char.get(NAMED_CHARS[m[1]]);
    }
    m = this.match(/^x([a-zA-Z0-9]+)/);
    if (m) {
      this.i += m[0].length;
      return Char.get(String.fromCharCode(parseInt(m[1], 16)));
    }
    if (this.done()) {
      throw new Unterminated("got EOS on char literal", this.rest(-2));
    } else {
      const c = this.txt[this.i];
      this.i++;
      return Char.get(c);
    }
  }

  // Parse a vector expression after `#(`
  _parseVector() {
    const begin = this.i;
    const arr = [];
    loop: while (this.i < this.txt.length) {
      this._skipAtmosphere();
      switch (this.txt[this.i]) {
        case ")":
          this.i++;
          break loop;
        case "]":
        case "}":
          throw new BiwaError("extra close paren", this.rest());
        default:
          arr.push(this.getObject());
          break;
      }
    }
    return arr;
  }

  // Parse a number prefixed with `#i`, `#b`, etc.
  _parsePrefixedNumber() {
    let base = 10; // Decimal is the default
    if (this.match(/^#[iIeE]/)) this.i += 2; // Exactness is not supported.
    if (this.match(/^#[bB]/)) {
      base = 2;
      this.i += 2;
    } else if (this.match(/^#[oO]/)) {
      base = 8;
      this.i += 2;
    } else if (this.match(/^#[dD]/)) {
      base = 10;
      this.i += 2;
    } else if (this.match(/^#[xX]/)) {
      base = 16;
      this.i += 2;
    }
    if (this.match(/^#[iIeE]/)) this.i += 2; // Exactness is not supported.

    return this._parseComplexNumber(base);
  }

  // Parse a (possibly) complex number
  _parseComplexNumber(base) {
    const a = this._parseRealNumber(base);
    const c = this.txt[this.i];
    if (c == "@") {
      this.i++;
      return this._parsePolarComplexNumber(a, base);
    } else if (c == "+" || c == "-") {
      this.i--; // Unget the sign
      return this._parseOrthoComplexNumber(a, base);
    } else {
      // Was not a complex number.
      return a;
    }
  }

  // Parse a complex number of the form `a@b`
  _parsePolarComplexNumber(a, base) {
    const b = this._parseRealNumber(base);
    return Complex.from_polar(a, b);
  }

  // Parse a complex number of the form `a+bi`
  _parseOrthoComplexNumber(a, base) {
    const b = this._parseRealNumber(base);
    if (this.match(/^[iI]/)) {
      this.i++;
      return new Complex(a, b);
    } else {
      throw new BiwaError(
        "invalid complex number format (missing `i`)",
        this.rest()
      );
    }
  }

  // Parse a real number in base 2, 8, or 16
  // If `maybeSymbol` is true, returns a consumed string intead of throwing error
  // when it is not a number.
  _parseRealNumber(base, maybeSymbol = false) {
    if (maybeSymbol && base != 10)
      throw new Bug("base must be 10 if maybeSymbol");
    let asSym = "";

    // Check if it is inf or nan
    const m = this.match(/^(\+|-)(inf.0|nan.0)/);
    if (m) {
      this.i += "+inf.0".length;
      return (m[2] == "inf.0" ? Infinity : NaN) * (m[1] == "+" ? 1 : -1);
    }

    let sign = 1;
    if (this.match(/^\+/)) {
      this.i++;
      asSym += "+";
    } else if (this.match(/^\-/)) {
      this.i++;
      asSym += "-";
      sign = -1;
    }

    let a = null;
    const mm = this.match(DIGITS[base]);
    if (mm) {
      this.i += mm[0].length;
      asSym += mm[0];
      a = parseInt(mm[0], base) * sign;
    } else if (base == 10 && this.txt[this.i] == ".");
    else if (maybeSymbol);
    else {
      throw new BiwaError("invalid char in number literal", this.rest());
    }

    // Parse rational
    if (this.txt[this.i] == "/") {
      this.i++;
      const mmm = this.match(DIGITS[base]);
      if (mmm) {
        this.i += mmm[0].length;
        const b = parseInt(mmm[0], base);
        return new Rational(a, b);
      } else if (maybeSymbol) {
        asSym += "/";
      } else {
        throw new BiwaError(
          "invalid char in rational number literal",
          this.rest()
        );
      }
    }

    // Was not a rational. Check if it is a decimal
    if (base == 10) {
      // Try matching form the beginning
      this.i -= asSym.length;
      const mmm = this.match(/^[+-]?(\d+\.\d*|\.?\d+)([eE][+-]?\d+)?/);
      if (mmm) {
        this.i += mmm[0].length;
        return parseFloat(mmm[0]);
      } else {
        // Was not a decimal either. Put back the cursor
        this.i += asSym.length;
      }
    }

    if (maybeSymbol) {
      return asSym;
    } else {
      throw new BiwaError(
        `invalid chars in number literal (${asSym})`,
        this.rest()
      );
    }
  }

  // Parse a datum label definition (`#0=`) or reference (`#0#`).
  _parseDatumLabel() {
    const m = this.match(/^(\d+)(=|#)/);
    if (m) {
      this.i += m[0].length;
      const id = parseInt(m[1]);
      if (m[2] == "=") {
        const v = this._getObjectOrThrowUnterminated(
          "got EOS for labelled datum"
        );
        this.labelledData[id] = v;
        return v;
      } else {
        if (this.labelledData.hasOwnProperty(id)) {
          return this.labelledData[id];
        } else {
          throw new BiwaError("undefined datum reference", this.rest(-1));
        }
      }
    } else {
      throw new BiwaError("unknown #-syntax", this.rest(-1));
    }
  }

  // Parse a list (`(...)`)
  // BiwaScheme allows `[]`, `{}` for list too
  _parseList() {
    const begin = this.i;
    const openParen = this.txt[this.i];
    this.i++;
    const closeParen = PAREN[openParen];
    let dotSeen = false;
    let list = nil,
      prev = list;
    while (this.i < this.txt.length) {
      this._skipAtmosphere();
      const c = this.txt[this.i];
      if (c == closeParen) {
        this.i++;
        return list;
      } else if (c == ")" || c == "]" || c == "}") {
        throw new BiwaError("extra close paren", this.rest());
      } else if (c == "." && this.match(/^\.[\s]/)) {
        if (list === nil) {
          throw new BiwaError("no list element before `.`", this.from(begin));
        }
        dotSeen = true;
        this.i++;
        const v = this.getObject();
        if (v === Parser.EOS) {
          throw new Unterminated(
            "found EOS after `.` in list",
            this.from(begin)
          );
        }
        prev.cdr = v;
      } else if (dotSeen) {
        throw new BiwaError(
          "more than one element after `.`",
          this.from(begin)
        );
      } else {
        const vv = this.getObject();
        if (vv === Parser.EOS) {
          this.i = begin;
          throw new Unterminated("found EOS in list", this.rest());
        }
        const cur = new Pair(vv, nil);
        if (list === nil) list = cur;
        else prev.cdr = cur;
        prev = cur;
      }
    }
    this.i = begin;
    throw new Unterminated("found EOS in list", this.rest());
  }

  // Parse a string literal (`"..."`)
  _parseString() {
    const m = this.match(/^"((\\"|[^"])*)"/);
    if (m) {
      this.i += m[0].length;
      const s = m[1].replaceAll(/\\\s*\n\s*/g, "");
      return this._replaceEscapedChars(s);
    } else {
      throw new Unterminated("invalid string literal", this.rest());
    }
  }

  // Parse a symbol enclosed with `|`
  _parseEnclosedSymbol() {
    const m = this.match(/^\|((\\\||[^\|])*)\|/);
    if (m) {
      this.i += m[0].length;
      return Sym(this._replaceEscapedChars(m[1]));
    } else {
      throw new Unterminated("invalid symbol literal", this.rest());
    }
  }

  // Replace `\n`, `\t`, `\x1234`, etc. in `s`
  _replaceEscapedChars(s) {
    return s
      .replaceAll(/\\x([0-9a-f]+);/gi, (_, n) =>
        String.fromCharCode(parseInt(n, 16))
      )
      .replaceAll(/\\(.)/g, (_, c) => ESCAPE_SEQUENCES[c] || c);
  }

  // Parse a number or an identifier.
  _parseDecimalNumberOrIdentifier() {
    const c = this.txt[this.i];
    if (c == "#") throw new Bug("#-syntax must be parsed beforehand");
    if (c === undefined) throw new Bug("EOS must be handled beforehand");

    let v = this._parseRealNumber(10, true);
    if (isString(v)) {
      // Read the rest of this identifier
      const m = this.match(/^[^\s)}\]]+/);
      if (m) {
        this.i += m[0].length;
        v += m[0];
      }
      if (this.foldCase) {
        v = v.toLowerCase();
      }
      return Sym(v);
    } else {
      return v;
    }
  }

  // Returns rest of the source text
  rest(di = 0) {
    return this.txt.slice(this.i + di);
  }

  // Returns the source text beginning from `pos`
  from(pos) {
    return this.txt.slice(pos);
  }

  // Execute regexp match from the current position.
  // Returns `null` if no match
  match(rexp, di = 0) {
    return rexp.exec(this.rest(di));
  }

  // Returns if whole text is seen
  done() {
    return this.i >= this.txt.length;
  }
}

// Indicates end of source file
Parser.EOS = new Object();
Parser.EOS.toString = () => "#<BiwaScheme.Parser.EOS>";

// Parser the text and return an array of exprs
Parser.parse = (txt) => {
  const parser = new Parser(txt);
  const ret = [];
  while (true) {
    var expr = parser.getObject();
    if (expr === Parser.EOS) break;
    ret.push(expr);
  }
  return ret;
};

Parser.Unterminated = Unterminated;

///
/// Interpreter
///

class Interpreter {
  // new Interpreter()
  // new Interpreter(lastInterpreter)
  // new Interpreter(errorHandler)
  // new Interpreter(lastInterpreter, errorHandler)
  constructor() {
    var last_interpreter = null;
    var on_error = null;
    if (arguments.length == 2) {
      last_interpreter = arguments[0];
      on_error = arguments[1];
    } else if (arguments.length == 1 && arguments[0] instanceof Interpreter) {
      last_interpreter = arguments[0];
    } else if (arguments.length == 1 && typeof arguments[0] == "function") {
      on_error = arguments[0];
    }

    // Interpreter stack
    this.stack = [];
    // JS function to handle error
    this.on_error =
      on_error ||
      (last_interpreter ? last_interpreter.on_error : function (e) {});
    // JS function to handle result
    this.after_evaluate = function () {};

    // (Variables for stack trace)
    // Name of the last variable read by refer-xx
    this.last_refer = last_interpreter ? last_interpreter.last_refer : null;
    // Call stack (array of last_refer)
    this.call_stack = last_interpreter ? [...last_interpreter.call_stack] : [];
    // Counts number of tail calls (= how many times should we pop call_stack
    // in op_return)
    this.tco_counter = [];
    // Maximum length of call_stack
    // (Note: we should cap call_stack for inifinite loop with recursion)
    this.max_trace_size = last_interpreter
      ? last_interpreter.max_trace_size
      : max_trace_size;

    // dynamic-wind
    this.current_dynamic_winder = Interpreter.DynamicWind.ROOT;
  }

  inspect() {
    return [
      "#<Interpreter: stack size=>",
      this.stack.length,
      " ",
      "after_evaluate=",
      inspect(this.after_evaluate),
      ">",
    ].join("");
  }

  // private
  push(x, s) {
    this.stack[s] = x;
    return s + 1;
  }

  // private
  //s: depth of stack to save
  //ret: saved(copied) stack
  save_stack(s) {
    var v = [];
    for (var i = 0; i < s; i++) {
      v[i] = this.stack[i];
    }
    return {
      stack: v,
      last_refer: this.last_refer,
      call_stack: [...this.call_stack],
      tco_counter: [...this.tco_counter],
    };
  }

  // private
  //v: stack array to restore
  //ret: lenght of restored stack
  restore_stack(stuff) {
    const v = stuff.stack;
    const s = v.length;
    for (var i = 0; i < s; i++) {
      this.stack[i] = v[i];
    }
    this.last_refer = stuff.last_refer;
    this.call_stack = [...stuff.call_stack];
    this.tco_counter = [...stuff.tco_counter];
    return s;
  }

  // private
  //s: depth of stack to save
  //n: number of args(for outer lambda) to remove (= 0 unless tail position)
  //ret: closure array
  capture_continuation(s, n) {
    // note: implementation of this function for final version doesn't exist in 3imp.pdf..
    var ss = this.push(n, s);
    return this.closure(
      ["nuate1", this.save_stack(ss), this.current_dynamic_winder],
      1, //arity
      0, //n (number of frees)
      null, //s (stack position to get frees)
      -1
    ); // dotpos
  }

  // private
  // shift stack
  // n: number of items to skip (from stack top)
  // m: number of items to shift
  // s: stack pointer (= index of stack top + 1)
  shift_args(n, m, s) {
    for (var i = n; i >= 0; i--) {
      this.index_set(s, i + m + 1, this.index(s, i));
    }
    return s - m - 1;
  }

  index(s, i) {
    return this.stack[s - 1 - i];
  }

  // private
  index_set(s, i, v) {
    this.stack[s - 1 - i] = v;
  }

  // private
  closure(body, n_args, n, s, dotpos) {
    const freevars = [];
    for (var i = 0; i < n; i++) {
      freevars[i] = this.index(s, i);
    }
    const expected_args = dotpos == -1 ? n_args : undefined;
    return new Closure(body, freevars, dotpos, expected_args);
  }

  // private
  run_dump_hook(a, x, f, c, s) {
    var dumper;
    var state;

    if (this.dumper) {
      dumper = this.dumper;
    } else if (Interpreter.dumper) {
      dumper = Interpreter.dumper;
    } else return;

    if (dumper) {
      state = { a: a, f: f, c: c, s: s, x: x, stack: this.stack };
      dumper.dump(state);
    }
  }

  // private
  // a: arbitary object (temporary register)
  // x: opecode
  // f: integer
  // c: BiwaScheme.Closure
  // s: integer
  _execute(a, x, f, c, s) {
    var ret = null;
    //Console.puts("executing "+x[0]);

    while (true) {
      //x[0] != "halt"){
      this.run_dump_hook(a, x, f, c, s);

      switch (x[0]) {
        case "halt":
          return a;
        case "refer-local":
          var n = x[1],
            x = x[2];
          a = this.index(f, n + 1);
          this.last_refer = "(anon)";
          break;
        case "refer-free":
          var n = x[1],
            x = x[2];
          a = c.freevars[n];
          this.last_refer = "(anon)";
          break;
        case "refer-global":
          var sym = x[1],
            x = x[2];
          if (TopEnv.hasOwnProperty(sym)) var val = TopEnv[sym];
          else if (CoreEnv.hasOwnProperty(sym)) var val = CoreEnv[sym];
          else throw new BiwaError("execute: unbound symbol: " + inspect(sym));

          a = val;
          this.last_refer = sym || "(anon)";
          break;
        case "indirect":
          var x = x[1];
          a = a[0]; //unboxing
          break;
        case "constant":
          var obj = x[1],
            x = x[2];
          a = obj;
          this.last_refer = "(anon)";
          break;
        case "close":
          var ox = x;
          var v = ox[1],
            n = ox[2],
            body = ox[3],
            x = ox[4],
            dotpos = ox[5];
          a = this.closure(body, v, n, s, dotpos);
          s -= n;
          break;
        case "box":
          var n = x[1],
            x = x[2];
          this.index_set(s, n + 1, [this.index(s, n + 1)]); //boxing
          break;
        case "test":
          var thenc = x[1],
            elsec = x[2];
          x = a !== false ? thenc : elsec;
          break;
        case "assign-global":
          var name = x[1],
            x = x[2];
          if (!TopEnv.hasOwnProperty(name) && !CoreEnv.hasOwnProperty(name))
            throw new BiwaError(
              "global variable '" + name + "' is not defined"
            );

          TopEnv[name] = a;
          a = undef;
          break;
        case "assign-local":
          var n = x[1],
            x = x[2];
          var box = this.index(f, n + 1);
          box[0] = a;
          a = undef;
          break;
        case "assign-free":
          var n = x[1],
            x = x[2];
          var box = c.freevars[n];
          box[0] = a;
          a = undef;
          break;
        case "conti":
          var n = x[1],
            x = x[2];
          a = this.capture_continuation(s, n);
          break;
        case "nuate1":
          var stack = x[1],
            to = x[2];
          var from = this.current_dynamic_winder;
          var winders = Interpreter.DynamicWind.listWinders(from, to);
          x = Interpreter.DynamicWind.joinWinders(winders, [
            "refer-local",
            0,
            ["nuate2", stack],
          ]);
          break;
        case "nuate2":
          var stack = x[1],
            x = ["return"];
          s = this.restore_stack(stack);
          break;
        case "frame":
          var ret = x[2];
          x = x[1];
          s = this.push(ret, this.push(f, this.push(c, s)));
          this.tco_counter[this.tco_counter.length] = 0;
          break;
        case "argument":
          var x = x[1];
          s = this.push(a, s);
          break;
        case "shift":
          var n = x[1],
            x = x[2];

          // the number of arguments in the last call
          var n_args = this.index(s, n + 1);

          s = this.shift_args(n, n_args, s);
          break;
        case "tco_hinted_apply": // just like a regular apply, except we need to trace the # of TCO calls so we can generate a stacktrace
          this.tco_counter[this.tco_counter.length - 1]++;
          x = ["apply"].concat(x.slice(1));
          break;
        case "apply": //extended: n_args as second argument
          var func = a; //, n_args = x[1];

          // Save stack trace
          this.call_stack.push(this.last_refer);
          if (this.call_stack.length > this.max_trace_size) {
            // Remove old memory if it grows too long
            // Note: this simple way may be inconvenient (e.g. no trace
            // will be shown when an error occurred right after returning
            // from a large function)
            this.call_stack.shift();
          }

          // the number of arguments in the last call is
          // pushed to the stack.
          var n_args = this.index(s, 0);
          if (isClosure(func)) {
            a = func;
            x = func.body;

            // The position of dot in the parameter list.
            const dotpos = func.dotpos;
            if (dotpos >= 0) {
              // The dot is found
              // ----------------
              // => Process the &rest args: packing the rest args into a list.
              var ls = nil;
              for (var i = n_args; --i >= dotpos; ) {
                ls = new Pair(this.index(s, i + 1), ls);
              }
              if (dotpos >= n_args) {
                // No rest argument is passed to this closure.
                // However, the closure expects the caller passes the rest argument.
                // In such case this VM prepares an empty list as the rest argument.
                // --------------------------------------------------------------
                // => We extend the stack to put the empty list.
                for (var i = 0; i < n_args + 1; i++) {
                  this.index_set(s, i - 1, this.index(s, i));
                }
                s++;
                // => Update the number of arguments
                this.index_set(s, 0, this.index(s, 0) + 1);
              }
              this.index_set(s, dotpos + 1, ls);
            } else {
              // the dot is not found
              // --------------------
              // => Verify that number of arguments = expected number of arguments
              // (if the closure knows how many it wants)
              if (
                func.expected_args !== undefined &&
                n_args != func.expected_args
              ) {
                var errMsg =
                  "Function call error: got " +
                  n_args +
                  " but wanted " +
                  func.expected_args;
                throw new BiwaError(errMsg);
              }
            }
            f = s;
            c = func;
          } else if (func instanceof Function) {
            // Apply JavaScript function
            // load arguments from stack
            var args = [];
            for (var i = 0; i < n_args; i++) args.push(this.index(s, i + 1));

            // invoke the function
            var result = func(args, this);

            if (result instanceof Pause) {
              // it requested the interpreter to suspend
              var pause = result;
              pause.set_state(this, ["return"], f, c, s);
              pause.ready();
              return pause;
            } else if (result instanceof Call) {
              // it requested the interpreter to call a scheme closure

              //   [frame,
              //     [constant... (args)
              //     [constant, proc
              //     [apply]]]]
              //   [frame,
              //     [constant, after
              //     [apply 1]]]]
              //   x
              var call_after = [
                "frame",
                [
                  "argument",
                  [
                    "constant",
                    1,
                    ["argument", ["constant", result.after, ["apply"]]],
                  ],
                ],
                ["return"],
              ];
              var call_proc = [
                "constant",
                result.args.length,
                [
                  "argument",
                  ["constant", result.proc, ["apply", result.args.length]],
                ],
              ];
              var push_args = result.args.reduce(function (opc, arg) {
                // (foo 1 2) => first push 2, then 1
                //   [constant 2 ... [constant 1 ... ]
                return ["constant", arg, ["argument", opc]];
              }, call_proc);
              x = ["frame", push_args, call_after];
            } else {
              // the JavaScript function returned a normal value
              a = result;
              x = ["return"];
            }
          } else {
            // unknown function type
            throw new BiwaError(inspect(func) + " is not a function");
          }
          break;
        case "return":
          // Pop stack frame
          var n = this.index(s, 0);
          var ss = s - n;
          (x = this.index(ss, 1)),
            (f = this.index(ss, 2)),
            (c = this.index(ss, 3)),
            (s = ss - 3 - 1);

          // Pop stack trace (> 1 times if tail calls are done)
          var n_pops = 1 + this.tco_counter[this.tco_counter.length - 1];
          this.call_stack.splice(-n_pops);
          this.tco_counter.pop();
          break;
        default:
          throw new Bug("unknown opecode type: " + x[0]);
      }
    }

    //      if(ret === null)
    //        throw new Bug("interpreter exited in unusual way");
    //      else
    //        return ret;
    return a;
  }

  // Compile and evaluate Scheme program
  evaluate(str, after_evaluate) {
    this.call_stack = [];
    this.parser = new Parser(str);
    this.compiler = new Compiler();
    if (after_evaluate) this.after_evaluate = after_evaluate;

    //Console.puts("executing: " + str);

    this.is_top = true;
    this.file_stack = [];

    try {
      return this.resume(false);
    } catch (e) {
      e.message = e.message + " [" + this.call_stack.join(", ") + "]";
      return this.on_error(e);
    }
  }

  // Resume evaluation
  // (internally used from Interpreter#execute and Pause#resume)
  resume(is_resume, a, x, f, c, s) {
    var ret = undef;

    for (;;) {
      if (is_resume) {
        ret = this._execute(a, x, f, c, s);
        is_resume = false;
      } else {
        if (!this.parser) break; // adhoc: when Pause is used via invoke_closure
        var expr = this.parser.getObject();
        if (expr === Parser.EOS) break;

        // expand
        expr = Compiler.expand(expr);

        // compile
        const vmcode = this.compiler.run(expr);

        // execute
        ret = this._execute(expr, vmcode.il, 0, [], 0);
      }

      if (ret instanceof Pause) {
        //suspend evaluation
        return ret;
      }
    }

    // finished executing all forms
    this.after_evaluate(ret);
    return ret;
  }

  // Execute compiled vmcode
  evaluate_vmcode(vmcode) {
    this.call_stack = [];
    this.is_top = true;
    this.file_stack = [];
    try {
      const ret = this._execute(undef, vmcode.il, 0, [], 0);
      if (!(ret instanceof Pause)) {
        this.after_evaluate(ret);
      }
      return ret;
    } catch (e) {
      e.message = e.message + " [" + this.call_stack.join(", ") + "]";
      return this.on_error(e);
    }
  }

  // Invoke a scheme closure
  invoke_closure(closure, args) {
    args || (args = []);
    var n_args = args.length;

    var x = [
      "constant",
      n_args,
      ["argument", ["constant", closure, ["apply"]]],
    ];
    for (var i = 0; i < n_args; i++) x = ["constant", args[i], ["argument", x]];

    return this._execute(closure, ["frame", x, ["halt"]], 0, closure, 0);
  }

  // only compiling (for debug use only)
  compile(str) {
    var obj = Interpreter.read(str);
    var opc = Compiler.compile(obj);
    return opc;
  }

  // before, after: Scheme closure
  push_dynamic_winder(before, after) {
    this.current_dynamic_winder = new Interpreter.DynamicWind(
      this.current_dynamic_winder,
      before,
      after
    );
  }

  pop_dynamic_winder(before, after) {
    this.current_dynamic_winder = this.current_dynamic_winder.parent;
  }
}

// Take a string and returns an expression.
Interpreter.read = function (str) {
  var parser = new Parser(str);
  var r = parser.getObject();
  return r == Parser.EOS ? eof : r;
};

Interpreter.expand = function () {
  throw "Interpreter.expand is moved to Compiler.expand";
};

//
// dynamic-wind
//

Interpreter.DynamicWind = class {
  constructor(parent, before, after) {
    // Parent `DynamicWind` obj
    this.parent = parent;
    // "before" winder (Scheme closure)
    this.before = before;
    // "after" winder (Scheme closure)
    this.after = after;
  }
};

// A special value indicates the root of the winders
// (the string is just for debugging purpose.)
Interpreter.DynamicWind.ROOT = { _: "this is ROOT." };

// Return the list of winders to call
Interpreter.DynamicWind.listWinders = function (from, to) {
  // List winders from `from` to `ROOT`
  var fromStack = [from];
  while (from !== Interpreter.DynamicWind.ROOT) {
    from = from.parent;
    fromStack.push(from);
  }

  // List winders from `to` to `ROOT` and find the common one
  var toStack = [];
  var common;
  while (true) {
    var matched = fromStack.find(function (item) {
      return item === to;
    });
    if (matched) {
      common = matched;
      break;
    }
    toStack.push(to);
    to = to.parent;
  }

  // List `after`s to call
  var ret = [];
  for (var i = 0; i < fromStack.length; i++) {
    if (fromStack[i] === common) break;
    ret.push(fromStack[i].after);
  }

  // List `before`s to call
  toStack.reverse();
  toStack.forEach(function (item) {
    ret.push(item.before);
  });

  return ret;
};

// Return an opecode to run all the winders
Interpreter.DynamicWind.joinWinders = function (winders, x) {
  return winders.reduceRight(function (acc, winder) {
    return [
      "frame",
      ["constant", 0, ["argument", ["constant", winder, ["apply"]]]],
      acc,
    ];
  }, x);
};

//
// R7RS Promise (lazy library; has nothing to do with JS Promise)
//
class BiwaPromise {
  constructor(done, thunk_or_value) {
    this.box = [done, thunk_or_value];
  }

  // Return true when this promise is already calculated
  is_done() {
    return this.box[0];
  }

  // Return calculated value of this promise
  value() {
    if (!this.is_done()) {
      throw new Bug("this promise is not calculated yet");
    }
    return this.box[1];
  }

  thunk() {
    if (this.is_done()) {
      throw new Bug("this promise does not know the thunk");
    }
    return this.box[1];
  }

  update_with(new_promise) {
    this.box[0] = new_promise.box[0];
    this.box[1] = new_promise.box[1];
    new_promise.box = this.box;
  }
}

const isPromise = function (obj) {
  return obj instanceof BiwaPromise;
};

// Create fresh promise
BiwaPromise.fresh = function (thunk) {
  return new BiwaPromise(false, thunk);
};
// Create calculated promise
BiwaPromise.done = function (value) {
  return new BiwaPromise(true, value);
};

///
/// infra.js - Basis for library functions
///

//
// define_*func - define library functions
//
const check_arity = function (fname, len, min, max) {
  if (len < min) {
    if (max && max == min)
      throw new BiwaError(
        fname +
          ": wrong number of arguments (expected: " +
          min +
          " got: " +
          len +
          ")"
      );
    else
      throw new BiwaError(
        fname + ": too few arguments (at least: " + min + " got: " + len + ")"
      );
  } else if (max && max < len)
    throw new BiwaError(
      fname + ": too many arguments (at most: " + max + " got: " + len + ")"
    );
};

const define_libfunc = function (fname, min, max, func) {
  var f = function (ar, intp) {
    check_arity(fname, ar.length, min, max);
    return func(ar, intp);
  };

  func["fname"] = fname; // for assert_*
  f["inspect"] = function () {
    return this.fname;
  };
  CoreEnv[fname] = f;
};

const alias_libfunc = function (fname, aliases) {
  if (CoreEnv[fname]) {
    if (Array.isArray(aliases)) {
      aliases.map(function (a) {
        alias_libfunc(fname, a);
      });
    } else if (typeof aliases === "string") {
      CoreEnv[aliases] = CoreEnv[fname];
    } else {
      console.error(
        "[BUG] bad alias for library function " +
          "`" +
          fname +
          "': " +
          aliases.toString()
      );
    }
  } else {
    console.error(
      "[BUG] library function " +
        "`" +
        fname +
        "'" +
        " does not exist, so can't alias it."
    );
  }
};

const define_syntax = function (sname, func) {
  var s = new Syntax(sname, func);
  CoreEnv[sname] = s;
};

const define_scmfunc = function (fname, min, max, str) {
  new Interpreter().evaluate("(define " + fname + " " + str + "\n)");
};

//  define_scmfunc("map+", 2, null,
//    "(lambda (proc ls) (if (null? ls) ls (cons (proc (car ls)) (map proc (cdr ls)))))");

//
// assertions - type checks
//

const assert_number = make_simple_assert("number", function (obj) {
  return typeof obj == "number" || obj instanceof Complex;
});

const assert_integer = make_simple_assert("integer", function (obj) {
  return typeof obj == "number" && obj % 1 == 0;
});

const assert_real = make_simple_assert("real number", function (obj) {
  return typeof obj == "number";
});

const assert_between = make_assert(function (fname, obj, from, to) {
  if (typeof obj != "number" || obj != Math.round(obj)) {
    throw new BiwaError(
      fname + ": " + "number required, but got " + to_write$1(obj)
    );
  }

  if (obj < from || to < obj) {
    throw new BiwaError(
      fname +
        ": " +
        "number must be between " +
        from +
        " and " +
        to +
        ", but got " +
        to_write$1(obj)
    );
  }
});

const assert_string = make_simple_assert("string", isString);
const assert_char = make_simple_assert("character", isChar);
const assert_symbol = make_simple_assert("symbol", isSymbol);
const assert_port = make_simple_assert("port", isPort);
const assert_pair = make_simple_assert("pair", isPair);
const assert_list = make_simple_assert("list", isList);
const assert_vector = make_simple_assert("vector", isVector);
const assert_hashtable = make_simple_assert("hashtable", isHashtable);
const assert_mutable_hashtable = make_simple_assert(
  "mutable hashtable",
  isMutableHashtable
);
const assert_promise = make_simple_assert("promise", isPromise);

const assert_function = make_simple_assert("JavaScript function", isFunction);
const assert_closure = make_simple_assert("scheme closure", isClosure);
const assert_procedure = make_simple_assert("scheme/js function", function (
  obj
) {
  return isClosure(obj) || isFunction(obj);
});

const assert_date = make_simple_assert("date", function (obj) {
  // FIXME: this is not accurate (about cross-frame issue)
  // https://prototype.lighthouseapp.com/projects/8886/tickets/443
  return obj instanceof Date;
});

//var assert_instance_of = BiwaScheme.make_assert(function(fname, type, obj, klass){
//  if(!(obj instanceof klass)){
//    throw new BiwaScheme.Error(fname + ": " +
//                               type + " required, but got " +
//                               BiwaScheme.to_write(obj));
//  }
//});

const assert = make_assert(function (fname, success, message, _fname) {
  if (!success) {
    throw new BiwaError((_fname || fname) + ": " + message);
  }
});

//
// deprecation
//

// Show deprecation warnig
// @param {string} title - feature to be deprecated
// @param {string} ver - when it will be removed (eg. "1.0")
// @param {string} alt - alternatives
const deprecate = function (title, ver, alt) {
  var msg =
    title +
    " is deprecated and will be removed in BiwaScheme " +
    ver +
    ". " +
    "Please use " +
    alt +
    " instead";
  console.warn(msg);
};

//
// utils
//

// Parses a fractional notation in the format: <num>/<denom> (e.g. 3/7, -9/4),
// where <num> is a valid integer notation, and <denom> is a valid notation
// for a positive integer.
//
// Returns a float if the notation is valid, otherwise false.
//
// @param {string} rep - the string representation of the fraction
// @return {float|false}
const parse_fraction = function (rep) {
  assert_string(rep);

  var frac_parts = rep.split("/");

  if (frac_parts.length !== 2) return false;

  var num_rep = frac_parts[0];
  var denom_rep = frac_parts[1];

  var num = parse_integer(num_rep, 10);
  var denom = parse_integer(denom_rep, 10);

  if (num === false || denom === false) return false;

  if (denom <= 0) return false;

  return num / denom;
};

// Given a string notation of an integer, and the radix, validates the
// notation: returns true if the notation is valid, otherwise false.
//
// @param {string} rep - the string representation of the integer
// @param {integer} rdx - the radix, where 2 <= rdx <= 36
// @return {boolean}
const is_valid_integer_notation = function (rep, rdx) {
  assert_string(rep);
  assert_integer(rdx);

  if (rdx < 2 || rdx > 36) return false;

  var rdx_symbols = "0123456789abcdefghijklmnopqrstuvwxyz";

  var valid_symbols = rdx_symbols.slice(0, rdx);
  var sym_regex = new RegExp("^[+-]?" + "[" + valid_symbols + "]+$", "ig");

  return sym_regex.test(rep);
};

// Parse an integer. If the integer does not have a valid representation, or
// produces NaN, - false is returned. If the radix is not within [2..36]
// range, false is returned as well.
//
// @param {string} rep - the string representation of the integer
// @param {integer} rdx - the radix, where 2 <= rdx <= 36
// @return {integer|false}
const parse_integer = function (rep, rdx) {
  assert_string(rep);
  assert_integer(rdx);

  if (rdx < 2 || rdx > 36) return false;

  if (!is_valid_integer_notation(rep, rdx)) return false;

  var res = parseInt(rep, rdx);

  if (Number.isNaN(res)) return false;

  return res;
};

// Given a string notation of a floating-point number in the standard or
// scientific notation, returns true if the notation valid, otherwise false.
//
// For example:
// "1"      -> true
// "1."     -> true
// "1.23"   -> true
// "1e4"    -> true
// "1E4"    -> true
// "1E4.34" -> false
// "e34"    -> false
//
// @param {string} rep - the string representation of the float.
// @return {boolean}
const is_valid_float_notation = function (rep) {
  assert_string(rep);

  var sci_regex = /^[+-]?[0-9]+[.]?[0-9]*e[+-]?[0-9]+$/i;
  var fp_regex = /(^[+-]?[0-9]*[.][0-9]+$)|(^[+-]?[0-9]+[.][0-9]*$)/;

  if (sci_regex.test(rep) || fp_regex.test(rep)) return true;

  return is_valid_integer_notation(rep, 10);
};

// Parse a floating-point number. If the floating-point number does not have a
// valid representation, or produces -Infinity, +Infinity or NaN, - false is
// returned.
//
// @param {string} rep - the string representation of the floating-point value
// @return {float|false}
const parse_float = function (rep) {
  assert_string(rep);

  if (!is_valid_float_notation(rep)) return false;

  var res = new Number(rep).valueOf();

  if (Number.isNaN(res)) return false;

  if (!Number.isFinite(res)) return false;

  return res;
};

//
// R6RS Enumerations
// http://www.r6rs.org/final/html/r6rs-lib/r6rs-lib-Z-H-15.html#node_chap_14
//
// Example
//
//   (define-enumeration color
//     (black white purple maroon)
//     color-set)
//
//   (color black)                  ;=> 'black
//   (color purpel)                 ;=> &syntax exception
//   (enum-set->list
//     (color-set maroon white))    ;=> #<enum-set (white maroon)>

const Enumeration = {};

// Represents an enum_type.
//
// Becuase there is no way to access an EnumType directly from Scheme,
// EnumType#to_write is not defined.
//
// Properties
//
// members - Array of symbols (no duplicate)
//
Enumeration.EnumType = class {
  // Creates a new enum_type.
  //
  // members - Array of symbols.
  //           Symbols may be duplicate (I think you shouldn't, though :-p).
  constructor(members) {
    this.members = [...new Set(members)];
  }

  // Returns an EnumSet.
  universe() {
    return new Enumeration.EnumSet(this, this.members);
  }

  // Returns a function which map a symbol to an integer (or #f, if
  // the symbol is out of the universe).
  //
  // Implementation note: don't forget this.members may have duplicates.
  indexer() {
    // ar[0] - a symbol
    // Returns an integer or #f.
    return (ar) => {
      assert_symbol(ar[0], "(enum-set indexer)");
      var idx = this.members.indexOf(ar[0]);
      return idx === -1 ? false : idx;
    };
  }

  // Retuns a function which creates an enum_set from a list of
  // symbols (Symbols may be duplicate.)
  constructor_() {
    // ar[0] - a list of symbol
    // Returns a enum_set.
    return (ar) => {
      assert_list(ar[0], "(enum-set constructor)");
      var symbols = ar[0].to_array();
      symbols.forEach(function (arg) {
        assert_symbol(arg, "(enum-set constructor)");
      });

      return new Enumeration.EnumSet(this, symbols);
    };
  }
};

// Represents an enum_set of an enum_type.
//
// Properties
//
// enum_type - The enum_type.
// symbols   - Array of symbols (no duplicate, properly ordered)
//
Enumeration.EnumSet = class {
  // Creates a new enum_set.
  //
  // enum_type - An EnumType
  // symbols   - Array of symbols.
  //
  // initialize normalizes symbols.
  //   - remove duplicates
  //   - order by universe
  constructor(enum_type, symbols) {
    this.enum_type = enum_type;
    this.symbols = enum_type.members.filter((sym) => symbols.includes(sym));
  }

  // Returns a list of symbols.
  symbol_list() {
    return array_to_list(this.symbols);
  }

  // Returns true if the enum_set includes the symbol.
  // 'symbol' is allowed to be a symbol which is not included in the universe.
  is_member(symbol) {
    return this.symbols.includes(symbol);
  }

  // Returns true if:
  // - the enum_set is a subset of the enum_set 'other', and
  // - the universe of the enum_set is a subset of
  //   the universe of 'other'.
  // The enum_set and 'other' may belong to different enum_type.
  is_subset(other) {
    // Check elements
    if (this.symbols.some((sym) => !other.symbols.includes(sym))) {
      return false;
    }

    // Check universe
    if (this.enum_type === other.enum_type) {
      return true;
    } else {
      return this.enum_type.members.every((sym) =>
        other.enum_type.members.includes(sym)
      );
    }
  }

  // Returns true if:
  //   - the enum_set contains the same set of symbols as 'other', and
  //   - universe of the enum_set contains the same set of symbols
  //     as the universe of 'other'.
  //
  // The enum_set and 'other' may belong to different enum_type.
  equal_to(other) {
    return this.is_subset(other) && other.is_subset(this);
  }

  // Returns a enum_set which has:
  // - all the symbols included in the enum_set or the enum_set 'other'.
  // The enum_set and 'other' *must* belong to the same enum_type.
  union(other) {
    var syms = this.enum_type.members.filter(
      (sym) => this.symbols.includes(sym) || other.symbols.includes(sym)
    );
    return new Enumeration.EnumSet(this.enum_type, syms);
  }

  // Returns a enum_set which has:
  // - the symbols included both in the enum_set or the enum_set 'other'.
  // The enum_set and 'other' *must* belong to the same enum_type.
  intersection(other) {
    var syms = this.symbols.filter((sym) => other.symbols.includes(sym));
    return new Enumeration.EnumSet(this.enum_type, syms);
  }

  // Returns a enum_set which has:
  // - the symbols included in the enum_set and not in the enum_set 'other'.
  // The enum_set and 'other' *must* belong to the same enum_type.
  difference(other) {
    var syms = this.symbols.filter((sym) => !other.symbols.includes(sym));
    return new Enumeration.EnumSet(this.enum_type, syms);
  }

  // Returns a enum_set which has:
  // - the symbols included in the universe but not in the enum_set.
  complement() {
    var syms = this.enum_type.members.filter(
      (sym) => !this.symbols.includes(sym)
    );
    return new Enumeration.EnumSet(this.enum_type, syms);
  }

  // Returns a enum_set which has:
  // - the symbols included in the enum_set and the universe of the enum_set 'other'.
  // The enum_set and 'other' may belong to different enum_type.
  projection(other) {
    var syms = this.symbols.filter((sym) =>
      other.enum_type.members.includes(sym)
    );
    return new Enumeration.EnumSet(other.enum_type, syms);
  }

  // Returns a string which represents the enum_set.
  toString() {
    return "#<EnumSet " + inspect(this.symbols) + ">";
  }
};

const isEnumSet = function (obj) {
  return obj instanceof Enumeration.EnumSet;
};

const assert_enum_set = make_simple_assert("enum_set", isEnumSet);

//
// Memoize
//
const memoize = function (klass, names) {
  const proto = klass.prototype;
  names.forEach((name) => {
    // Copy original function foo as 'compute_foo'
    proto["compute_" + name] = proto[name];
    // Define memoized version
    proto[name] = function (/*arguments*/) {
      if (!this.hasOwnProperty("cached_" + name)) {
        this["cached_" + name] = this["compute_" + name].apply(
          this,
          Array.from(arguments)
        );
      }
      return this["cached_" + name];
    };
  });
};
memoize(Enumeration.EnumSet, ["symbol_list"]);
memoize(Enumeration.EnumType, ["universe", "indexer", "constructor_"]);

//
// R6RS Records
// http://www.r6rs.org/final/html/r6rs-lib/r6rs-lib-Z-H-7.html#node_chap_6
//
// Record is like struct in C, but supports more feature like inheritance.
// see also: src/library/r6rs_lib.js

//
// Record
// represents each instance of record type
//
class Record {
  constructor(rtd, values) {
    assert_record_td(rtd, "new Record");

    this.rtd = rtd;
    this.fields = values;
  }

  get(k) {
    return this.fields[k];
  }

  set(k, v) {
    this.fields[k] = v;
  }

  toString() {
    var contents = to_write$1(this.fields);
    return "#<Record " + this.rtd.name + " " + contents + ">";
  }
}

const isRecord = function (o) {
  return o instanceof Record;
};

// Defined record types
Record._DefinedTypes = {};

Record.define_type = function (name_str, rtd, cd) {
  return (Record._DefinedTypes[name_str] = { rtd: rtd, cd: cd });
};

Record.get_type = function (name_str) {
  return Record._DefinedTypes[name_str];
};

//
// RTD (Record type descriptor)
//
Record.RTD = class {
  //          Symbol RTD        Symbol Bool  Bool    Array
  constructor(name, parent_rtd, uid, sealed, opaque, fields) {
    this.name = name;
    this.parent_rtd = parent_rtd;
    this.is_base_type = !parent_rtd;

    if (uid) {
      this.uid = uid;
      this.generative = false;
    } else {
      this.uid = this._generate_new_uid();
      this.generative = true;
    }

    this.sealed = !!sealed;
    this.opaque = parent_rtd.opaque || !!opaque;

    this.fields = fields.map(function (field) {
      return { name: field[0], mutable: !!field[1] };
    });
  }

  // Returns the name of the k-th field.
  // Only used for error messages.
  field_name(k) {
    var names = this._field_names();

    for (var par = this.parent_rtd; par; par = par.parent_rtd) {
      names = par._field_names() + names;
    }

    return names[k];
  }

  _field_names() {
    return this.fields.map(function (spec) {
      return spec.name;
    });
  }

  _generate_new_uid() {
    return Sym(uniqueId("__record_td_uid"));
  }

  toString() {
    return "#<RecordTD " + name + ">";
  }
};

Record.RTD.NongenerativeRecords = {};
const isRecordTD = function (o) {
  return o instanceof Record.RTD;
};

//
// CD (Record constructor descriptor)
//
Record.CD = class {
  constructor(rtd, parent_cd, protocol) {
    this._check(rtd, parent_cd, protocol);
    this.rtd = rtd;
    this.parent_cd = parent_cd;
    if (protocol) {
      this.has_custom_protocol = true;
      this.protocol = protocol;
    } else {
      this.has_custom_protocol = false;
      if (rtd.parent_rtd)
        this.protocol = this._default_protocol_for_derived_types();
      else this.protocol = this._default_protocol_for_base_types();
    }
  }

  _check(rtd, parent_cd, protocol) {
    if (rtd.is_base_type && parent_cd)
      throw new Error("Record.CD.new: cannot specify parent cd of a base type");

    if (parent_cd && rtd.parent_rtd && parent_cd.rtd != rtd.parent_rtd)
      throw new Error(
        "Record.CD.new: mismatched parents between rtd and parent_cd"
      );

    if (rtd.parent_rtd && !parent_cd && protocol)
      throw new Error(
        "Record.CD.new: protocol must be #f when parent_cd is not given"
      );

    if (parent_cd && parent_cd.has_custom_protocol && !protocol)
      throw new Error(
        "Record.CD.new: protocol must be specified when parent_cd has a custom protocol"
      );
  }

  _default_protocol_for_base_types() {
    // (lambda (p) p)
    // called with `p' as an argument
    return function (ar) {
      var p = ar[0];
      assert_procedure(p, "_default_protocol/base");
      return p;
    };
  }

  _default_protocol_for_derived_types() {
    // (lambda (n)
    //   (lambda (a b x y s t)
    //     (let1 p (n a b x y) (p s t))))
    // called with `n' as an argument
    var rtd = this.rtd;
    return function (ar) {
      var n = ar[0];
      assert_procedure(n, "_default_protocol/n");

      var ctor = function (args) {
        var my_argc = rtd.fields.length;
        var ancestor_argc = args.length - my_argc;

        var ancestor_values = args.slice(0, ancestor_argc);
        var my_values = args.slice(ancestor_argc);

        // (n a b x y) => p
        return new Call(n, ancestor_values, function (ar) {
          var p = ar[0];
          assert_procedure(p, "_default_protocol/p");

          // (p s t) => record
          return new Call(p, my_values, function (ar) {
            var record = ar[0];
            assert_record(record, "_default_protocol/result");

            return record;
          });
        });
      };
      return ctor;
    };
  }

  toString() {
    return "#<RecordCD " + this.rtd.name + ">";
  }

  record_constructor() {
    var arg_for_protocol = this.parent_cd
      ? this._make_n([], this.rtd)
      : this._make_p();
    arg_for_protocol = arg_for_protocol.bind(this);

    return new Call(this.protocol, [arg_for_protocol], function (ar) {
      var ctor = ar[0];
      assert_procedure(ctor, "record_constructor");
      return ctor;
    });
  }

  // Create the function `p' which is given to the protocol.
  _make_p() {
    return function (values) {
      return new Record(this.rtd, values);
      // TODO: check argc
    };
  }

  // Create the function `n' which is given to the protocol.
  // When creating an instance of a derived type,
  // _make_n is called for each ancestor rtd's.
  _make_n(children_values, rtd) {
    var parent_cd = this.parent_cd;

    if (parent_cd) {
      // called from protocol (n)
      var n = function (args_for_n) {
        // called from protocol (p)
        var p = function (args_for_p) {
          var values = [].concat(args_for_p[0]).concat(children_values);
          var parent_n = parent_cd._make_n(values, rtd);

          return new Call(parent_cd.protocol, [parent_n], function (ar) {
            var ctor = ar[0];
            assert_procedure(ctor, "_make_n");

            return new Call(ctor, args_for_n, function (ar) {
              var record = ar[0];
              assert_record(record);
              return record;
            });
          });
        };
        return p;
      };
      return n;
    } else {
      var n = function (my_values) {
        var values = my_values.concat(children_values);
        return new Record(rtd, values);
        // TODO: check argc
      };
      return n;
    }
  }
};

const isRecordCD = function (o) {
  return o instanceof Record.CD;
};

const assert_record = make_simple_assert("record", isRecord);
const assert_record_td = make_simple_assert(
  "record type descriptor",
  isRecordTD
);
const assert_record_cd = make_simple_assert(
  "record constructor descriptor",
  isRecordCD
);

//
// Values
//
class Values {
  constructor(values) {
    this.content = values;
  }

  to_write() {
    return "#<Values " + this.content.map(to_write$1).join(" ") + ">";
  }
}

const Console = {};

// Actual implementation is in src/platforms/*/console.js

define_libfunc("html-escape", 1, 1, function (ar) {
  assert_string(ar[0]);
  return escape(ar[0]);
});
const inspect_objs = function (objs) {
  return objs.map(inspect).join(", ");
};
define_libfunc("inspect", 1, null, function (ar) {
  return inspect_objs(ar);
});
define_libfunc("inspect!", 1, null, function (ar) {
  Console.puts(inspect_objs(ar));
  return undef;
});

//
// json
//
// json->sexp
// Array -> list
// Object -> alist
// (number, boolean, string,
//
const json2sexp = function (json) {
  switch (true) {
    case typeof json === "number" ||
      typeof json === "string" ||
      json === true ||
      json === false:
      return json;
    case Array.isArray(json):
      return array_to_list(json.map(json2sexp));
    case typeof json == "object":
      var ls = nil;
      for (key in json) {
        ls = new Pair(new Pair(key, json2sexp(json[key])), ls);
      }
      return ls;
    default:
      throw new Error(
        "json->sexp: detected invalid value for json: " + inspect(json)
      );
  }
};
define_libfunc("json->sexp", 1, 1, function (ar) {
  return json2sexp(ar[0]);
});

// (vector-push! v item1 item2 ...)
define_libfunc("vector-push!", 2, null, function (ar) {
  assert_vector(ar[0]);
  for (var i = 1; i < ar.length; i++) {
    ar[0].push(ar[i]);
  }
  return ar[0];
});

//
//from Gauche
//

// (identity obj)
// Returns obj.
define_libfunc("identity", 1, 1, function (ar) {
  return ar[0];
});

// (inc! i)
// = (begin (set! i (+ i 1)) i)
// Increments i (i.e., set i+1 to i).
define_syntax("inc!", function (x) {
  var target = x.cdr.car;
  return List(
    Sym("begin"),
    List(Sym("set!"), target, List(Sym("+"), target, 1)),
    target
  );
});

// (dec! i)
// = (begin (set! i (- i 1)) i)
// Decrements i (i.e., set i-1 to i).
define_syntax("dec!", function (x) {
  var target = x.cdr.car;
  return List(
    Sym("begin"),
    List(Sym("set!"), target, List(Sym("-"), target, 1)),
    target
  );
});

// string

define_libfunc("string-concat", 1, 1, function (ar) {
  assert_list(ar[0]);
  return ar[0].to_array().join("");
});

define_libfunc("string-split", 2, 2, function (ar) {
  assert_string(ar[0]);
  assert_string(ar[1]);
  return array_to_list(ar[0].split(ar[1]));
});

define_libfunc("string-join", 1, 2, function (ar) {
  assert_list(ar[0]);
  var delim = "";
  if (ar[1]) {
    assert_string(ar[1]);
    delim = ar[1];
  }
  return ar[0].to_array().join(delim);
});

// lists

define_libfunc("intersperse", 2, 2, function (ar) {
  var item = ar[0],
    ls = ar[1];
  assert_list(ls);

  var ret = [];
  ls.to_array()
    .reverse()
    .forEach(function (x) {
      ret.push(x);
      ret.push(item);
    });
  ret.pop();
  return array_to_list(ret);
});

define_libfunc("map-with-index", 2, null, function (ar) {
  var proc = ar.shift(),
    lists = ar;
  lists.forEach(assert_list);

  var results = [],
    i = 0;
  return Call.multi_foreach(lists, {
    call: function (xs) {
      var args = xs.map(function (x) {
        return x.car;
      });
      args.unshift(i);
      i++;
      return new Call(proc, args);
    },
    result: function (res) {
      results.push(res);
    },
    finish: function () {
      return array_to_list(results);
    },
  });
});

// loop

// (dotimes (variable limit result) body ...)
// Iterate with variable 0 to limit-1.
// ->
//    (do ((tlimit limit)
//         (variable 0 (+ variable 1)))
//        ((>= variable tlimit) result)
//      body ...)
define_syntax("dotimes", function (x) {
  var spec = x.cdr.car,
    bodies = x.cdr.cdr;
  var variable = spec.car,
    limit = spec.cdr.car,
    result = spec.cdr.cdr.car;
  var tlimit = gensym();

  var do_vars = deep_array_to_list([
    [tlimit, limit],
    [variable, 0, [Sym("+"), variable, 1]],
  ]);
  var do_check = deep_array_to_list([[Sym(">="), variable, tlimit], result]);

  return new Pair(Sym("do"), new Pair(do_vars, new Pair(do_check, bodies)));
});

// sorting (Obsolete: use list-sort, etc. instead of these.)

// utility function. takes a JS Array and a Scheme procedure,
// returns sorted array
var sort_with_comp = function (ary, proc, intp) {
  return ary.sort(function (a, b) {
    var intp2 = new Interpreter(intp);
    return intp2.invoke_closure(proc, [a, b]);
  });
};

define_libfunc("list-sort/comp", 1, 2, function (ar, intp) {
  assert_procedure(ar[0]);
  assert_list(ar[1]);

  return array_to_list(sort_with_comp(ar[1].to_array(), ar[0], intp));
});
define_libfunc("vector-sort/comp", 1, 2, function (ar, intp) {
  assert_procedure(ar[0]);
  assert_vector(ar[1]);

  return sort_with_comp([...ar[1]], ar[0], intp);
});
define_libfunc("vector-sort/comp!", 1, 2, function (ar, intp) {
  assert_procedure(ar[0]);
  assert_vector(ar[1]);

  sort_with_comp(ar[1], ar[0], intp);
  return undef;
});

// macros

//(define-macro (foo x y) body ...)
//(define-macro foo lambda)

var rearrange_args = function (expected, given) {
  var args = [];
  var dotpos = new Compiler().find_dot_pos(expected);
  if (dotpos == -1) args = given;
  else {
    for (var i = 0; i < dotpos; i++) {
      args[i] = given[i];
    }
    args[i] = array_to_list(given.slice(i));
  }
  return args;
};
define_syntax("define-macro", function (x) {
  var head = x.cdr.car;
  var expected_args;
  if (head instanceof Pair) {
    var name = head.car;
    expected_args = head.cdr;
    var body = x.cdr.cdr;
    var lambda = new Pair(Sym("lambda"), new Pair(expected_args, body));
  } else {
    var name = head;
    var lambda = x.cdr.cdr.car;
    expected_args = lambda.cdr.car;
  }

  //["close", <args>, <n>, <body>, <opecodes_next>, <dotpos>]
  var opc = Compiler.compile(lambda).il;
  if (opc[2] != 0)
    throw new Bug(
      "you cannot use free variables in macro expander (or define-macro must be on toplevel)"
    );
  var cls = new Closure(opc[3], [], -1, undefined);

  TopEnv[name.name] = new Syntax(name.name, function (sexp) {
    var given_args = sexp.to_array();

    given_args.shift();

    var intp = new Interpreter();
    var args = rearrange_args(expected_args, given_args);
    var result = intp.invoke_closure(cls, args);
    return result;
  });

  return undef;
});

var macroexpand_1 = function (x) {
  if (x instanceof Pair) {
    // TODO: Should we check CoreEnv too?
    if (x.car instanceof BiwaSymbol && TopEnv[x.car.name] instanceof Syntax) {
      var transformer = TopEnv[x.car.name];
      x = transformer.transform(x);
    } else
      throw new Error("macroexpand-1: `" + to_write$1(x) + "' is not a macro");
  }
  return x;
};
define_syntax("%macroexpand", function (x) {
  var expanded = Compiler.expand(x.cdr.car);
  return List(Sym("quote"), expanded);
});
define_syntax("%macroexpand-1", function (x) {
  var expanded = macroexpand_1(x.cdr.car);
  return List(Sym("quote"), expanded);
});

define_libfunc("macroexpand", 1, 1, function (ar) {
  return Compiler.expand(ar[0]);
});
define_libfunc("macroexpand-1", 1, 1, function (ar) {
  return macroexpand_1(ar[0]);
});

define_libfunc("gensym", 0, 1, function (ar) {
  if (ar[0] == undefined) {
    return gensym();
  } else {
    assert_string(ar[0]);
    return gensym(ar[0]);
  }
});

// i/o

define_libfunc("print", 1, null, function (ar) {
  ar.map(function (item) {
    Console.puts(to_display(item), true);
  });
  Console.puts(""); //newline
  return undef;
});
define_libfunc("write-to-string", 1, 1, function (ar) {
  return to_write$1(ar[0]);
});
define_libfunc("read-from-string", 1, 1, function (ar) {
  assert_string(ar[0]);
  return Interpreter.read(ar[0]);
});
define_libfunc("port-closed?", 1, 1, function (ar) {
  assert_port(ar[0]);
  return !ar[0].is_open;
});
//define_libfunc("with-input-from-port", 2, 2, function(ar){
//define_libfunc("with-error-to-port", 2, 2, function(ar){
define_libfunc("with-output-to-port", 2, 2, function (ar) {
  var port = ar[0],
    proc = ar[1];
  assert_port(port);
  assert_procedure(proc);

  var original_port = Port.current_output;
  Port.current_output = port;

  return new Call(proc, [port], function (ar) {
    port.close();
    Port.current_output = original_port;

    return ar[0];
  });
});

// syntax

define_syntax("let1", function (x) {
  //(let1 vari expr body ...)
  //=> ((lambda (var) body ...) expr)
  var vari = x.cdr.car;
  var expr = x.cdr.cdr.car;
  var body = x.cdr.cdr.cdr;

  return new Pair(
    new Pair(Sym("lambda"), new Pair(new Pair(vari, nil), body)),
    new Pair(expr, nil)
  );
});

//
// Regular Expression
//
var assert_regexp = function (obj, fname) {
  if (!(obj instanceof RegExp))
    throw new Error(fname + ": regexp required, but got " + to_write$1(obj));
};

//Function: string->regexp string &keyword case-fold
define_libfunc("string->regexp", 1, 1, function (ar) {
  assert_string(ar[0], "string->regexp");
  return new RegExp(ar[0]); //todo: case-fold
});
//Function: regexp? obj
define_libfunc("regexp?", 1, 1, function (ar) {
  return ar[0] instanceof RegExp;
});
//Function: regexp->string regexp
define_libfunc("regexp->string", 1, 1, function (ar) {
  assert_regexp(ar[0], "regexp->string");
  return ar[0].toString().slice(1, -1); //cut '/'
});

define_libfunc("regexp-exec", 2, 2, function (ar) {
  var rexp = ar[0];
  if (typeof ar[0] === "string") {
    rexp = new RegExp(ar[0]);
  }
  assert_regexp(rexp, "regexp-exec");
  assert_string(ar[1], "regexp-exec");
  var ret = rexp.exec(ar[1]);
  return ret === null ? false : array_to_list(ret);
});

//  //Function: rxmatch regexp string
//  define_libfunc("rxmatch", 1, 1, function(ar){
//    assert_regexp(ar[0], "rxmatch");
//    assert_string(ar[1], "rxmatch");
//    return ar[0].match(ar[1]);
//  });
//Function: rxmatch-start match &optional (i 0)
//Function: rxmatch-end match &optional (i 0)
//Function: rxmatch-substring match &optional (i 0)
//Function: rxmatch-num-matches match
//Function: rxmatch-after match &optional (i 0)
//Function: rxmatch-before match &optional (i 0)
//Generic application: regmatch &optional index
//Generic application: regmatch 'before &optional index
//Generic application: regmatch 'after &optional index
//Function: regexp-replace regexp string substitution

// regexp-replace-all regexp string substitution
define_libfunc("regexp-replace-all", 3, 3, function (ar) {
  var pat = ar[0];
  if (typeof pat === "string") {
    var rexp = new RegExp(pat, "g");
  } else {
    assert_regexp(pat);
    var rexp = new RegExp(pat.source, "g");
  }
  assert_string(ar[1]);
  assert_string(ar[2]);
  return ar[1].replace(rexp, ar[2]);
});
//Function: regexp-replace* string rx1 sub1 rx2 sub2 ...
//Function: regexp-replace-all* string rx1 sub1 rx2 sub2 ...
//Function: regexp-quote string
//Macro: rxmatch-let match-expr (var ...) form ...
//Macro: rxmatch-if match-expr (var ...) then-form else-form
//Macro: rxmatch-cond clause ...
//Macro: rxmatch-case string-expr clause ...

//
// interface to javascript
//
define_libfunc("js-eval", 1, 1, function (ar) {
  return eval(ar[0]);
});
define_libfunc("js-ref", 2, 2, function (ar) {
  if (typeof ar[1] === "string" || Number.isInteger(ar[1])) {
    return ar[0][ar[1]];
  } else {
    assert_symbol(ar[1]);
    return ar[0][ar[1].name];
  }
});
define_libfunc("js-set!", 3, 3, function (ar) {
  assert_string(ar[1]);
  ar[0][ar[1]] = ar[2];
  return undef;
});

// (js-call (js-eval "Math.pow") 2 4)
define_libfunc("js-call", 1, null, function (ar) {
  var js_func = ar.shift();
  assert_function(js_func);

  var receiver = null;
  return js_func.apply(receiver, ar);
});
// (js-invoke (js-new "Date") "getTime")
define_libfunc("js-invoke", 2, null, function (ar) {
  var js_obj = ar.shift();
  var func_name = ar.shift();
  if (!typeof func_name === "string") {
    assert_symbol(func_name);
    func_name = func_name.name;
  }
  if (js_obj[func_name]) return js_obj[func_name].apply(js_obj, ar);
  else throw new Error("js-invoke: function " + func_name + " is not defined");
});

// Short hand for JavaScript method call.
//
// (js-invocation obj '(foo 1 2 3))  ;=> obj.foo(1,2,3)
// (js-invocation obj '(foo 1 2 3)   ;=> obj.foo(1,2,3)
//                    'bar           ;      .bar
//                    '(baz 4 5))    ;      .baz(4,5)
// (js-invocation 'Math '(pow 2 3))  ;=> Math.pow(2,3)
//
// It also converts
//   (lambda (e) ...) to
//   (js-closure (lambda (e) ...))
//   and
//   '((a . b) (c . 4)) to
//   {a: "b", c: 4}
//
define_libfunc("js-invocation", 2, null, function (ar, intp) {
  var receiver = ar.shift();
  // TODO: convert lambdas by js-closure
  if (isSymbol(receiver)) {
    receiver = eval(receiver.name); //XXX: is this ok?
  }

  var v = receiver;

  // Process each method call
  ar.forEach(function (callspec) {
    if (isSymbol(callspec)) {
      // Property access
      v = v[callspec.name];
    } else if (isList(callspec)) {
      // Method call
      var args = callspec.to_array();

      assert_symbol(args[0]);
      var method = args.shift().name;

      // Convert arguments
      args = args.map(function (arg) {
        if (isClosure(arg)) {
          // closure -> JavaScript funciton
          return js_closure(arg, intp);
        } else if (isList(arg)) {
          // alist -> JavaScript Object
          var o = {};
          arg.foreach(function (pair) {
            assert_symbol(pair.car);
            o[pair.car.name] = pair.cdr;
          });
          return o;
        } else return arg;
      });

      // Call the method
      if (!typeof v[method] === "function") {
        throw new BiwaError(
          "js-invocation: the method `" + method + "' not found"
        );
      }
      v = v[method].apply(v, args);
    } else {
      // (wrong argument)
      throw new BiwaError(
        "js-invocation: expected list or symbol for callspec but got " +
          inspect(callspec)
      );
    }
  });

  return v;
});

// TODO: provide corresponding macro ".."
define_syntax("..", function (x) {
  if (x.cdr == nil) {
    throw new Error("malformed ..");
  }
  return new Pair(Sym("js-invocation"), x.cdr);
});

// (js-new (js-eval "Date") 2005 1 1)
// (js-new (js-eval "Draggable") elem 'onEnd (lambda (drg) ...))
//   If symbol is given, following arguments are converted to
//   an js object. If any of them is a scheme closure,
//   it is converted to js function which invokes that closure.
//
// (js-new "Date" 2005 1 1)
//   You can pass javascript program string for constructor.
define_libfunc("js-new", 1, null, function (ar, intp) {
  // make js object from key-value pair
  var array_to_obj = function (ary) {
    if (ary.length % 2 != 0)
      throw new Error("js-new: odd number of key-value pair");

    var obj = {};
    for (var i = 0; i < ary.length; i += 2) {
      var key = ary[i],
        value = ary[i + 1];
      assert_symbol(key);
      if (isClosure(value)) value = js_closure(value, intp);

      obj[key.name] = value;
    }
    return obj;
  };

  var ctor = ar.shift();
  if (typeof ctor === "string") ctor = eval(ctor);

  if (ar.length == 0) {
    return new ctor();
  } else {
    // pack args to js object, if symbol appears
    var args = [];
    for (var i = 0; i < ar.length; i++) {
      if (ar[i] instanceof BiwaSymbol) {
        args.push(array_to_obj(ar.slice(i)));
        break;
      } else {
        args.push(ar[i]);
      }
    }
    // Run `new ctor(...args)`;
    return new (Function.prototype.bind.apply(ctor, [null].concat(args)))();
  }
});

// (js-obj "foo" 1 "bar" 2)
// -> {"foo": 1, "bar": 2}
define_libfunc("js-obj", 0, null, function (ar) {
  if (ar.length % 2 != 0) {
    throw new Error("js-obj: number of arguments must be even");
  }

  var obj = {};
  for (let i = 0; i < ar.length / 2; i++) {
    assert_string(ar[i * 2]);
    obj[ar[i * 2]] = ar[i * 2 + 1];
  }
  return obj;
});

const js_closure = function (proc, intp) {
  var intp2 = new Interpreter(intp);
  return function (/*args*/) {
    return intp2.invoke_closure(proc, Array.from(arguments));
  };
};
// (js-closure (lambda (event) ..))
// Returns a js function which executes the given procedure.
//
// Example
//   (add-handler! ($ "#btn") "click" (js-closure on-click))
define_libfunc("js-closure", 1, 1, function (ar, intp) {
  assert_closure(ar[0]);
  return js_closure(ar[0], intp);
});

define_libfunc("js-null?", 1, 1, function (ar) {
  return ar[0] === null;
});

define_libfunc("js-undefined?", 1, 1, function (ar) {
  return ar[0] === undefined;
});

define_libfunc("js-function?", 1, 1, function (ar) {
  return typeof ar[0] === "function";
});

define_libfunc("js-array-to-list", 1, 1, function (ar) {
  deprecate("js-array-to-list", "1.0", "js-array->list");
  return array_to_list(ar[0]);
});

define_libfunc("js-array->list", 1, 1, function (ar) {
  return array_to_list(ar[0]);
});

define_libfunc("list-to-js-array", 1, 1, function (ar) {
  deprecate("list-to-js-array", "1.0", "list->js-array");
  return ar[0].to_array();
});

define_libfunc("list->js-array", 1, 1, function (ar) {
  return ar[0].to_array();
});

define_libfunc("alist-to-js-obj", 1, 1, function (ar) {
  deprecate("alist-to-js-obj", "1.0", "alist->js-obj");
  return alist_to_js_obj(ar[0]);
});

define_libfunc("alist->js-obj", 1, 1, function (ar) {
  assert_list(ar[0]);
  return alist_to_js_obj(ar[0]);
});

define_libfunc("js-obj-to-alist", 1, 1, function (ar) {
  deprecate("js-obj-to-alist", "1.0", "js-obj->alist");
  return js_obj_to_alist(ar[0]);
});
define_libfunc("js-obj->alist", 1, 1, function (ar) {
  return js_obj_to_alist(ar[0]);
});

//
// timer, sleep
//
define_libfunc("timer", 2, 2, function (ar, intp) {
  var proc = ar[0],
    sec = ar[1];
  assert_closure(proc);
  assert_real(sec);
  var intp2 = new Interpreter(intp);
  setTimeout(function () {
    intp2.invoke_closure(proc);
  }, sec * 1000);
  return undef;
});
define_libfunc("set-timer!", 2, 2, function (ar, intp) {
  var proc = ar[0],
    sec = ar[1];
  assert_closure(proc);
  assert_real(sec);
  var intp2 = new Interpreter(intp);
  return setInterval(function () {
    intp2.invoke_closure(proc);
  }, sec * 1000);
});
define_libfunc("clear-timer!", 1, 1, function (ar) {
  var timer_id = ar[0];
  clearInterval(timer_id);
  return undef;
});
define_libfunc("sleep", 1, 1, function (ar) {
  var sec = ar[0];
  assert_real(sec);
  return new Pause(function (pause) {
    setTimeout(function () {
      pause.resume(nil);
    }, sec * 1000);
  });
});

//
// console
//
// (console-debug obj1 ...)
// (console-log obj1 ...)
// (console-info obj1 ...)
// (console-warn obj1 ...)
// (console-error obj1 ...)
//   Put objects to console, if window.console is defined.
//   Returns obj1.
//
// Example:
//     (some-func arg1 (console-debug arg2) arg3)
var define_console_func = function (name) {
  define_libfunc("console-" + name, 1, null, function (ar) {
    var con = window.console;
    if (con) {
      var vals = ar.map(function (item) {
        return inspect(item, { fallback: item });
      });

      con[name].apply(con, vals);
    }
    return ar[0];
  });
};
define_console_func("debug");
define_console_func("log");
define_console_func("info");
define_console_func("warn");
define_console_func("error");

///
/// R6RS Base library
///

//
//        11.4  Expressions
//
//            11.4.1  Quotation
//(quote)
//            11.4.2  Procedures
//(lambda)
//            11.4.3  Conditionaar
//(if)
//            11.4.4  Assignments
//(set!)
//            11.4.5  Derived conditionaar

define_syntax("cond", function (x) {
  var clauses = x.cdr;
  if (!(clauses instanceof Pair) || clauses === nil) {
    throw new BiwaError(
      "malformed cond: cond needs list but got " + write_ss(clauses)
    );
  }
  // TODO: assert that clauses is a proper list

  var ret = null;
  clauses
    .to_array()
    .reverse()
    .forEach(function (clause) {
      if (!(clause instanceof Pair)) {
        throw new BiwaError("bad clause in cond: " + write_ss(clause));
      }

      if (clause.car === Sym("else")) {
        if (ret !== null) {
          throw new BiwaError(
            "'else' clause of cond followed by more clauses: " +
              write_ss(clauses)
          );
        } else if (clause.cdr === nil) {
          // pattern A: (else)
          //  -> #f            ; not specified in R6RS...?
          ret = false;
        } else if (clause.cdr.cdr === nil) {
          // pattern B: (else expr)
          //  -> expr
          ret = clause.cdr.car;
        } else {
          // pattern C: (else expr ...)
          //  -> (begin expr ...)
          ret = new Pair(Sym("begin"), clause.cdr);
        }
      } else {
        var test = clause.car;
        if (clause.cdr === nil) {
          // pattern 1: (test)
          //  -> (or test ret)
          ret = List(Sym("or"), test, ret);
        } else if (clause.cdr.cdr === nil) {
          // pattern 2: (test expr)
          //  -> (if test expr ret)
          ret = List(Sym("if"), test, clause.cdr.car, ret);
        } else if (clause.cdr.car === Sym("=>")) {
          // pattern 3: (test => expr)
          //  -> (let ((#<gensym1> test))
          //       (if test (expr #<gensym1>) ret))
          var test = clause.car,
            expr = clause.cdr.cdr.car;
          var tmp_sym = gensym();

          ret = List(
            Sym("let"),
            List(List(tmp_sym, test)),
            List(Sym("if"), test, List(expr, tmp_sym), ret)
          );
        } else {
          // pattern 4: (test expr ...)
          //  -> (if test (begin expr ...) ret)
          ret = List(Sym("if"), test, new Pair(Sym("begin"), clause.cdr), ret);
        }
      }
    });
  return ret;
});

define_syntax("case", function (x) {
  var tmp_sym = gensym();

  if (x.cdr === nil) {
    throw new BiwaError("case: at least one clause is required");
  } else if (!(x.cdr instanceof Pair)) {
    throw new BiwaError("case: proper list is required");
  } else {
    // (case key clauses ....)
    //  -> (let ((#<gensym1> key))
    var key = x.cdr.car;
    var clauses = x.cdr.cdr;

    var ret = undefined;
    clauses
      .to_array()
      .reverse()
      .forEach(function (clause) {
        if (clause.car === Sym("else")) {
          // pattern 0: (else expr ...)
          //  -> (begin expr ...)
          if (ret === undefined) {
            ret = new Pair(Sym("begin"), clause.cdr);
          } else {
            throw new BiwaError(
              "case: 'else' clause followed by more clauses: " +
                write_ss(clauses)
            );
          }
        } else {
          // pattern 1: ((datum ...) expr ...)
          //  -> (if (or (eqv? key (quote d1)) ...) (begin expr ...) ret)
          ret = List(
            Sym("if"),
            new Pair(
              Sym("or"),
              array_to_list(
                clause.car.to_array().map(function (d) {
                  return List(Sym("eqv?"), tmp_sym, List(Sym("quote"), d));
                })
              )
            ),
            new Pair(Sym("begin"), clause.cdr),
            ret
          );
        }
      });
    return new Pair(
      Sym("let1"),
      new Pair(tmp_sym, new Pair(key, new Pair(ret, nil)))
    );
  }
});

define_syntax("and", function (x) {
  // (and a b c) => (if a (if b c #f) #f)
  //todo: check improper list
  if (x.cdr == nil) return true;

  var objs = x.cdr.to_array();
  var i = objs.length - 1;
  var t = objs[i];
  for (i = i - 1; i >= 0; i--) t = List(Sym("if"), objs[i], t, false);

  return t;
});

define_syntax("or", function (x) {
  // (or a b c) => (if a a (if b b (if c c #f)))
  //todo: check improper list

  var objs = x.cdr.to_array();
  var f = false;
  for (var i = objs.length - 1; i >= 0; i--)
    f = List(Sym("if"), objs[i], objs[i], f);

  return f;
});

//            11.4.6  Binding constructs
define_syntax("let", function (x) {
  //(let ((a 1) (b 2)) (print a) (+ a b))
  //=> ((lambda (a b) (print a) (+ a b)) 1 2)
  var name = null;
  if (x.cdr.car instanceof BiwaSymbol) {
    name = x.cdr.car;
    x = x.cdr;
  }
  var binds = x.cdr.car,
    body = x.cdr.cdr;

  if (!(binds instanceof Pair) && binds != nil) {
    throw new BiwaError(
      "let: need a pair for bindings: got " + to_write$1(binds)
    );
  }

  var vars = nil,
    vals = nil;
  for (var p = binds; p instanceof Pair; p = p.cdr) {
    if (!(p.car instanceof Pair)) {
      throw new BiwaError(
        "let: need a pair for bindings: got " + to_write$1(p.car)
      );
    }
    vars = new Pair(p.car.car, vars);
    vals = new Pair(p.car.cdr.car, vals);
  }

  var lambda = null;
  if (name) {
    // (let loop ((a 1) (b 2)) body ..)
    //=> (letrec ((loop (lambda (a b) body ..))) (loop 1 2))
    vars = array_to_list(vars.to_array().reverse());
    vals = array_to_list(vals.to_array().reverse());

    var body_lambda = new Pair(Sym("lambda"), new Pair(vars, body));
    var init_call = new Pair(name, vals);

    lambda = List(
      Sym("letrec"),
      new Pair(List(name, body_lambda), nil),
      init_call
    );
  } else {
    lambda = new Pair(new Pair(Sym("lambda"), new Pair(vars, body)), vals);
  }
  return lambda;
});

define_syntax("let*", function (x) {
  //(let* ((a 1) (b a)) (print a) (+ a b))
  //-> (let ((a 1))
  //     (let ((b a)) (print a) (+ a b)))
  var binds = x.cdr.car,
    body = x.cdr.cdr;

  if (binds === nil) return new Pair(Sym("let"), new Pair(nil, body));

  if (!(binds instanceof Pair))
    throw new BiwaError(
      "let*: need a pair for bindings: got " + to_write$1(binds)
    );

  var ret = null;
  binds
    .to_array()
    .reverse()
    .forEach(function (bind) {
      ret = new Pair(
        Sym("let"),
        new Pair(new Pair(bind, nil), ret == null ? body : new Pair(ret, nil))
      );
    });
  return ret;
});

var expand_letrec_star = function (x) {
  var binds = x.cdr.car,
    body = x.cdr.cdr;

  if (!(binds instanceof Pair))
    throw new BiwaError(
      "letrec*: need a pair for bindings: got " + to_write$1(binds)
    );

  var ret = body;
  binds
    .to_array()
    .reverse()
    .forEach(function (bind) {
      ret = new Pair(new Pair(Sym("set!"), bind), ret);
    });
  var letbody = nil;
  binds
    .to_array()
    .reverse()
    .forEach(function (bind) {
      letbody = new Pair(new Pair(bind.car, new Pair(undef, nil)), letbody);
    });
  return new Pair(Sym("let"), new Pair(letbody, ret));
};
define_syntax("letrec", expand_letrec_star);
define_syntax("letrec*", expand_letrec_star);

define_syntax("let-values", function (x) {
  // (let-values (((a b) (values 1 2))
  //               ((c d . e) (values 3 4 a)))
  //              (print a b c d e))
  // =>
  // (let ((#<gensym1> (lambda () (values 1 2)))
  //       (#<gensym2> (lambda () (values 3 4 a))))
  //   (let*-values (((a b) #<gensym1>)
  //                 ((c d . e) #<gensym2>))
  //                 (print a b c d e)))
  var mv_bindings = x.cdr.car;
  var body = x.cdr.cdr;
  var ret = null;

  var let_bindings = nil;
  var let_star_values_bindings = nil;
  mv_bindings
    .to_array()
    .reverse()
    .forEach(function (item) {
      var init = item.cdr.car;
      var tmpsym = gensym();
      var binding = new Pair(
        tmpsym,
        new Pair(
          new Pair(Sym("lambda"), new Pair(nil, new Pair(init, nil))),
          nil
        )
      );
      let_bindings = new Pair(binding, let_bindings);

      var formals = item.car;
      let_star_values_bindings = new Pair(
        new Pair(formals, new Pair(new Pair(tmpsym, nil), nil)),
        let_star_values_bindings
      );
    });

  var let_star_values = new Pair(
    Sym("let*-values"),
    new Pair(let_star_values_bindings, body)
  );
  ret = new Pair(
    Sym("let"),
    new Pair(let_bindings, new Pair(let_star_values, nil))
  );
  return ret;
});

//let*-values
define_syntax("let*-values", function (x) {
  // (let*-values (((a b) (values 1 2))
  //               ((c d . e) (values 3 4 a)))
  //   (print a b c d e))
  // -> (call-with-values
  //      (lambda () (values 1 2))
  //      (lambda (a b)
  //        (call-with-values
  //          (lambda () (values 3 4 a))
  //          (lambda (c d . e)
  //            (print a b c d e)))))
  var mv_bindings = x.cdr.car;
  var body = x.cdr.cdr;

  var ret = null;

  mv_bindings
    .to_array()
    .reverse()
    .forEach(function (item) {
      var formals = item.car,
        init = item.cdr.car;
      ret = new Pair(
        Sym("call-with-values"),
        new Pair(
          new Pair(Sym("lambda"), new Pair(nil, new Pair(init, nil))),
          new Pair(
            new Pair(
              Sym("lambda"),
              new Pair(formals, ret == null ? body : new Pair(ret, nil))
            ),
            nil
          )
        )
      );
    });
  return ret;
});
//            11.4.7  Sequencing
//(begin)

//
//        11.5  Equivalence predicates
//
define_libfunc("eqv?", 2, 2, function (ar) {
  return eqv(ar[0], ar[1]);
});
define_libfunc("eq?", 2, 2, function (ar) {
  return eq(ar[0], ar[1]);
});
define_libfunc("equal?", 2, 2, function (ar) {
  return equal(ar[0], ar[1]);
});

//
//        11.6  Procedure predicate
//
//"procedure?", 1, 1
define_libfunc("procedure?", 1, 1, function (ar) {
  return isProcedure(ar[0]);
});

//
//        11.7  Arithmetic
//

//            11.7.1  Propagation of exactness and inexactness
//            11.7.2  Representability of infinities and NaNs
//            11.7.3  Semantics of common operations
//                11.7.3.1  Integer division
//                11.7.3.2  Transcendental functions
//(no functions are introduced by above sections)

//
//            11.7.4  Numerical operations
//

//                11.7.4.1  Numerical type predicates
define_libfunc("number?", 1, 1, function (ar) {
  return isNumber(ar[0]);
});
define_libfunc("complex?", 1, 1, function (ar) {
  return isComplex(ar[0]);
});
define_libfunc("real?", 1, 1, function (ar) {
  return isReal(ar[0]);
});
define_libfunc("rational?", 1, 1, function (ar) {
  return isRational(ar[0]);
});
define_libfunc("integer?", 1, 1, function (ar) {
  return isInteger(ar[0]);
});

//(real-valued? obj)    procedure
//(rational-valued? obj)    procedure
//(integer-valued? obj)    procedure
//
//(exact? z)    procedure
//(inexact? z)    procedure

//                11.7.4.2  Generic conversions
//
//(inexact z)    procedure
//(exact z)    procedure
//
//                11.7.4.3  Arithmetic operations

//inf & nan: ok (for this section)
define_libfunc("=", 2, null, function (ar) {
  var v = ar[0];
  assert_number(ar[0]);
  for (var i = 1; i < ar.length; i++) {
    assert_number(ar[i]);
    if (real_part(ar[i]) != real_part(v)) return false;
    if (imag_part(ar[i]) != imag_part(v)) return false;
  }
  return true;
});
define_libfunc("<", 2, null, function (ar) {
  assert_number(ar[0]);
  for (var i = 1; i < ar.length; i++) {
    assert_number(ar[i]);
    if (!(ar[i - 1] < ar[i])) return false;
  }
  return true;
});
define_libfunc(">", 2, null, function (ar) {
  assert_number(ar[0]);
  for (var i = 1; i < ar.length; i++) {
    assert_number(ar[i]);
    if (!(ar[i - 1] > ar[i])) return false;
  }
  return true;
});
define_libfunc("<=", 2, null, function (ar) {
  assert_number(ar[0]);
  for (var i = 1; i < ar.length; i++) {
    assert_number(ar[i]);
    if (!(ar[i - 1] <= ar[i])) return false;
  }
  return true;
});
define_libfunc(">=", 2, null, function (ar) {
  assert_number(ar[0]);
  for (var i = 1; i < ar.length; i++) {
    assert_number(ar[i]);
    if (!(ar[i - 1] >= ar[i])) return false;
  }
  return true;
});

define_libfunc("zero?", 1, 1, function (ar) {
  assert_number(ar[0]);
  return ar[0] === 0;
});
define_libfunc("positive?", 1, 1, function (ar) {
  assert_number(ar[0]);
  return ar[0] > 0;
});
define_libfunc("negative?", 1, 1, function (ar) {
  assert_number(ar[0]);
  return ar[0] < 0;
});
define_libfunc("odd?", 1, 1, function (ar) {
  assert_number(ar[0]);
  return ar[0] % 2 == 1 || ar[0] % 2 == -1;
});
define_libfunc("even?", 1, 1, function (ar) {
  assert_number(ar[0]);
  return ar[0] % 2 == 0;
});
define_libfunc("finite?", 1, 1, function (ar) {
  assert_number(ar[0]);
  return ar[0] != Infinity && ar[0] != -Infinity && !isNaN(ar[0]);
});
define_libfunc("infinite?", 1, 1, function (ar) {
  assert_number(ar[0]);
  return ar[0] == Infinity || ar[0] == -Infinity;
});
define_libfunc("nan?", 1, 1, function (ar) {
  assert_number(ar[0]);
  return isNaN(ar[0]);
});
define_libfunc("max", 2, null, function (ar) {
  for (var i = 0; i < ar.length; i++) assert_number(ar[i]);

  return Math.max.apply(null, ar);
});
define_libfunc("min", 2, null, function (ar) {
  for (var i = 0; i < ar.length; i++) assert_number(ar[i]);

  return Math.min.apply(null, ar);
});

var complex_or_real = function (real, imag) {
  if (imag === 0) return real;
  return new Complex(real, imag);
};
var polar_or_real = function (magnitude, angle) {
  if (angle === 0) return magnitude;
  return Complex.from_polar(magnitude, angle);
};
define_libfunc("+", 0, null, function (ar) {
  var real = 0;
  var imag = 0;
  for (var i = 0; i < ar.length; i++) {
    assert_number(ar[i]);
    real += real_part(ar[i]);
    imag += imag_part(ar[i]);
  }
  return complex_or_real(real, imag);
});
var the_magnitude = function (n) {
  if (n instanceof Complex) return n.magnitude();
  return n;
};
var the_angle = function (n) {
  if (n instanceof Complex) return n.angle();
  return 0;
};
define_libfunc("*", 0, null, function (ar) {
  var magnitude = 1;
  var angle = 0;
  for (var i = 0; i < ar.length; i++) {
    assert_number(ar[i]);
    magnitude *= the_magnitude(ar[i]);
    angle += the_angle(ar[i]);
  }
  return polar_or_real(magnitude, angle);
});
define_libfunc("-", 1, null, function (ar) {
  var len = ar.length;
  assert_number(ar[0]);

  if (len == 1) {
    if (ar[0] instanceof Complex)
      return new Complex(-real_part(ar[0]), -imag_part(ar[0]));
    return -ar[0];
  } else {
    var real = real_part(ar[0]);
    var imag = imag_part(ar[0]);
    for (var i = 1; i < len; i++) {
      assert_number(ar[i]);
      real -= real_part(ar[i]);
      imag -= imag_part(ar[i]);
    }
    return complex_or_real(real, imag);
  }
});
//for r6rs specification, (/ 0 0) or (/ 3 0) raises '&assertion exception'
define_libfunc("/", 1, null, function (ar) {
  var len = ar.length;
  assert_number(ar[0]);

  if (len == 1) {
    if (ar[0] instanceof Complex)
      return Complex.from_polar(1 / the_magnitude(ar[0]), -the_angle(ar[0]));
    return 1 / ar[0];
  } else {
    var magnitude = the_magnitude(ar[0]);
    var angle = the_angle(ar[0]);
    for (var i = 1; i < len; i++) {
      assert_number(ar[i]);
      magnitude /= the_magnitude(ar[i]);
      angle -= the_angle(ar[i]);
    }
    return polar_or_real(magnitude, angle);
  }
});

define_libfunc("abs", 1, 1, function (ar) {
  assert_number(ar[0]);
  return Math.abs(ar[0]);
});

var div = function (n, m) {
  return Math.floor(n / m);
};
var mod = function (n, m) {
  return n - Math.floor(n / m) * m;
};
var div0 = function (n, m) {
  return n > 0 ? Math.floor(n / m) : Math.ceil(n / m);
};
var mod0 = function (n, m) {
  return n > 0 ? n - Math.floor(n / m) * m : n - Math.ceil(n / m) * m;
};
define_libfunc("div-and-mod", 2, 2, function (ar) {
  assert_number(ar[0]);
  assert_number(ar[1]);
  return new Values([div(ar[0], ar[1]), mod(ar[0], ar[1])]);
});
define_libfunc("div", 2, 2, function (ar) {
  assert_number(ar[0]);
  assert_number(ar[1]);
  return div(ar[0], ar[1]);
});
define_libfunc("mod", 2, 2, function (ar) {
  assert_number(ar[0]);
  assert_number(ar[1]);
  return mod(ar[0], ar[1]);
});
define_libfunc("div0-and-mod0", 2, 2, function (ar) {
  assert_number(ar[0]);
  assert_number(ar[1]);
  return new Values([div0(ar[0], ar[1]), mod0(ar[0], ar[1])]);
});
define_libfunc("div0", 2, 2, function (ar) {
  assert_number(ar[0]);
  assert_number(ar[1]);
  return div0(ar[0], ar[1]);
});
define_libfunc("mod0", 2, 2, function (ar) {
  assert_number(ar[0]);
  assert_number(ar[1]);
  return mod0(ar[0], ar[1]);
});

// R7RS
alias_libfunc("div-and-mod", "floor/");
alias_libfunc("div", "floor-quotient");
alias_libfunc("mod", "floor-remainder");
var truncate_q = function (n, m) {
  return Math.trunc(n / m);
};
var truncate_r = function (n, m) {
  return n - Math.trunc(n / m) * m;
};
define_libfunc("truncate/", 2, 2, function (ar) {
  assert_number(ar[0]);
  assert_number(ar[1]);
  return new Values([truncate_q(ar[0], ar[1]), truncate_r(ar[0], ar[1])]);
});
define_libfunc("truncate-quotient", 2, 2, function (ar) {
  assert_number(ar[0]);
  assert_number(ar[1]);
  return truncate_q(ar[0], ar[1]);
});
define_libfunc("truncate-remainder", 2, 2, function (ar) {
  assert_number(ar[0]);
  assert_number(ar[1]);
  return truncate_r(ar[0], ar[1]);
});
alias_libfunc("truncate-quotient", "quotient");
alias_libfunc("truncate-remainder", "remainder");
alias_libfunc("floor-remainder", "modulo");

//(gcd n1 ...)    procedure
//(lcm n1 ...)    procedure

define_libfunc("numerator", 1, 1, function (ar) {
  assert_number(ar[0]);
  if (ar[0] instanceof Rational$1) return ar[0].numerator;
  else throw new Bug("todo");
});
define_libfunc("denominator", 1, 1, function (ar) {
  assert_number(ar[0]);
  if (ar[0] instanceof Rational$1) return ar[0].denominator;
  else throw new Bug("todo");
});
define_libfunc("floor", 1, 1, function (ar) {
  assert_number(ar[0]);
  return Math.floor(ar[0]);
});
define_libfunc("ceiling", 1, 1, function (ar) {
  assert_number(ar[0]);
  return Math.ceil(ar[0]);
});
define_libfunc("truncate", 1, 1, function (ar) {
  assert_number(ar[0]);
  return ar[0] < 0 ? Math.ceil(ar[0]) : Math.floor(ar[0]);
});
define_libfunc("round", 1, 1, function (ar) {
  assert_number(ar[0]);
  return Math.round(ar[0]);
});

//(rationalize x1 x2)    procedure

define_libfunc("exp", 1, 1, function (ar) {
  assert_number(ar[0]);
  return Math.exp(ar[0]);
});
define_libfunc("log", 1, 2, function (ar) {
  var num = ar[0],
    base = ar[1];
  assert_number(num);

  if (base) {
    // log b num == log e num / log e b
    assert_number(base);
    return Math.log(num) / Math.log(base);
  } else return Math.log(num);
});
define_libfunc("sin", 1, 1, function (ar) {
  assert_number(ar[0]);
  return Math.sin(ar[0]);
});
define_libfunc("cos", 1, 1, function (ar) {
  assert_number(ar[0]);
  return Math.cos(ar[0]);
});
define_libfunc("tan", 1, 1, function (ar) {
  assert_number(ar[0]);
  return Math.tan(ar[0]);
});
define_libfunc("asin", 1, 1, function (ar) {
  assert_number(ar[0]);
  return Math.asin(ar[0]);
});
define_libfunc("acos", 1, 1, function (ar) {
  assert_number(ar[0]);
  return Math.acos(ar[0]);
});
define_libfunc("atan", 1, 2, function (ar) {
  assert_number(ar[0]);
  if (ar.length == 2) {
    assert_number(ar[1]);
    return Math.atan2(ar[0], ar[1]);
  } else return Math.atan(ar[0]);
});
define_libfunc("sqrt", 1, 1, function (ar) {
  assert_number(ar[0]);
  return Math.sqrt(ar[0]);
});
define_libfunc("exact-integer-sqrt", 1, 1, function (ar) {
  assert_number(ar[0]);
  var sqrt_f = Math.sqrt(ar[0]);
  var sqrt_i = sqrt_f - (sqrt_f % 1);
  var rest = ar[0] - sqrt_i * sqrt_i;

  return new Values([sqrt_i, rest]);
});
define_libfunc("expt", 2, 2, function (ar) {
  assert_number(ar[0]);
  assert_number(ar[1]);
  return Math.pow(ar[0], ar[1]);
});
define_libfunc("make-rectangular", 2, 2, function (ar) {
  assert_number(ar[0]);
  assert_number(ar[1]);
  return new Complex(ar[0], ar[1]);
});
define_libfunc("make-polar", 2, 2, function (ar) {
  assert_number(ar[0]);
  assert_number(ar[1]);
  return Complex.from_polar(ar[0], ar[1]);
});
var real_part = function (n) {
  return Complex.assure(n).real;
};
var imag_part = function (n) {
  return Complex.assure(n).imag;
};
define_libfunc("real-part", 1, 1, function (ar) {
  assert_number(ar[0]);
  return real_part(ar[0]);
});
define_libfunc("imag-part", 1, 1, function (ar) {
  assert_number(ar[0]);
  return Complex.assure(ar[0]).imag;
});
define_libfunc("magnitude", 1, 1, function (ar) {
  assert_number(ar[0]);
  return Complex.assure(ar[0]).magnitude();
});
define_libfunc("angle", 1, 1, function (ar) {
  assert_number(ar[0]);
  return Complex.assure(ar[0]).angle();
});

//
//                11.7.4.4  Numerical Input and Output
//
define_libfunc("number->string", 1, 3, function (ar) {
  var z = ar[0],
    radix = ar[1],
    precision = ar[2];
  if (precision)
    throw new Bug("number->string: precision is not yet implemented");

  radix = radix || 10; //TODO: check radix is 2, 8, 10, or 16.
  return z.toString(radix);
});
define_libfunc("string->number", 1, 3, function (ar) {
  var s = ar[0];

  if (s === "+inf.0") return Infinity;

  if (s === "-inf.0") return -Infinity;

  if (s === "+nan.0") return NaN;

  var radix = ar[1];

  var int_res = parse_integer(s, radix === 0 ? 0 : radix || 10);

  if (int_res !== false) return int_res;

  if (radix !== undefined && radix !== 10) return false;

  var fp_res = parse_float(s);

  if (fp_res !== false) return fp_res;

  var frac_res = parse_fraction(s);

  if (frac_res !== false) return frac_res;

  return false;
});

//
//        11.8  Booleans
//

define_libfunc("not", 1, 1, function (ar) {
  return ar[0] === false ? true : false;
});
define_libfunc("boolean?", 1, 1, function (ar) {
  return ar[0] === false || ar[0] === true ? true : false;
});
define_libfunc("boolean=?", 2, null, function (ar) {
  var len = ar.length;
  for (var i = 1; i < len; i++) {
    if (ar[i] != ar[0]) return false;
  }
  return true;
});

//        11.9  Pairs and lists

define_libfunc("pair?", 1, 1, function (ar) {
  return ar[0] instanceof Pair ? true : false;
});
define_libfunc("cons", 2, 2, function (ar) {
  return new Pair(ar[0], ar[1]);
});
define_libfunc("car", 1, 1, function (ar) {
  //should raise &assertion for '()...
  if (!(ar[0] instanceof Pair))
    throw new BiwaError("Attempt to apply car on " + ar[0]);
  return ar[0].car;
});
define_libfunc("cdr", 1, 1, function (ar) {
  //should raise &assertion for '()...
  if (!(ar[0] instanceof Pair))
    throw new BiwaError("Attempt to apply cdr on " + ar[0]);
  return ar[0].cdr;
});
define_libfunc("set-car!", 2, 2, function (ar) {
  if (!(ar[0] instanceof Pair))
    throw new BiwaError("Attempt to apply set-car! on " + ar[0]);
  ar[0].car = ar[1];
  return undef;
});
define_libfunc("set-cdr!", 2, 2, function (ar) {
  if (!(ar[0] instanceof Pair))
    throw new BiwaError("Attempt to apply set-cdr! on " + ar[0]);
  ar[0].cdr = ar[1];
  return undef;
});

// cadr, caddr, cadddr, etc.
(function () {
  // To traverse into pair and raise error
  var get = function (funcname, spec, obj) {
    var ret = obj;
    spec.forEach(function (is_cdr) {
      if (ret instanceof Pair) {
        ret = is_cdr ? ret.cdr : ret.car;
      } else {
        throw new BiwaError(
          funcname +
            ": attempt to get " +
            (is_cdr ? "cdr" : "car") +
            " of " +
            ret
        );
      }
    });
    return ret;
  };
  define_libfunc("caar", 1, 1, function (ar) {
    return get("caar", [0, 0], ar[0]);
  });
  define_libfunc("cadr", 1, 1, function (ar) {
    return get("cadr", [1, 0], ar[0]);
  });
  define_libfunc("cdar", 1, 1, function (ar) {
    return get("cadr", [0, 1], ar[0]);
  });
  define_libfunc("cddr", 1, 1, function (ar) {
    return get("cadr", [1, 1], ar[0]);
  });

  define_libfunc("caaar", 1, 1, function (ar) {
    return get("caaar", [0, 0, 0], ar[0]);
  });
  define_libfunc("caadr", 1, 1, function (ar) {
    return get("caadr", [1, 0, 0], ar[0]);
  });
  define_libfunc("cadar", 1, 1, function (ar) {
    return get("cadar", [0, 1, 0], ar[0]);
  });
  define_libfunc("caddr", 1, 1, function (ar) {
    return get("caddr", [1, 1, 0], ar[0]);
  });
  define_libfunc("cdaar", 1, 1, function (ar) {
    return get("cdaar", [0, 0, 1], ar[0]);
  });
  define_libfunc("cdadr", 1, 1, function (ar) {
    return get("cdadr", [1, 0, 1], ar[0]);
  });
  define_libfunc("cddar", 1, 1, function (ar) {
    return get("cddar", [0, 1, 1], ar[0]);
  });
  define_libfunc("cdddr", 1, 1, function (ar) {
    return get("cdddr", [1, 1, 1], ar[0]);
  });

  define_libfunc("caaaar", 1, 1, function (ar) {
    return get("caaaar", [0, 0, 0, 0], ar[0]);
  });
  define_libfunc("caaadr", 1, 1, function (ar) {
    return get("caaadr", [1, 0, 0, 0], ar[0]);
  });
  define_libfunc("caadar", 1, 1, function (ar) {
    return get("caadar", [0, 1, 0, 0], ar[0]);
  });
  define_libfunc("caaddr", 1, 1, function (ar) {
    return get("caaddr", [1, 1, 0, 0], ar[0]);
  });
  define_libfunc("cadaar", 1, 1, function (ar) {
    return get("cadaar", [0, 0, 1, 0], ar[0]);
  });
  define_libfunc("cadadr", 1, 1, function (ar) {
    return get("cadadr", [1, 0, 1, 0], ar[0]);
  });
  define_libfunc("caddar", 1, 1, function (ar) {
    return get("caddar", [0, 1, 1, 0], ar[0]);
  });
  define_libfunc("cadddr", 1, 1, function (ar) {
    return get("cadddr", [1, 1, 1, 0], ar[0]);
  });
  define_libfunc("cdaaar", 1, 1, function (ar) {
    return get("cdaaar", [0, 0, 0, 1], ar[0]);
  });
  define_libfunc("cdaadr", 1, 1, function (ar) {
    return get("cdaadr", [1, 0, 0, 1], ar[0]);
  });
  define_libfunc("cdadar", 1, 1, function (ar) {
    return get("cdadar", [0, 1, 0, 1], ar[0]);
  });
  define_libfunc("cdaddr", 1, 1, function (ar) {
    return get("cdaddr", [1, 1, 0, 1], ar[0]);
  });
  define_libfunc("cddaar", 1, 1, function (ar) {
    return get("cddaar", [0, 0, 1, 1], ar[0]);
  });
  define_libfunc("cddadr", 1, 1, function (ar) {
    return get("cddadr", [1, 0, 1, 1], ar[0]);
  });
  define_libfunc("cdddar", 1, 1, function (ar) {
    return get("cdddar", [0, 1, 1, 1], ar[0]);
  });
  define_libfunc("cddddr", 1, 1, function (ar) {
    return get("cddddr", [1, 1, 1, 1], ar[0]);
  });
})();

define_libfunc("null?", 1, 1, function (ar) {
  return ar[0] === nil;
});
define_libfunc("list?", 1, 1, function (ar) {
  return isList(ar[0]);
});
define_libfunc("list", 0, null, function (ar) {
  var l = nil;
  for (var i = ar.length - 1; i >= 0; i--) l = new Pair(ar[i], l);
  return l;
});
define_libfunc("length", 1, 1, function (ar) {
  assert_list(ar[0]);
  var n = 0;
  for (var o = ar[0]; o != nil; o = o.cdr) n++;
  return n;
});
define_libfunc("append", 1, null, function (ar) {
  var k = ar.length;
  var ret = ar[--k];
  while (k--) {
    ar[k]
      .to_array()
      .reverse()
      .forEach(function (item) {
        ret = new Pair(item, ret);
      });
  }
  return ret;
});
define_libfunc("reverse", 1, 1, function (ar) {
  // (reverse '()) => '()
  if (ar[0] == nil) return nil;
  assert_pair(ar[0]);

  var l = nil;
  for (var o = ar[0]; o != nil; o = o.cdr) l = new Pair(o.car, l);
  return l;
});
define_libfunc("list-tail", 2, 2, function (ar) {
  assert_pair(ar[0]);
  assert_integer(ar[1]);
  if (ar[1] < 0)
    throw new BiwaError("list-tail: index out of range (" + ar[1] + ")");

  var o = ar[0];
  for (var i = 0; i < ar[1]; i++) {
    if (!(o instanceof Pair))
      throw new BiwaError("list-tail: the list is shorter than " + ar[1]);
    o = o.cdr;
  }
  return o;
});
define_libfunc("list-ref", 2, 2, function (ar) {
  assert_pair(ar[0]);
  assert_integer(ar[1]);
  if (ar[1] < 0)
    throw new BiwaError("list-tail: index out of range (" + ar[1] + ")");

  var o = ar[0];
  for (var i = 0; i < ar[1]; i++) {
    if (!(o instanceof Pair))
      throw new BiwaError("list-ref: the list is shorter than " + ar[1]);
    o = o.cdr;
  }
  return o.car;
});
define_libfunc("map", 2, null, function (ar) {
  var proc = ar.shift(),
    lists = ar;
  lists.forEach(assert_list);

  var a = [];
  return Call.multi_foreach(lists, {
    // Called for each element
    // input: the element (or the elements, if more than one list is given)
    // output: a Call request of proc and args
    call: function (xs) {
      return new Call(
        proc,
        xs.map(function (x) {
          return x.car;
        })
      );
    },

    // Called when each Call request is finished
    // input: the result of Call request,
    //   the element(s) of the Call request (which is not used here)
    // output: `undefined' to continue,
    //   some value to terminate (the value will be the result)
    result: function (res) {
      a.push(res);
    },

    // Called when reached to the end of the list(s)
    // input: none
    // output: the resultant value
    finish: function () {
      return array_to_list(a);
    },
  });
});
define_libfunc("for-each", 2, null, function (ar) {
  var proc = ar.shift(),
    lists = ar;
  lists.forEach(assert_list);

  return Call.multi_foreach(lists, {
    call: function (xs) {
      return new Call(
        proc,
        xs.map(function (x) {
          return x.car;
        })
      );
    },
    finish: function () {
      return undef;
    },
  });
});

//        11.10  Symbols

define_libfunc("symbol?", 1, 1, function (ar) {
  return ar[0] instanceof BiwaSymbol ? true : false;
});
define_libfunc("symbol->string", 1, 1, function (ar) {
  assert_symbol(ar[0]);
  return ar[0].name;
});
define_libfunc("symbol=?", 2, null, function (ar) {
  assert_symbol(ar[0]);
  for (var i = 1; i < ar.length; i++) {
    assert_symbol(ar[i]);
    if (ar[i] != ar[0]) return false;
  }
  return true;
});
define_libfunc("string->symbol", 1, 1, function (ar) {
  assert_string(ar[0]);
  return Sym(ar[0]);
});

//
//        11.11  Characters
//
define_libfunc("char?", 1, 1, function (ar) {
  return ar[0] instanceof Char;
});
define_libfunc("char->integer", 1, 1, function (ar) {
  assert_char(ar[0]);
  return ar[0].value.charCodeAt(0);
});
define_libfunc("integer->char", 1, 1, function (ar) {
  assert_integer(ar[0]);
  return Char.get(String.fromCharCode(ar[0]));
});

var make_char_compare_func = function (test) {
  return function (ar) {
    assert_char(ar[0]);
    for (var i = 1; i < ar.length; i++) {
      assert_char(ar[i]);
      if (!test(ar[i - 1].value, ar[i].value)) return false;
    }
    return true;
  };
};
define_libfunc(
  "char=?",
  2,
  null,
  make_char_compare_func(function (a, b) {
    return a == b;
  })
);
define_libfunc(
  "char<?",
  2,
  null,
  make_char_compare_func(function (a, b) {
    return a < b;
  })
);
define_libfunc(
  "char>?",
  2,
  null,
  make_char_compare_func(function (a, b) {
    return a > b;
  })
);
define_libfunc(
  "char<=?",
  2,
  null,
  make_char_compare_func(function (a, b) {
    return a <= b;
  })
);
define_libfunc(
  "char>=?",
  2,
  null,
  make_char_compare_func(function (a, b) {
    return a >= b;
  })
);

//
//        11.12  Strings
//
define_libfunc("string?", 1, 1, function (ar) {
  return typeof ar[0] == "string";
});
define_libfunc("make-string", 1, 2, function (ar) {
  assert_integer(ar[0]);
  var c = " ";
  if (ar[1]) {
    assert_char(ar[1]);
    c = ar[1].value;
  }
  var out = "";
  Array(ar[0])
    .fill()
    .map(() => {
      out += c;
    });
  return out;
});
define_libfunc("string", 0, null, function (ar) {
  if (ar.length == 0) return "";
  for (var i = 0; i < ar.length; i++) assert_char(ar[i]);
  return ar
    .map(function (c) {
      return c.value;
    })
    .join("");
});
define_libfunc("string-length", 1, 1, function (ar) {
  assert_string(ar[0]);
  return ar[0].length;
});
define_libfunc("string-ref", 2, 2, function (ar) {
  assert_string(ar[0]);
  assert_between(ar[1], 0, ar[0].length - 1);
  return Char.get(ar[0].charAt([ar[1]]));
});
define_libfunc("string=?", 2, null, function (ar) {
  assert_string(ar[0]);
  for (var i = 1; i < ar.length; i++) {
    assert_string(ar[i]);
    if (ar[0] != ar[i]) return false;
  }
  return true;
});
define_libfunc("string<?", 2, null, function (ar) {
  assert_string(ar[0]);
  for (var i = 1; i < ar.length; i++) {
    assert_string(ar[i]);
    if (!(ar[i - 1] < ar[i])) return false;
  }
  return true;
});
define_libfunc("string>?", 2, null, function (ar) {
  assert_string(ar[0]);
  for (var i = 1; i < ar.length; i++) {
    assert_string(ar[i]);
    if (!(ar[i - 1] > ar[i])) return false;
  }
  return true;
});
define_libfunc("string<=?", 2, null, function (ar) {
  assert_string(ar[0]);
  for (var i = 1; i < ar.length; i++) {
    assert_string(ar[i]);
    if (!(ar[i - 1] <= ar[i])) return false;
  }
  return true;
});
define_libfunc("string>=?", 2, null, function (ar) {
  assert_string(ar[0]);
  for (var i = 1; i < ar.length; i++) {
    assert_string(ar[i]);
    if (!(ar[i - 1] >= ar[i])) return false;
  }
  return true;
});

define_libfunc("substring", 3, 3, function (ar) {
  assert_string(ar[0]);
  assert_integer(ar[1]);
  assert_integer(ar[2]);

  if (ar[1] < 0) throw new BiwaError("substring: start too small: " + ar[1]);
  if (ar[2] < 0) throw new BiwaError("substring: end too small: " + ar[2]);
  if (ar[0].length + 1 <= ar[1])
    throw new BiwaError("substring: start too big: " + ar[1]);
  if (ar[0].length + 1 <= ar[2])
    throw new BiwaError("substring: end too big: " + ar[2]);
  if (!(ar[1] <= ar[2]))
    throw new BiwaError("substring: not start <= end: " + ar[1] + ", " + ar[2]);

  return ar[0].substring(ar[1], ar[2]);
});

define_libfunc("string-append", 0, null, function (ar) {
  for (var i = 0; i < ar.length; i++) assert_string(ar[i]);

  return ar.join("");
});
define_libfunc("string->list", 1, 1, function (ar) {
  assert_string(ar[0]);
  return array_to_list(
    ar[0].split("").map(function (s) {
      return Char.get(s[0]);
    })
  );
});
define_libfunc("list->string", 1, 1, function (ar) {
  assert_list(ar[0]);
  return ar[0]
    .to_array()
    .map(function (c) {
      return c.value;
    })
    .join("");
});
define_libfunc("string-for-each", 2, null, function (ar) {
  var proc = ar.shift(),
    strs = ar;
  strs.forEach(assert_string);

  return Call.multi_foreach(strs, {
    call: function (chars) {
      return new Call(proc, chars);
    },
    finish: function () {
      return undef;
    },
  });
});
define_libfunc("string-copy", 1, 1, function (ar) {
  // note: this is useless, because javascript strings are immutable
  assert_string(ar[0]);
  return ar[0];
});

//
//        11.13  Vectors
//
define_libfunc("vector?", 1, 1, function (ar) {
  return isVector(ar[0]);
});
define_libfunc("make-vector", 1, 2, function (ar) {
  assert_integer(ar[0]);
  var vec = new Array(ar[0]);

  if (ar.length == 2) {
    for (var i = 0; i < ar[0]; i++) vec[i] = ar[1];
  }
  return vec;
});
define_libfunc("vector", 0, null, function (ar) {
  return ar;
});
define_libfunc("vector-length", 1, 1, function (ar) {
  assert_vector(ar[0]);
  return ar[0].length;
});
define_libfunc("vector-ref", 2, 2, function (ar) {
  assert_vector(ar[0]);
  assert_integer(ar[1]);
  assert_between(ar[1], 0, ar[0].length - 1);

  return ar[0][ar[1]];
});
define_libfunc("vector-set!", 3, 3, function (ar) {
  assert_vector(ar[0]);
  assert_integer(ar[1]);

  ar[0][ar[1]] = ar[2];
  return undef;
});
define_libfunc("vector->list", 1, 1, function (ar) {
  assert_vector(ar[0]);
  return array_to_list(ar[0]);
});
define_libfunc("list->vector", 1, 1, function (ar) {
  assert_list(ar[0]);
  return ar[0].to_array();
});
define_libfunc("vector-fill!", 2, 2, function (ar) {
  assert_vector(ar[0]);
  var vec = ar[0],
    obj = ar[1];

  for (var i = 0; i < vec.length; i++) vec[i] = obj;
  return vec;
});
define_libfunc("vector-map", 2, null, function (ar) {
  var proc = ar.shift(),
    vecs = ar;
  vecs.forEach(assert_vector);

  var a = [];
  return Call.multi_foreach(vecs, {
    call: function (objs) {
      return new Call(proc, objs);
    },
    result: function (res) {
      a.push(res);
    },
    finish: function () {
      return a;
    },
  });
});
define_libfunc("vector-for-each", 2, null, function (ar) {
  var proc = ar.shift(),
    vecs = ar;
  vecs.forEach(assert_vector);

  return Call.multi_foreach(vecs, {
    call: function (objs) {
      return new Call(proc, objs);
    },
    finish: function () {
      return undef;
    },
  });
});

//
//        11.14  Errors and violations
//
//(error who message irritant1 ...)    procedure
//(assertion-violation who message irritant1 ...)    procedure
//(assert <expression>)    syntax

//
//        11.15  Control features
//
define_libfunc("apply", 2, null, function (ar) {
  var proc = ar.shift(),
    rest_args = ar.pop(),
    args = ar;
  args = args.concat(rest_args.to_array());

  return new Call(proc, args);
});
define_syntax("call-with-current-continuation", function (x) {
  return new Pair(Sym("call/cc"), x.cdr);
});
define_libfunc("values", 0, null, function (ar) {
  if (ar.length == 1)
    // eg. (values 3)
    return ar[0];
  else return new Values(ar);
});
define_libfunc("call-with-values", 2, 2, function (ar) {
  var producer = ar[0],
    consumer = ar[1];
  assert_procedure(producer);
  assert_procedure(consumer);
  return new Call(producer, [], function (ar) {
    var result = ar[0];
    if (result instanceof Values) {
      return new Call(consumer, result.content);
    } else {
      // eg. (call-with-values (lambda () 3)
      //                       (lambda (x) x))
      return new Call(consumer, [result]);
    }
  });
});

define_libfunc("dynamic-wind", 3, 3, function (ar, intp) {
  var before = ar[0],
    thunk = ar[1],
    after = ar[2];
  return new Call(before, [], function () {
    intp.push_dynamic_winder(before, after);
    return new Call(thunk, [], function (ar2) {
      var result = ar2[0];
      intp.pop_dynamic_winder();
      return new Call(after, [], function () {
        return result;
      });
    });
  });
});

//        11.16  Iteration
//named let

//        11.17  Quasiquotation
// `() is expanded to `cons` and `append`.
// `#() is expanded to `vector` and `vector-append`.
var expand_qq = function (f, lv) {
  if (f instanceof BiwaSymbol || f === nil) {
    return List(Sym("quote"), f);
  } else if (f instanceof Pair) {
    var car = f.car;
    if (car instanceof Pair && car.car === Sym("unquote-splicing")) {
      if (lv == 1) {
        if (f.cdr === nil) {
          return f.car.cdr.car;
        } else {
          return List(Sym("append"), f.car.cdr.car, expand_qq(f.cdr, lv));
        }
      } else
        return List(
          Sym("cons"),
          List(
            Sym("list"),
            List(Sym("quote"), Sym("unquote-splicing")),
            expand_qq(f.car.cdr.car, lv - 1)
          ),
          expand_qq(f.cdr, lv)
        );
    } else if (car === Sym("unquote")) {
      if (lv == 1) return f.cdr.car;
      else
        return List(
          Sym("list"),
          List(Sym("quote"), Sym("unquote")),
          expand_qq(f.cdr.car, lv - 1)
        );
    } else if (car === Sym("quasiquote"))
      return List(
        Sym("list"),
        List(Sym("quote"), Sym("quasiquote")),
        expand_qq(f.cdr.car, lv + 1)
      );
    else return List(Sym("cons"), expand_qq(f.car, lv), expand_qq(f.cdr, lv));
  } else if (f instanceof Array) {
    var vecs = [[]];
    for (var i = 0; i < f.length; i++) {
      if (f[i] instanceof Pair && f[i].car === Sym("unquote-splicing")) {
        if (lv == 1) {
          var item = List(Sym("list->vector"), f[i].cdr.car);
          item["splicing"] = true;
          vecs.push(item);
          vecs.push([]);
        } else {
          var item = List(
            Sym("cons"),
            List(
              Sym("list"),
              List(Sym("quote"), Sym("unquote-splicing")),
              expand_qq(f[i].car.cdr.car, lv - 1)
            ),
            expand_qq(f[i].cdr, lv)
          );
          vecs[vecs.length - 1].push(item);
        }
      } else {
        // Expand other things as the same as if they are in a list quasiquote
        vecs[vecs.length - 1].push(expand_qq(f[i], lv));
      }
    }

    var vectors = vecs.map(function (vec) {
      if (vec["splicing"]) {
        return vec;
      } else {
        return Cons(Sym("vector"), array_to_list(vec));
      }
    });
    if (vectors.length == 1) {
      return Cons(Sym("vector"), array_to_list(vecs[0]));
    } else {
      return Cons(Sym("vector-append"), array_to_list(vectors));
    }
  } else return f;
};
define_syntax("quasiquote", function (x) {
  return expand_qq(x.cdr.car, 1);
});
//unquote
define_syntax("unquote", function (x) {
  throw new BiwaError("unquote(,) must be inside quasiquote(`)");
});
//unquote-splicing
define_syntax("unquote-splicing", function (x) {
  throw new BiwaError("unquote-splicing(,@) must be inside quasiquote(`)");
});

//        11.18  Binding constructs for syntactic keywords
//let-syntax
//letrec-syntax

//        11.19  Macro transformers
//syntax-rules
//identifier-syntax
//

//        11.20  Tail calls and tail contexts
//(no library function introduced)

///
/// R6RS Standard Libraries
///

//
// Chapter 1 Unicode
//
//(char-upcase char)    procedure
//(char-downcase char)    procedure
//(char-titlecase char)    procedure
//(char-foldcase char)    procedure
//
//(char-ci=? char1 char2 char3 ...)    procedure
//(char-ci<? char1 char2 char3 ...)    procedure
//(char-ci>? char1 char2 char3 ...)    procedure
//(char-ci<=? char1 char2 char3 ...)    procedure
//(char-ci>=? char1 char2 char3 ...)    procedure
//
//(char-alphabetic? char)    procedure
//(char-numeric? char)    procedure
//(char-whitespace? char)    procedure
//(char-upper-case? char)    procedure
//(char-lower-case? char)    procedure
//(char-title-case? char)    procedure
//
//(char-general-category char)    procedure

//(string-upcase string)    procedure
define_libfunc("string-upcase", 1, 1, function (ar) {
  assert_string(ar[0]);
  return ar[0].toUpperCase();
});
//(string-downcase string)    procedure
define_libfunc("string-downcase", 1, 1, function (ar) {
  assert_string(ar[0]);
  return ar[0].toLowerCase();
});
//(string-titlecase string)    procedure
//(string-foldcase string)    procedure

const make_string_ci_function = function (compare) {
  return function (ar) {
    assert_string(ar[0]);
    var str = ar[0].toUpperCase();

    for (var i = 1; i < ar.length; i++) {
      assert_string(ar[i]);
      if (!compare(str, ar[i].toUpperCase())) return false;
    }
    return true;
  };
};
//(string-ci=? string1 string2 string3 ...)    procedure
define_libfunc(
  "string-ci=?",
  2,
  null,
  make_string_ci_function(function (a, b) {
    return a == b;
  })
);
//(string-ci<? string1 string2 string3 ...)    procedure
define_libfunc(
  "string-ci<?",
  2,
  null,
  make_string_ci_function(function (a, b) {
    return a < b;
  })
);
//(string-ci>? string1 string2 string3 ...)    procedure
define_libfunc(
  "string-ci>?",
  2,
  null,
  make_string_ci_function(function (a, b) {
    return a > b;
  })
);
//(string-ci<=? string1 string2 string3 ...)    procedure
define_libfunc(
  "string-ci<=?",
  2,
  null,
  make_string_ci_function(function (a, b) {
    return a <= b;
  })
);
//(string-ci>=? string1 string2 string3 ...)    procedure
define_libfunc(
  "string-ci>=?",
  2,
  null,
  make_string_ci_function(function (a, b) {
    return a >= b;
  })
);

//(string-normalize-nfd string)    procedure
//(string-normalize-nfkd string)    procedure
//(string-normalize-nfc string)    procedure
//(string-normalize-nfkc string)    procedure

//
// Chapter 2 Bytevectors
//

//
// Chapter 3 List utilities
//
define_libfunc("find", 2, 2, function (ar) {
  var proc = ar[0],
    ls = ar[1];
  assert_list(ls);
  return Call.foreach(ls, {
    call: function (x) {
      return new Call(proc, [x.car]);
    },
    result: function (res, x) {
      if (res) return x.car;
    },
    finish: function () {
      return false;
    },
  });
});
define_libfunc("for-all", 2, null, function (ar) {
  var proc = ar.shift();
  var lists = ar;
  lists.forEach(assert_list);

  var last = true; //holds last result which proc returns
  return Call.multi_foreach(lists, {
    call: function (pairs) {
      return new Call(
        proc,
        pairs.map(function (x) {
          return x.car;
        })
      );
    },
    result: function (res, pairs) {
      if (res === false) return false;
      last = res;
    },
    finish: function () {
      return last;
    },
  });
});
define_libfunc("exists", 2, null, function (ar) {
  var proc = ar.shift();
  var lists = ar;
  lists.forEach(assert_list);

  return Call.multi_foreach(lists, {
    call: function (pairs) {
      return new Call(
        proc,
        pairs.map(function (x) {
          return x.car;
        })
      );
    },
    result: function (res, pairs) {
      if (res !== false) return res;
    },
    finish: function () {
      return false;
    },
  });
});
define_libfunc("filter", 2, 2, function (ar) {
  var proc = ar[0],
    ls = ar[1];
  assert_list(ls);

  var a = [];
  return Call.foreach(ls, {
    call: function (x) {
      return new Call(proc, [x.car]);
    },
    result: function (res, x) {
      if (res) a.push(x.car);
    },
    finish: function () {
      return array_to_list(a);
    },
  });
});
//  define_scmfunc("partition+", 2, 2,
//    "(lambda (proc ls)  \
//       (define (partition2 proc ls t f) \
//         (if (null? ls) \
//           (values (reverse t) (reverse f)) \
//           (if (proc (car ls)) \
//             (partition2 proc (cdr ls) (cons (car ls) t) f) \
//             (partition2 proc (cdr ls) t (cons (car ls) f))))) \
//       (partition2 proc ls '() '()))");

define_libfunc("partition", 2, 2, function (ar) {
  var proc = ar[0],
    ls = ar[1];
  assert_list(ls);

  var t = [],
    f = [];
  return Call.foreach(ls, {
    call: function (x) {
      return new Call(proc, [x.car]);
    },
    result: function (res, x) {
      if (res) t.push(x.car);
      else f.push(x.car);
    },
    finish: function () {
      return new Values([array_to_list(t), array_to_list(f)]);
    },
  });
});
define_libfunc("fold-left", 3, null, function (ar) {
  var proc = ar.shift(),
    accum = ar.shift(),
    lists = ar;
  lists.forEach(assert_list);

  return Call.multi_foreach(lists, {
    call: function (pairs) {
      var args = pairs.map(function (x) {
        return x.car;
      });
      args.unshift(accum);
      return new Call(proc, args);
    },
    result: function (res, pairs) {
      accum = res;
    },
    finish: function () {
      return accum;
    },
  });
});
define_libfunc("fold-right", 3, null, function (ar) {
  var proc = ar.shift(),
    accum = ar.shift();
  var lists = ar.map(function (ls) {
    // reverse each list
    assert_list(ls);
    return array_to_list(ls.to_array().reverse());
  });

  return Call.multi_foreach(lists, {
    call: function (pairs) {
      var args = pairs.map(function (x) {
        return x.car;
      });
      args.push(accum);
      return new Call(proc, args);
    },
    result: function (res, pairs) {
      accum = res;
    },
    finish: function () {
      return accum;
    },
  });
});
define_libfunc("remp", 2, 2, function (ar) {
  var proc = ar[0],
    ls = ar[1];
  assert_list(ls);

  var ret = [];
  return Call.foreach(ls, {
    call: function (x) {
      return new Call(proc, [x.car]);
    },
    result: function (res, x) {
      if (!res) ret.push(x.car);
    },
    finish: function () {
      return array_to_list(ret);
    },
  });
});
var make_remover = function (key) {
  return function (ar) {
    var obj = ar[0],
      ls = ar[1];
    assert_list(ls);

    var ret = [];
    return Call.foreach(ls, {
      call: function (x) {
        return new Call(TopEnv[key] || CoreEnv[key], [obj, x.car]);
      },
      result: function (res, x) {
        if (!res) ret.push(x.car);
      },
      finish: function () {
        return array_to_list(ret);
      },
    });
  };
};
define_libfunc("remove", 2, 2, make_remover("equal?"));
define_libfunc("remv", 2, 2, make_remover("eqv?"));
define_libfunc("remq", 2, 2, make_remover("eq?"));

define_libfunc("memp", 2, 2, function (ar) {
  var proc = ar[0],
    ls = ar[1];
  assert_list(ls);
  return Call.foreach(ls, {
    call: function (x) {
      return new Call(proc, [x.car]);
    },
    result: function (res, x) {
      if (res) return x;
    },
    finish: function () {
      return false;
    },
  });
});
var make_finder = function (key) {
  return function (ar) {
    var obj = ar[0],
      ls = ar[1];
    assert_list(ls);
    return Call.foreach(ls, {
      call: function (x) {
        return new Call(TopEnv[key] || CoreEnv[key], [obj, x.car]);
      },
      result: function (res, x) {
        if (res) return x;
      },
      finish: function () {
        return false;
      },
    });
  };
};
define_libfunc("member", 2, 2, make_finder("equal?"));
define_libfunc("memv", 2, 2, make_finder("eqv?"));
define_libfunc("memq", 2, 2, make_finder("eq?"));

define_libfunc("assp", 2, 2, function (ar) {
  var proc = ar[0],
    als = ar[1];
  assert_list(als);
  return Call.foreach(als, {
    call: function (x) {
      if (x.car.car) return new Call(proc, [x.car.car]);
      else
        throw new BiwaError("ass*: pair required but got " + to_write$1(x.car));
    },
    result: function (res, x) {
      if (res) return x.car;
    },
    finish: function () {
      return false;
    },
  });
});
var make_assoc = function (func_name, eq_func_name) {
  return function (ar) {
    var obj = ar[0],
      list = ar[1];
    assert_list(list);
    return Call.foreach(list, {
      call: function (ls) {
        if (!isPair(ls.car))
          throw new BiwaError(
            func_name + ": pair required but got " + to_write$1(ls.car)
          );

        var equality = TopEnv[eq_func_name] || CoreEnv[eq_func_name];
        return new Call(equality, [obj, ls.car.car]);
      },
      result: function (was_equal, ls) {
        if (was_equal) return ls.car;
      },
      finish: function () {
        return false;
      },
    });
  };
};
define_libfunc("assoc", 2, 2, make_assoc("assoc", "equal?"));
define_libfunc("assv", 2, 2, make_assoc("assv", "eqv?"));
define_libfunc("assq", 2, 2, make_assoc("assq", "eq?"));

define_libfunc("cons*", 1, null, function (ar) {
  if (ar.length == 1) return ar[0];
  else {
    var ret = null;
    ar.reverse().forEach(function (x) {
      if (ret) {
        ret = new Pair(x, ret);
      } else ret = x;
    });
    return ret;
  }
});

//
// Chapter 4 Sorting
//
(function () {
  // Destructively sort the given array
  // with scheme function `proc` as comparator
  var mergeSort = function (ary, proc, finish) {
    if (ary.length <= 1) return finish(ary);
    return mergeSort_(ary, proc, finish, [[0, ary.length, false]], false);
  };

  var mergeSort_ = function (ary, proc, finish, stack, up) {
    while (true) {
      var start = stack[stack.length - 1][0],
        end = stack[stack.length - 1][1],
        left = stack[stack.length - 1][2];
      var len = end - start;
      //console.debug("mergeSort_", ary, stack.join(' '), up?"u":"d", ""+start+".."+(end-1))

      if (len >= 2 && !up) {
        // There are parts to be sorted
        stack.push([start, start + (len >> 1), true]);
        continue;
      } else if (left) {
        // Left part sorted. Continue to the right one
        stack.pop();
        var rend = stack[stack.length - 1][1];
        stack.push([end, rend, false]);
        up = false;
        continue;
      } else {
        // Right part sorted. Merge left and right
        stack.pop();
        var lstart = stack[stack.length - 1][0];
        var ary1 = ary.slice(lstart, start),
          ary2 = ary.slice(start, end);
        return merge_(ary1, ary2, proc, [], 0, 0, function (ret) {
          //console.debug("mergeSortd", ary, stack.join(' '), up?"u":"d", ary1, ary2, ret, ""+start+".."+(start+len-1));
          for (var i = 0; i < ret.length; i++) {
            ary[lstart + i] = ret[i];
          }

          if (stack.length == 1) {
            return finish(ary);
          } else {
            return mergeSort_(ary, proc, finish, stack, true);
          }
        });
      }
    }
  };

  var merge_ = function (ary1, ary2, proc, ret, i, j, cont) {
    //console.debug("merge_", ary1, ary2, ret, i, j);
    var len1 = ary1.length,
      len2 = ary2.length;
    if (i < len1 && j < len2) {
      return new Call(proc, [ary2[j], ary1[i]], function (ar) {
        //console.debug("comp", [ary2[j], ary1[i]], ar[0]);
        if (ar[0]) {
          ret.push(ary2[j]);
          j += 1;
        } else {
          ret.push(ary1[i]);
          i += 1;
        }
        return merge_(ary1, ary2, proc, ret, i, j, cont);
      });
    } else {
      while (i < len1) {
        ret.push(ary1[i]);
        i += 1;
      }
      while (j < len2) {
        ret.push(ary2[j]);
        j += 1;
      }
      return cont(ret);
    }
  };

  var compareFn = function (a, b) {
    return lt(a, b) ? -1 : lt(b, a) ? 1 : 0;
  };

  define_libfunc("list-sort", 1, 2, function (ar) {
    if (ar[1]) {
      assert_procedure(ar[0]);
      assert_list(ar[1]);
      return mergeSort(ar[1].to_array(), ar[0], function (ret) {
        return array_to_list(ret);
      });
    } else {
      assert_list(ar[0]);
      return array_to_list(ar[0].to_array().sort(compareFn));
    }
  });

  //(vector-sort proc vector)    procedure
  define_libfunc("vector-sort", 1, 2, function (ar) {
    if (ar[1]) {
      assert_procedure(ar[0]);
      assert_vector(ar[1]);
      return mergeSort([...ar[1]], ar[0], function (ret) {
        return ret;
      });
    } else {
      assert_vector(ar[0]);
      return [...ar[0]].sort(compareFn);
    }
  });

  //(vector-sort! proc vector)    procedure
  define_libfunc("vector-sort!", 1, 2, function (ar) {
    if (ar[1]) {
      assert_procedure(ar[0]);
      assert_vector(ar[1]);
      return mergeSort(ar[1], ar[0], function (ret) {
        return undef;
      });
    } else {
      assert_vector(ar[0]);
      ar[0].sort(compareFn);
      return undef;
    }
  });
})();

//
// Chapter 5 Control Structures
//
define_syntax("when", function (x) {
  //(when test body ...)
  //=> (if test (begin body ...) #<undef>)
  var test = x.cdr.car,
    body = x.cdr.cdr;

  return new Pair(
    Sym("if"),
    new Pair(test, new Pair(new Pair(Sym("begin"), body), new Pair(undef, nil)))
  );
});

define_syntax("unless", function (x) {
  //(unless test body ...)
  //=> (if (not test) (begin body ...) #<undef>)
  var test = x.cdr.car,
    body = x.cdr.cdr;

  return new Pair(
    Sym("if"),
    new Pair(
      new Pair(Sym("not"), new Pair(test, nil)),
      new Pair(new Pair(Sym("begin"), body), new Pair(undef, nil))
    )
  );
});

define_syntax("do", function (x) {
  //(do ((var1 init1 step1)
  //     (var2 init2 step2) ...)
  //    (test expr1 expr2 ...)
  //  body1 body2 ...)
  //=> (let loop` ((var1 init1) (var2 init2) ...)
  //     (if test
  //       (begin expr1 expr2 ...)
  //       (begin body1 body2 ...
  //              (loop` step1 step2 ...)))))

  // parse arguments
  if (!isPair(x.cdr)) throw new BiwaError("do: no variables of do");
  var varsc = x.cdr.car;
  if (!isPair(varsc))
    throw new BiwaError("do: variables must be given as a list");
  if (!isPair(x.cdr.cdr)) throw new BiwaError("do: no resulting form of do");
  var resultc = x.cdr.cdr.car;
  var bodyc = x.cdr.cdr.cdr;

  // construct subforms
  var loop = gensym();

  var init_vars = array_to_list(
    varsc.map(function (var_def) {
      var a = var_def.to_array();
      return List(a[0], a[1]);
    })
  );

  var test = resultc.car;
  var result_exprs = new Pair(Sym("begin"), resultc.cdr);

  var next_loop = new Pair(
    loop,
    array_to_list(
      varsc.map(function (var_def) {
        var a = var_def.to_array();
        return a[2] || a[0];
      })
    )
  );
  var body_exprs = new Pair(Sym("begin"), bodyc).concat(List(next_loop));

  // combine subforms
  return List(
    Sym("let"),
    loop,
    init_vars,
    List(Sym("if"), test, result_exprs, body_exprs)
  );
});

//(case-lambda <case-lambda clause> ...)    syntax
define_syntax("case-lambda", function (x) {
  if (!isPair(x.cdr))
    throw new BiwaError("case-lambda: at least 1 clause required");
  var clauses = x.cdr.to_array();

  var args_ = gensym();
  var exec = List(Sym("raise"), "case-lambda: no matching clause found");

  clauses.reverse().forEach(function (clause) {
    if (!isPair(clause))
      throw new BiwaError(
        "case-lambda: clause must be a pair: " + to_write$1(clause)
      );
    var formals = clause.car,
      clause_body = clause.cdr;

    if (formals === nil) {
      exec = List(
        Sym("if"),
        List(Sym("null?"), args_),
        new Pair(Sym("begin"), clause_body),
        exec
      );
    } else if (isPair(formals)) {
      var len = formals.length(),
        last_cdr = formals.last_cdr();
      var pred = last_cdr === nil ? Sym("=") : Sym(">=");
      var lambda = new Pair(Sym("lambda"), new Pair(formals, clause_body));
      exec = List(
        Sym("if"),
        List(pred, List(Sym("length"), args_), len),
        List(Sym("apply"), lambda, args_),
        exec
      );
    } else if (isSymbol(formals)) {
      exec = new Pair(
        Sym("let1"),
        new Pair(formals, new Pair(args_, clause_body))
      );
      // Note: previous `exec` is just discarded because this is a wildcard pattern.
    } else {
      throw new BiwaError(
        "case-lambda: invalid formals: " + to_write$1(formals)
      );
    }
  });

  return List(Sym("lambda"), args_, exec);
});

//
// Chapter 6 Records
// see also: src/system/record.js
//

// 6.2 Records: Syntactic layer
//eqv, eq

//(define-record-type <name spec> <record clause>*)    syntax
define_syntax("define-record-type", function (x) {
  // (define-record-type <name spec> <record clause>*)
  var name_spec = x.cdr.car;
  var record_clauses = x.cdr.cdr;

  // 1. parse name spec
  // <name spec>: either
  // - <record name> eg: point
  // - (<record name> <constructor name> <predicate name>)
  //   eg: (point make-point point?)
  if (isSymbol(name_spec)) {
    var record_name = name_spec;
    var constructor_name = Sym("make-" + name_spec.name);
    var predicate_name = Sym(name_spec.name + "?");
  } else {
    assert_list(name_spec);
    var record_name = name_spec.car;
    var constructor_name = name_spec.cdr.car;
    var predicate_name = name_spec.cdr.cdr.car;
    assert_symbol(record_name);
    assert_symbol(constructor_name);
    assert_symbol(predicate_name);
  }

  // 2. parse record clauses
  var sealed = false;
  var opaque = false;
  var uid = false;
  var parent_name;
  var parent_rtd = false;
  var parent_cd = false;
  var protocol = false;
  var fields = [];

  // <record clause>:
  record_clauses.to_array().forEach(function (clause) {
    switch (clause.car) {
      // - (fields <field spec>*)
      case Sym("fields"):
        fields = clause.cdr.to_array().map(function (field_spec, idx) {
          if (isSymbol(field_spec)) {
            // - <field name>
            return {
              name: field_spec,
              idx: idx,
              mutable: false,
              accessor_name: null,
              mutator_name: null,
            };
          } else {
            assert_list(field_spec);
            assert_symbol(field_spec.car);
            switch (field_spec.car) {
              case Sym("immutable"):
                // - (immutable <field name>)
                // - (immutable <field name> <accessor name>)
                var field_name = field_spec.cdr.car;
                assert_symbol(field_name);

                if (isNil(field_spec.cdr.cdr))
                  return { name: field_name, idx: idx, mutable: false };
                else
                  return {
                    name: field_name,
                    idx: idx,
                    mutable: false,
                    accessor_name: field_spec.cdr.cdr.car,
                  };

              case Sym("mutable"):
                // - (mutable <field name>)
                // - (mutable <field name> <accessor name> <mutator name>)
                var field_name = field_spec.cdr.car;
                assert_symbol(field_name);

                if (isNil(field_spec.cdr.cdr))
                  return { name: field_name, idx: idx, mutable: true };
                else
                  return {
                    name: field_name,
                    idx: idx,
                    mutable: true,
                    accessor_name: field_spec.cdr.cdr.car,
                    mutator_name: field_spec.cdr.cdr.cdr.car,
                  };
              default:
                throw new BiwaError(
                  "define-record-type: field definition " +
                    "must start with `immutable' or `mutable' " +
                    "but got " +
                    inspect(field_spec.car)
                );
            }
          }
        });
        break;
      // - (parent <name>)
      case Sym("parent"):
        parent_name = clause.cdr.car;
        assert_symbol(parent_name);
        break;
      // - (protocol <expr>)
      case Sym("protocol"):
        protocol = clause.cdr.car;
        break;
      // - (sealed <bool>)
      case Sym("sealed"):
        sealed = !!clause.cdr.car;
        break;
      // - (opaque <bool>)
      case Sym("opaque"):
        opaque = !!clause.cdr.car;
        break;
      // - (nongenerative <uid>?)
      case Sym("nongenerative"):
        uid = clause.cdr.car;
        break;
      // - (parent-rtd <rtd> <cd>)
      case Sym("parent-rtd"):
        parent_rtd = clause.cdr.car;
        parent_cd = clause.cdr.cdr.car;
        break;
      default:
        throw new BiwaError(
          "define-record-type: unknown clause `" + to_write$1(clause.car) + "'"
        );
    }
  });

  if (parent_name) {
    parent_rtd = [Sym("record-type-descriptor"), parent_name];
    parent_cd = [Sym("record-constructor-descriptor"), parent_name];
  }

  // 3. build the definitions
  // Note: In this implementation, rtd and cd are not bound to symbols.
  // They are referenced through record name by record-type-descriptor
  // and record-constructor-descriptor. These relation are stored in
  // the hash BiwaScheme.Record._DefinedTypes.
  var rtd = [Sym("record-type-descriptor"), record_name];
  var cd = [Sym("record-constructor-descriptor"), record_name];

  // registration
  var rtd_fields = fields.map(function (field) {
    return List(Sym(field.mutable ? "mutable" : "immutable"), field.name);
  });
  rtd_fields.is_vector = true; //tell List not to convert
  var rtd_def = [
    Sym("make-record-type-descriptor"),
    [Sym("quote"), record_name],
    parent_rtd,
    uid,
    sealed,
    opaque,
    rtd_fields,
  ];
  var cd_def = [
    Sym("make-record-constructor-descriptor"),
    Sym("__rtd"),
    parent_cd,
    protocol,
  ];
  var registration = [
    Sym("let*"),
    [
      [Sym("__rtd"), rtd_def],
      [Sym("__cd"), cd_def],
    ],
    [
      Sym("_define-record-type"),
      [Sym("quote"), record_name],
      Sym("__rtd"),
      Sym("__cd"),
    ],
  ];

  // accessors and mutators
  var accessor_defs = fields.map(function (field) {
    var name =
      field.accessor_name || Sym(record_name.name + "-" + field.name.name);

    return [Sym("define"), name, [Sym("record-accessor"), rtd, field.idx]];
  });

  var mutator_defs = fields.filter(function (field) {
    return field.mutable;
  });
  mutator_defs = mutator_defs.map(function (field) {
    var name =
      field.mutator_name ||
      Sym(record_name.name + "-" + field.name.name + "-set!");

    return [Sym("define"), name, [Sym("record-mutator"), rtd, field.idx]];
  });

  // Wrap the definitions with `begin'
  // Example:
  //   (begin
  //     (let* ((__rtd (make-record-type-descriptor 'square
  //                     (record-type-descriptor rect)
  //                     #f #f #f
  //                     #((mutable w) (mutable h))))
  //            (__cd (make-record-constructor-descriptor __rtd
  //                    (record-constructor-descriptor rect)
  //                    (lambda (n) ...))))
  //       (_define-record-type 'square __rtd __cd))
  //
  //     (define make-square
  //       (record-constructor
  //         (record-constructor-descriptor square)))
  //     (define square?
  //       (record-predicate (record-type-descriptor square)))
  //     (define square-w
  //       (record-accessor (record-type-descriptor square) 0))
  //     (define square-h
  //       (record-accessor (record-type-descriptor square) 1))
  //     (define set-square-w!
  //       (record-mutator (record-type-descriptor square) 0))
  //     (define set-square-h!
  //       (record-mutator (record-type-descriptor square) 1)))
  //
  return deep_array_to_list(
    [
      Sym("begin"),
      registration,
      [Sym("define"), constructor_name, [Sym("record-constructor"), cd]],
      [Sym("define"), predicate_name, [Sym("record-predicate"), rtd]],
    ]
      .concat(accessor_defs)
      .concat(mutator_defs)
  );
});

define_libfunc("_define-record-type", 3, 3, function (ar) {
  assert_symbol(ar[0]);
  assert_record_td(ar[1]);
  assert_record_cd(ar[2]);
  Record.define_type(ar[0].name, ar[1], ar[2]);
  return undef;
});

//(record-type-descriptor <record name>)    syntax
define_syntax("record-type-descriptor", function (x) {
  return deep_array_to_list([
    Sym("_record-type-descriptor"),
    [Sym("quote"), x.cdr.car],
  ]);
});
define_libfunc("_record-type-descriptor", 1, 1, function (ar) {
  assert_symbol(ar[0]);
  var type = Record.get_type(ar[0].name);
  if (type) return type.rtd;
  else
    throw new BiwaError(
      "record-type-descriptor: unknown record type " + ar[0].name
    );
});

//(record-constructor-descriptor <record name>)    syntax
define_syntax("record-constructor-descriptor", function (x) {
  return deep_array_to_list([
    Sym("_record-constructor-descriptor"),
    [Sym("quote"), x.cdr.car],
  ]);
});
define_libfunc("_record-constructor-descriptor", 1, 1, function (ar) {
  assert_symbol(ar[0]);
  var type = Record.get_type(ar[0].name);
  if (type) return type.cd;
  else
    throw new BiwaError(
      "record-constructor-descriptor: unknown record type " + ar[0].name
    );
});

// 6.3  Records: Procedural layer
//(make-record-type-descriptor name    procedure
define_libfunc("make-record-type-descriptor", 6, 6, function (ar) {
  var name = ar[0],
    parent_rtd = ar[1],
    uid = ar[2],
    sealed = ar[3],
    opaque = ar[4],
    fields = ar[5];

  assert_symbol(name);
  if (parent_rtd) assert_record_td(parent_rtd);
  if (uid) {
    assert_symbol(uid);
    var _rtd = Record.RTD.NongenerativeRecords[uid.name];
    if (_rtd) {
      // the record type is already defined.
      return _rtd;
      // should check equality of other arguments..
    }
  }
  sealed = !!sealed;
  opaque = !!opaque;
  assert_vector(fields);
  for (var i = 0; i < fields.length; i++) {
    var list = fields[i];
    assert_symbol(list.car, "mutability");
    assert_symbol(list.cdr.car, "field name");
    fields[i] = [list.cdr.car.name, list.car == Sym("mutable")];
  }
  var rtd = new Record.RTD(name, parent_rtd, uid, sealed, opaque, fields);
  if (uid) Record.RTD.NongenerativeRecords[uid.name] = rtd;

  return rtd;
});

//(record-type-descriptor? obj)    procedure
define_libfunc("record-type-descriptor?", 1, 1, function (ar) {
  return ar[0] instanceof Record.RTD;
});

//(make-record-constructor-descriptor rtd    procedure
define_libfunc("make-record-constructor-descriptor", 3, 3, function (ar) {
  var rtd = ar[0],
    parent_cd = ar[1],
    protocol = ar[2];

  assert_record_td(rtd);
  if (parent_cd) assert_record_cd(parent_cd);
  if (protocol) assert_procedure(protocol);

  return new Record.CD(rtd, parent_cd, protocol);
});

//(record-constructor constructor-descriptor)    procedure
define_libfunc("record-constructor", 1, 1, function (ar) {
  var cd = ar[0];
  assert_record_cd(cd);

  return cd.record_constructor();
});

//(record-predicate rtd)    procedure
define_libfunc("record-predicate", 1, 1, function (ar) {
  var rtd = ar[0];
  assert_record_td(rtd);

  return function (args) {
    var obj = args[0];

    if (!(obj instanceof Record)) {
      return false;
    } else if (obj.rtd === rtd) {
      // Fast path
      return true;
    } else {
      for (let _rtd = obj.rtd; _rtd; _rtd = _rtd.parent_rtd) {
        if (_rtd == rtd) return true;
      }
      return false;
    }
  };
});

//(record-accessor rtd k)    procedure
define_libfunc("record-accessor", 2, 2, function (ar) {
  var rtd = ar[0],
    k = ar[1];
  assert_record_td(rtd);
  assert_integer(k);
  for (var _rtd = rtd.parent_rtd; _rtd; _rtd = _rtd.parent_rtd)
    k += _rtd.fields.length;

  return function (args) {
    var record = args[0];
    var error_msg =
      rtd.name.name +
      "-" +
      rtd.field_name(k) +
      ": " +
      to_write$1(record) +
      " is not a " +
      rtd.name.name;
    assert(isRecord(record), error_msg);

    var descendant = false;
    for (var _rtd = record.rtd; _rtd; _rtd = _rtd.parent_rtd) {
      if (_rtd == rtd) descendant = true;
    }
    assert(descendant, error_msg);

    return record.get(k);
  };
});

//(record-mutator rtd k)    procedure
define_libfunc("record-mutator", 2, 2, function (ar) {
  var rtd = ar[0],
    k = ar[1];
  assert_record_td(rtd);
  assert_integer(k);
  for (var _rtd = rtd.parent_rtd; _rtd; _rtd = _rtd.parent_rtd)
    k += _rtd.fields.length;

  return function (args) {
    var record = args[0],
      val = args[1];
    var func_name = rtd.field_name(k);

    assert_record(record);
    assert(
      record.rtd === rtd,
      func_name + ": " + to_write$1(record) + " is not a " + rtd.name.name
    );
    assert(
      !record.rtd.sealed,
      func_name + ": " + rtd.name.name + " is sealed (can't mutate)"
    );

    record.set(k, val);
  };
});

// 6.4  Records: Inspection
//(record? obj)    procedure
define_libfunc("record?", 1, 1, function (ar) {
  var obj = ar[0];
  if (isRecord(obj)) {
    if (obj.rtd.opaque) return false;
    // opaque records pretend as if it is not a record.
    else return true;
  } else return false;
});

//(record-rtd record)    procedure
define_libfunc("record-rtd", 1, 1, function (ar) {
  assert_record(ar[0]);
  return ar[0].rtd;
});

//(record-type-name rtd)    procedure
define_libfunc("record-type-name", 1, 1, function (ar) {
  assert_record_td(ar[0]);
  return ar[0].name;
});

//(record-type-parent rtd)    procedure
define_libfunc("record-type-parent", 1, 1, function (ar) {
  assert_record_td(ar[0]);
  return ar[0].parent_rtd;
});

//(record-type-uid rtd)    procedure
define_libfunc("record-type-uid", 1, 1, function (ar) {
  assert_record_td(ar[0]);
  return ar[0].uid;
});

//(record-type-generative? rtd)    procedure
define_libfunc("record-type-generative?", 1, 1, function (ar) {
  assert_record_td(ar[0]);
  return ar[0].generative;
});

//(record-type-sealed? rtd)    procedure
define_libfunc("record-type-sealed?", 1, 1, function (ar) {
  assert_record_td(ar[0]);
  return ar[0].sealed;
});

//(record-type-opaque? rtd)    procedure
define_libfunc("record-type-opaque?", 1, 1, function (ar) {
  assert_record_td(ar[0]);
  return ar[0].opaque;
});

//(record-type-field-names rtd)    procedure
define_libfunc("record-type-field-names", 1, 1, function (ar) {
  assert_record_td(ar[0]);
  return ar[0].fields.map(function (field) {
    return field.name;
  });
});

//(record-field-mutable? rtd k)    procedure
define_libfunc("record-field-mutable?", 2, 2, function (ar) {
  var rtd = ar[0],
    k = ar[1];
  assert_record_td(ar[0]);
  assert_integer(k);

  for (var _rtd = rtd.parent_rtd; _rtd; _rtd = _rtd.parent_rtd)
    k += _rtd.fields.length;

  return ar[0].fields[k].mutable;
});

//
// Chapter 7 Exceptions and conditions
//
//(with-exception-handler handler thunk)    procedure
//(guard (<variable>    syntax
//(raise obj)    procedure
define_libfunc("raise", 1, 1, function (ar) {
  throw new UserError(to_write$1(ar[0]));
});
//(raise-continuable obj)    procedure
//
//&condition    condition type
//(condition condition1 ...)    procedure
//(simple-conditions condition)    procedure
//(condition? obj)    procedure
//(condition-predicate rtd)    procedure
//(condition-accessor rtd proc)    procedure
//
//&message    condition type
//&warning    condition type
//&serious    condition type
//&error    condition type
//&violation    condition type
//&assertion    condition type
//&irritants    condition type
//&who    condition type
//&non-continuable    condition type
//&implementation-restriction    condition type
//&lexical    condition type
//&syntax    condition type
//&undefined    condition type

//
// Chapter 8 I/O
//
//  //    8  I/O
//  //        8.1  Condition types
//&i/o    condition type
//&i/o-read    condition type
//&i/o-write    condition type
//&i/o-invalid-position    condition type
//&i/o-filename    condition type
//&i/o-file-protection    condition type
//&i/o-file-is-read-only    condition type
//&i/o-file-already-exists    condition type
//&i/o-file-does-not-exist    condition type
//&i/o-port    condition type
//
//  //        8.2  Port I/O
//  //            8.2.1  File names
//  //(no function introduced)
//
//  //            8.2.2  File options
//(file-options <file-options symbol> ...)    syntax
//
//  //            8.2.3  Buffer modes
//(buffer-mode <buffer-mode symbol>)    syntax
//(buffer-mode? obj)    procedure
//
//  //            8.2.4  Transcoders
//(latin-1-codec)    procedure
//(utf-8-codec)    procedure
//(utf-16-codec)    procedure
//(eol-style <eol-style symbol>)    syntax
//(native-eol-style)    procedure
//&i/o-decoding    condition type
//&i/o-encoding    condition type
//(error-handling-mode <error-handling-mode symbol>)    syntax
//(make-transcoder codec)    procedure
//(make-transcoder codec eol-style)    procedure
//(make-transcoder codec eol-style handling-mode)    procedure
//(native-transcoder)    procedure
//(transcoder-codec transcoder)    procedure
//(transcoder-eol-style transcoder)    procedure
//(transcoder-error-handling-mode transcoder)    procedure
//(bytevector->string bytevector transcoder)    procedure
//(string->bytevector string transcoder)    procedure
//
//            8.2.5  End-of-file object
//-> 8.3 (eof-object)    procedure
//-> 8.3 (eof-object? obj)    procedure

//            8.2.6  Input and output ports
define_libfunc("port?", 1, 1, function (ar) {
  return ar[0] instanceof Port;
});
//(port-transcoder port)    procedure
define_libfunc("textual-port?", 1, 1, function (ar) {
  assert_port(ar[0]);
  return !ar[0].is_binary;
});
define_libfunc("binary-port?", 1, 1, function (ar) {
  assert_port(ar[0]);
  return ar[0].is_binary;
});
//(transcoded-port binary-port transcoder)    procedure
//(port-has-port-position? port)    procedure
//(port-position port)    procedure
//(port-has-set-port-position!? port)    procedure
//(set-port-position! port pos)    procedure
define_libfunc("close-port", 1, 1, function (ar) {
  assert_port(ar[0]);
  ar[0].close();
  return undef;
});
//(call-with-port port proc)    procedure
define_libfunc("call-with-port", 2, 2, function (ar) {
  var port = ar[0],
    proc = ar[1];
  assert_port(port);
  assert_closure(proc);

  return new Call(proc, [port], function (ar) {
    // Automatically close the port
    port.close();
    return ar[0]; // TODO: values
  });
});

//            8.2.7  Input ports
//8.3 (input-port? obj)    procedure
//(port-eof? input-port)    procedure
//(open-file-input-port filename)    procedure
//(open-bytevector-input-port bytevector)    procedure
//(open-string-input-port string)    procedure
//(standard-input-port)    procedure
//8.3 (current-input-port)    procedure
//(make-custom-binary-input-port id read!    procedure
//(make-custom-textual-input-port id read!    procedure
//
//  //            8.2.8  Binary input
//(get-u8 binary-input-port)    procedure
//(lookahead-u8 binary-input-port)    procedure
//(get-bytevector-n binary-input-port count)    procedure
//(get-bytevector-n! binary-input-port    procedure
//(get-bytevector-some binary-input-port)    procedure
//(get-bytevector-all binary-input-port)    procedure
//
//  //            8.2.9  Textual input
//(get-char textual-input-port)    procedure
//(lookahead-char textual-input-port)    procedure
//(get-string-n textual-input-port count)    procedure
//(get-string-n! textual-input-port string start count)    procedure
//(get-string-all textual-input-port)    procedure
//(get-line textual-input-port)    procedure
//(get-datum textual-input-port)    procedure
//
//            8.2.10  Output ports
//8.3 (output-port? obj)    procedure
//(flush-output-port output-port)    procedure
//(output-port-buffer-mode output-port)    procedure
//(open-file-output-port filename)    procedure
//(open-bytevector-output-port)    procedure
//(call-with-bytevector-output-port proc)    procedure
//(open-string-output-port)    procedure
//(call-with-string-output-port proc)    procedure
define_libfunc("call-with-string-output-port", 1, 1, function (ar) {
  var proc = ar[0];
  assert_procedure(proc);

  var port = new Port.StringOutput();

  return new Call(proc, [port], function (ar) {
    port.close();
    return port.output_string();
  });
});

//(standard-output-port)    procedure
//(standard-error-port)    procedure
//8.3 (current-output-port)    procedure
//8.3 (current-error-port)    procedure
//(make-custom-binary-output-port id    procedure
//(make-custom-textual-output-port id write! get-position set-position! close)
//  define_libfunc("make-custom-textual-output-port", 5, 5, function(ar){
//    assert_string(ar[0]);
//    assert_closure(ar[1]);
//    assert_closure(ar[2]);
//    assert_closure(ar[3]);
//    assert_closure(ar[4]);
//    return new Port(ar[0], ar[1], ar[2], ar[3], ar[4]);
//  })
//
//  //            8.2.11  Binary output
//(put-u8 binary-output-port octet)    procedure
//(put-bytevector binary-output-port bytevector)    procedure
//
//            8.2.12  Textual output
define_libfunc("put-char", 2, 2, function (ar) {
  assert_port(ar[0]);
  assert_char(ar[1]);
  ar[0].put_string(ar[1].value);
  return undef;
});
define_libfunc("put-string", 2, 2, function (ar) {
  assert_port(ar[0]);
  assert_string(ar[1]);
  ar[0].put_string(ar[1]);
  return undef;
});
define_libfunc("put-datum", 2, 2, function (ar) {
  assert_port(ar[0]);
  ar[0].put_string(to_write$1(ar[1]));
  return undef;
});
//
//  //            8.2.13  Input/output ports
//(open-file-input/output-port filename)    procedure
//(make-custom-binary-input/output-port    procedure
//(make-custom-textual-input/output-port    procedure
//
//  //        8.3  Simple I/O
define_libfunc("eof-object", 0, 0, function (ar) {
  return eof;
});
define_libfunc("eof-object?", 1, 1, function (ar) {
  return ar[0] === eof;
});
//(call-with-input-file filename proc)    procedure
//(call-with-output-file filename proc)    procedure
define_libfunc("input-port?", 1, 1, function (ar) {
  assert_port(ar[0]);
  return ar[0].is_input;
});
define_libfunc("output-port?", 1, 1, function (ar) {
  assert_port(ar[0]);
  return ar[0].is_output;
});
define_libfunc("current-input-port", 0, 0, function (ar) {
  return Port.current_input;
});
define_libfunc("current-output-port", 0, 0, function (ar) {
  return Port.current_output;
});
define_libfunc("current-error-port", 0, 0, function (ar) {
  return Port.current_error;
});
//(with-input-from-file filename thunk)    procedure
//(with-output-to-file filename thunk)    procedure
//(open-input-file filename)    procedure
//(open-output-file filename)    procedure
define_libfunc("close-input-port", 1, 1, function (ar) {
  assert_port(ar[0]);
  if (!ar[0].is_input)
    throw new BiwaError("close-input-port: port is not input port");
  ar[0].close();
  return undef;
});
define_libfunc("close-output-port", 1, 1, function (ar) {
  assert_port(ar[0]);
  if (!ar[0].is_output)
    throw new BiwaError("close-output-port: port is not output port");
  ar[0].close();
  return undef;
});
//(read-char)    procedure
//(peek-char)    procedure
define_libfunc("read", 0, 1, function (ar) {
  var port = ar[0] || Port.current_input;
  assert_port(port);

  return port.get_string(function (str) {
    return Interpreter.read(str);
  });
});

define_libfunc("write-char", 1, 2, function (ar) {
  var port = ar[1] || Port.current_output;
  assert_char(ar[0]);
  port.put_string(ar[0].value);
  return undef;
});
define_libfunc("newline", 0, 1, function (ar) {
  var port = ar[0] || Port.current_output;
  port.put_string("\n");
  return undef;
});
define_libfunc("display", 1, 2, function (ar) {
  var port = ar[1] || Port.current_output;
  port.put_string(to_display(ar[0]));
  return undef;
});
define_libfunc("write", 1, 2, function (ar) {
  var port = ar[1] || Port.current_output;
  assert_port(port);
  port.put_string(to_write$1(ar[0]));
  return undef;
});
define_libfunc("write-shared", 1, 2, function (ar) {
  var port = ar[1] || Port.current_output;
  assert_port(port);
  port.put_string(write_shared(ar[0]));
  return undef;
});
define_libfunc("write-simple", 1, 2, function (ar) {
  var port = ar[1] || Port.current_output;
  assert_port(port);
  port.put_string(write_simple(ar[0]));
  return undef;
});

//
// Chapter 9 File System
// Chapter 10 Command-line access and exit values
//
// see src/library/node_functions.js

//
// Chapter 11 Arithmetic
//
////        11.1  Bitwise operations
////        11.2  Fixnums
//(fixnum? obj)    procedure
//(fixnum-width)    procedure
//(least-fixnum)    procedure
//(greatest-fixnum)    procedure
//(fx=? fx1 fx2 fx3 ...)    procedure
//(fx>? fx1 fx2 fx3 ...)    procedure
//(fx<? fx1 fx2 fx3 ...)    procedure
//(fx>=? fx1 fx2 fx3 ...)    procedure
//(fx<=? fx1 fx2 fx3 ...)    procedure
//(fxzero? fx)    procedure
//(fxpositive? fx)    procedure
//(fxnegative? fx)    procedure
//(fxodd? fx)    procedure
//(fxeven? fx)    procedure
//(fxmax fx1 fx2 ...)    procedure
//(fxmin fx1 fx2 ...)    procedure
//(fx+ fx1 fx2)    procedure
//(fx* fx1 fx2)    procedure
//(fx- fx1 fx2)    procedure
//(fxdiv-and-mod fx1 fx2)    procedure
//(fxdiv fx1 fx2)    procedure
//(fxmod fx1 fx2)    procedure
//(fxdiv0-and-mod0 fx1 fx2)    procedure
//(fxdiv0 fx1 fx2)    procedure
//(fxmod0 fx1 fx2)    procedure
//(fx+/carry fx1 fx2 fx3)    procedure
//(fx-/carry fx1 fx2 fx3)    procedure
//(fx*/carry fx1 fx2 fx3)    procedure
//(fxnot fx)    procedure
//(fxand fx1 ...)    procedure
//(fxior fx1 ...)    procedure
//(fxxor fx1 ...)    procedure
//(fxif fx1 fx2 fx3)    procedure
//(fxbit-count fx)    procedure
//(fxlength fx)    procedure
//(fxfirst-bit-set fx)    procedure
//(fxbit-set? fx1 fx2)    procedure
//(fxcopy-bit fx1 fx2 fx3)    procedure
//(fxbit-field fx1 fx2 fx3)    procedure
//(fxcopy-bit-field fx1 fx2 fx3 fx4)    procedure
//(fxarithmetic-shift fx1 fx2)    procedure
//(fxarithmetic-shift-left fx1 fx2)    procedure
//(fxarithmetic-shift-right fx1 fx2)    procedure
//(fxrotate-bit-field fx1 fx2 fx3 fx4)    procedure
//(fxreverse-bit-field fx1 fx2 fx3)    procedure
//
////        11.3  Flonums
//(flonum? obj)    procedure
//(real->flonum x)    procedure
//(fl=? fl1 fl2 fl3 ...)    procedure
//(fl<? fl1 fl2 fl3 ...)    procedure
//(fl<=? fl1 fl2 fl3 ...)    procedure
//(fl>? fl1 fl2 fl3 ...)    procedure
//(fl>=? fl1 fl2 fl3 ...)    procedure
//(flinteger? fl)    procedure
//(flzero? fl)    procedure
//(flpositive? fl)    procedure
//(flnegative? fl)    procedure
//(flodd? ifl)    procedure
//(fleven? ifl)    procedure
//(flfinite? fl)    procedure
//(flinfinite? fl)    procedure
//(flnan? fl)    procedure
//(flmax fl1 fl2 ...)    procedure
//(flmin fl1 fl2 ...)    procedure
//(fl+ fl1 ...)    procedure
//(fl* fl1 ...)    procedure
//(fl- fl1 fl2 ...)    procedure
//(fl- fl)    procedure
//(fl/ fl1 fl2 ...)    procedure
//(fl/ fl)    procedure
//(flabs fl)    procedure
//(fldiv-and-mod fl1 fl2)    procedure
//(fldiv fl1 fl2)    procedure
//(flmod fl1 fl2)    procedure
//(fldiv0-and-mod0 fl1 fl2)    procedure
//(fldiv0 fl1 fl2)    procedure
//(flmod0 fl1 fl2)    procedure
//(flnumerator fl)    procedure
//(fldenominator fl)    procedure
//(flfloor fl)    procedure
//(flceiling fl)    procedure
//(fltruncate fl)    procedure
//(flround fl)    procedure
//(flexp fl)    procedure
//(fllog fl)    procedure
//(fllog fl1 fl2)    procedure
//(flsin fl)    procedure
//(flcos fl)    procedure
//(fltan fl)    procedure
//(flasin fl)    procedure
//(flacos fl)    procedure
//(flatan fl)    procedure
//(flatan fl1 fl2)    procedure
//(flsqrt fl)    procedure
//(flexpt fl1 fl2)    procedure
//&no-infinities    condition type
//&no-nans    condition type
//(fixnum->flonum fx)    procedure

////        11.4  Exact bitwise arithmetic
//(bitwise-not ei)    procedure
define_libfunc("bitwise-not", 1, 1, function (ar) {
  return ~ar[0];
});

//(bitwise-and ei1 ...)    procedure
define_libfunc("bitwise-and", 1, null, function (ar) {
  return ar.reduce(function (ret, item) {
    return ret & item;
  });
});

//(bitwise-ior ei1 ...)    procedure
define_libfunc("bitwise-ior", 1, null, function (ar) {
  return ar.reduce(function (ret, item) {
    return ret | item;
  });
});

//(bitwise-xor ei1 ...)    procedure
define_libfunc("bitwise-xor", 1, null, function (ar) {
  return ar.reduce(function (ret, item) {
    return ret ^ item;
  });
});

//(bitwise-if ei1 ei2 ei3)    procedure
define_libfunc("bitwise-if", 3, 3, function (ar) {
  return (ar[0] & ar[1]) | (~ar[0] & ar[2]);
});

//(bitwise-bit-count ei)    procedure
define_libfunc("bitwise-bit-count", 1, 1, function (ar) {
  var e = Math.abs(ar[0]),
    ret = 0;
  for (; e != 0; e >>= 1) {
    if (e & 1) ret++;
  }
  return ret;
});

//(bitwise-length ei)    procedure
define_libfunc("bitwise-length", 1, 1, function (ar) {
  var e = Math.abs(ar[0]),
    ret = 0;
  for (; e != 0; e >>= 1) {
    ret++;
  }
  return ret;
});

//(bitwise-first-bit-set ei)    procedure
define_libfunc("bitwise-first-bit-set", 1, 1, function (ar) {
  var e = Math.abs(ar[0]),
    ret = 0;
  if (e == 0) return -1;
  for (; e != 0; e >>= 1) {
    if (e & 1) return ret;
    ret++;
  }
});

//(bitwise-bit-set? ei1 ei2)    procedure
define_libfunc("bitwise-bit-set?", 2, 2, function (ar) {
  return !!(ar[0] & (1 << ar[1]));
});

//(bitwise-copy-bit ei1 n b)    procedure
define_libfunc("bitwise-copy-bit", 3, 3, function (ar) {
  var mask = 1 << ar[1];
  return (
    (mask & (ar[2] << ar[1])) | // Set n-th bit to b
    (~mask & ar[0])
  ); // and use ei1 for rest of the bits
});

//(bitwise-bit-field ei1 start end)    procedure
define_libfunc("bitwise-bit-field", 3, 3, function (ar) {
  var mask = ~(-1 << ar[2]); // Has 1 at 0...end
  return (mask & ar[0]) >> ar[1];
});

//(bitwise-copy-bit-field dst start end src)    procedure
define_libfunc("bitwise-copy-bit-field", 4, 4, function (ar) {
  var dst = ar[0],
    start = ar[1],
    end = ar[2],
    src = ar[3];
  var mask =
    ~(-1 << end) & // Has 1 at 0...end
    (-1 << start); // Clear 0...start
  return (mask & (src << start)) | (~mask & dst);
});

//(bitwise-arithmetic-shift ei1 ei2)    procedure
define_libfunc("bitwise-arithmetic-shift", 2, 2, function (ar) {
  return ar[1] >= 0 ? ar[0] << ar[1] : ar[0] >> -ar[1];
});

//(bitwise-arithmetic-shift-left ei1 ei2)    procedure
define_libfunc("bitwise-arithmetic-shift-left", 2, 2, function (ar) {
  return ar[0] << ar[1];
});

//(bitwise-arithmetic-shift-right ei1 ei2)    procedure
define_libfunc("bitwise-arithmetic-shift-right", 2, 2, function (ar) {
  return ar[0] >> ar[1];
});

//(bitwise-rotate-bit-field ei1 start end count)    procedure
define_libfunc("bitwise-rotate-bit-field", 4, 4, function (ar) {
  var n = ar[0],
    start = ar[1],
    end = ar[2],
    count = ar[3];
  var width = end - start;
  if (width <= 0) return n;

  count %= width;
  var orig_field = (~(-1 << end) & n) >> start;
  var rotated_field = (orig_field << count) | (orig_field >> (width - count));

  var mask = ~(-1 << end) & (-1 << start);
  return (mask & (rotated_field << start)) | (~mask & n);
});

//(bitwise-reverse-bit-field ei1 ei2 ei3)    procedure
define_libfunc("bitwise-reverse-bit-field", 3, 3, function (ar) {
  var ret = ar[0],
    n = ar[0],
    start = ar[1],
    end = ar[2];
  var orig_field = (~(-1 << end) & n) >> start;
  for (var i = 0; i < end - start; i++, orig_field >>= 1) {
    var bit = orig_field & 1;
    var setpos = end - 1 - i;
    var mask = 1 << setpos;
    ret = (mask & (bit << setpos)) | (~mask & ret);
  }
  return ret;
});

//
// Chapter 12 syntax-case
//

//
// Chapter 13 Hashtables
//

//13.1  Constructors
//(define h (make-eq-hashtale)
//(define h (make-eq-hashtable 1000))
define_libfunc("make-eq-hashtable", 0, 1, function (ar) {
  // Note: ar[1] (hashtable size) is just ignored
  return new Hashtable(Hashtable.eq_hash, Hashtable.eq_equiv);
});
//(make-eqv-hashtable)    procedure
//(make-eqv-hashtable k)    procedure
define_libfunc("make-eqv-hashtable", 0, 1, function (ar) {
  return new Hashtable(Hashtable.eqv_hash, Hashtable.eqv_equiv);
});
//(make-hashtable hash-function equiv)    procedure
//(make-hashtable hash-function equiv k)    procedure
define_libfunc("make-hashtable", 2, 3, function (ar) {
  assert_procedure(ar[0]);
  assert_procedure(ar[1]);
  return new Hashtable(ar[0], ar[1]);
});

//13.2  Procedures
// (hashtable? hash)
define_libfunc("hashtable?", 1, 1, function (ar) {
  return ar[0] instanceof Hashtable;
});
//(hashtable-size hash)
define_libfunc("hashtable-size", 1, 1, function (ar) {
  assert_hashtable(ar[0]);
  return ar[0].keys().length;
});

// Find a pair from a hashtable with given key.
//
// hash      - a BiwaScheme.Hashtable
// key       - an object
// callbacks - an object contains callback functions
//             .on_found     - function(pair, hashed)
//               pair   - [Object key, Object value]
//               hashed - Object hashed
//             .on_not_found - function(hashed)
//               hashed - Object hashed
//
// Returns an instance of BiwaScheme.Call.
const find_hash_pair = function (hash, key, callbacks) {
  // invoke hash proc
  return new Call(hash.hash_proc, [key], function (ar) {
    var hashed = ar[0];
    var candidate_pairs = hash.candidate_pairs(hashed);

    if (!candidate_pairs) {
      // shortcut: obviously not found
      return callbacks.on_not_found(hashed);
    }

    // search the exact key from candidates
    return Call.foreach(candidate_pairs, {
      call: function (pair) {
        // invoke the equivalence proc
        return new Call(hash.equiv_proc, [key, pair[0]]);
      },
      result: function (equal, pair) {
        if (equal) {
          // found
          return callbacks.on_found(pair, hashed);
        }
      },
      finish: function () {
        // not found
        return callbacks.on_not_found(hashed);
      },
    });
  });
};

//(hashtable-ref hash "foo" #f)
define_libfunc("hashtable-ref", 3, 3, function (ar) {
  var hash = ar[0],
    key = ar[1],
    ifnone = ar[2];
  assert_hashtable(hash);

  return find_hash_pair(hash, key, {
    on_found: function (pair) {
      return pair[1];
    },
    on_not_found: function (hashed) {
      return ifnone;
    },
  });
});

//(hashtable-set! hash "foo" '(1 2))
define_libfunc("hashtable-set!", 3, 3, function (ar) {
  var hash = ar[0],
    key = ar[1],
    value = ar[2];
  assert_hashtable(hash);
  assert(hash.mutable, "hashtable is not mutable");

  return find_hash_pair(hash, key, {
    on_found: function (pair) {
      pair[1] = value;
      return undef;
    },
    on_not_found: function (hashed) {
      hash.add_pair(hashed, key, value);
      return undef;
    },
  });
});

//(hashtable-delete! hash "foo")
define_libfunc("hashtable-delete!", 2, 2, function (ar) {
  var hash = ar[0],
    key = ar[1];
  assert_hashtable(hash);
  assert(hash.mutable, "hashtable is not mutable");

  return find_hash_pair(hash, key, {
    on_found: function (pair, hashed) {
      hash.remove_pair(hashed, pair);
      return undef;
    },
    on_not_found: function (hashed) {
      return undef;
    },
  });
});

//(hashtable-contains? hash "foo")
define_libfunc("hashtable-contains?", 2, 2, function (ar) {
  var hash = ar[0],
    key = ar[1];
  assert_hashtable(hash);

  return find_hash_pair(hash, key, {
    on_found: function (pair) {
      return true;
    },
    on_not_found: function (hashed) {
      return false;
    },
  });
});

//(hashtable-update! hashtable key proc default)    procedure
define_libfunc("hashtable-update!", 4, 4, function (ar) {
  var hash = ar[0],
    key = ar[1],
    proc = ar[2],
    ifnone = ar[3];
  assert_hashtable(hash);
  assert(hash.mutable, "hashtable is not mutable");
  assert_procedure(proc);

  return find_hash_pair(hash, key, {
    on_found: function (pair, hashed) {
      // invoke proc and get new value
      return new Call(proc, [pair[1]], function (ar) {
        // replace the value
        pair[1] = ar[0];
        return undef;
      });
    },
    on_not_found: function (hashed) {
      // invoke proc and get new value
      return new Call(proc, [ifnone], function (ar) {
        // create new pair
        hash.add_pair(hashed, key, ar[0]);
        return undef;
      });
    },
  });
});
//(hashtable-copy hashtable)    procedure
//(hashtable-copy hashtable mutable)    procedure
define_libfunc("hashtable-copy", 1, 2, function (ar) {
  var mutable = ar[1] === undefined ? false : !!ar[1];
  assert_hashtable(ar[0]);
  return ar[0].create_copy(mutable);
});
//(hashtable-clear! hashtable)    procedure
//(hashtable-clear! hashtable k)    procedure
define_libfunc("hashtable-clear!", 0, 1, function (ar) {
  assert_hashtable(ar[0]);
  assert(ar[0].mutable, "hashtable is not mutable");
  ar[0].clear();
  return undef;
});
//(hashtable-keys hash)  ; => vector
define_libfunc("hashtable-keys", 1, 1, function (ar) {
  assert_hashtable(ar[0]);
  return ar[0].keys();
});
//(hashtable-entries hash)  ; => two vectors (keys, values)
define_libfunc("hashtable-entries", 1, 1, function (ar) {
  assert_hashtable(ar[0]);
  return new Values([ar[0].keys(), ar[0].values()]);
});

//13.3  Inspection

//(hashtable-equivalence-function hashtable)    procedure
define_libfunc("hashtable-equivalence-function", 1, 1, function (ar) {
  assert_hashtable(ar[0]);
  return ar[0].equiv_proc;
});
//(hashtable-hash-function hashtable)    procedure
define_libfunc("hashtable-hash-function", 1, 1, function (ar) {
  assert_hashtable(ar[0]);
  return ar[0].hash_proc;
});
//(hashtable-mutable? hashtable)    procedure
define_libfunc("hashtable-mutable?", 1, 1, function (ar) {
  assert_hashtable(ar[0]);
  return ar[0].mutable;
});

//13.4  Hash functions

//(equal-hash obj)    procedure
define_libfunc("equal-hash", 0, 0, function (ar) {
  return Hashtable.equal_hash;
});
//(string-hash string)    procedure
define_libfunc("string-hash", 0, 0, function (ar) {
  return Hashtable.string_hash;
});
//(string-ci-hash string)    procedure
define_libfunc("string-ci-hash", 0, 0, function (ar) {
  return Hashtable.string_ci_hash;
});
//(symbol-hash symbol)    procedure
define_libfunc("symbol-hash", 0, 0, function (ar) {
  return Hashtable.symbol_hash;
});

//
// Chapter 14 Enumerators
//
//(make-enumeration symbol-list) -> enum-set(new type)
define_libfunc("make-enumeration", 1, 1, function (ar) {
  assert_list(ar[0]);
  var members = ar[0].to_array();
  var enum_type = new Enumeration.EnumType(members);
  return enum_type.universe();
});

//(enum-set-universe enum-set) -> enum-set(same type as the argument)
define_libfunc("enum-set-universe", 1, 1, function (ar) {
  assert_enum_set(ar[0]);
  return ar[0].enum_type.universe();
});

//(enum-set-indexer enum-set) -> (lambda (sym)) -> integer or #f
define_libfunc("enum-set-indexer", 1, 1, function (ar) {
  assert_enum_set(ar[0]);
  return ar[0].enum_type.indexer();
});

//(enum-set-constructor enum-set) -> (lambda (syms)) -> enum-set(same type as the argument)
define_libfunc("enum-set-constructor", 1, 1, function (ar) {
  assert_enum_set(ar[0]);
  return ar[0].enum_type.constructor_();
});

//(enum-set->list enum-set) -> symbol-list
define_libfunc("enum-set->list", 1, 1, function (ar) {
  assert_enum_set(ar[0]);
  return ar[0].symbol_list();
});

//(enum-set-member? symbol enum-set) -> bool
define_libfunc("enum-set-member?", 2, 2, function (ar) {
  assert_symbol(ar[0]);
  assert_enum_set(ar[1]);
  return ar[1].is_member(ar[0]);
});

//(enum-set-subset? esa esb) -> bool
define_libfunc("enum-set-subset?", 2, 2, function (ar) {
  assert_enum_set(ar[0]);
  assert_enum_set(ar[1]);
  return ar[0].is_subset(ar[1]);
});

//(enum-set=? esa esb) -> bool
define_libfunc("enum-set=?", 2, 2, function (ar) {
  assert_enum_set(ar[0]);
  assert_enum_set(ar[1]);
  return ar[0].equal_to(ar[1]);
});

//(enum-set-union es1 es2) -> enum-set
define_libfunc("enum-set-union", 2, 2, function (ar) {
  assert_enum_set(ar[0]);
  assert_enum_set(ar[1]);
  assert(
    ar[0].enum_type === ar[1].enum_type,
    "two enum-sets must be the same enum-type",
    "enum-set-union"
  );
  return ar[0].union(ar[1]);
});

//(enum-set-intersection es1 es2) -> enum-set
define_libfunc("enum-set-intersection", 2, 2, function (ar) {
  assert_enum_set(ar[0]);
  assert_enum_set(ar[1]);
  return ar[0].intersection(ar[1]);
});

//(enum-set-difference es1 es2) -> enum-set
define_libfunc("enum-set-difference", 2, 2, function (ar) {
  assert_enum_set(ar[0]);
  assert_enum_set(ar[1]);
  return ar[0].difference(ar[1]);
});

//(enum-set-complement enum-set) -> enum-set
define_libfunc("enum-set-complement", 1, 1, function (ar) {
  assert_enum_set(ar[0]);
  return ar[0].complement();
});

//(enum-set-projection esa esb) -> enum-set
define_libfunc("enum-set-projection", 2, 2, function (ar) {
  assert_enum_set(ar[0]);
  assert_enum_set(ar[1]);
  return ar[0].projection(ar[1]);
});

//(define-enumeration <type-name> (<symbol> ...) <constructor-syntax>)
// Example:
//   (define-enumeration color (red green black white) color-set)
//   this defines:
//     - an EnumType
//     - (color red) ;=> 'red
//     - (color-set red black) ;=> #<enum-set (red black)>
define_syntax("define-enumeration", function (x) {
  // Extract parameters
  var type_name = x.cdr.car;
  assert(
    isSymbol(type_name),
    "expected symbol for type_name",
    "define-enumeration"
  );
  type_name = type_name.name;

  var members = x.cdr.cdr.car;
  assert(
    isList(members),
    "expected list of symbol for members",
    "define-enumeration"
  );
  members = members.to_array();

  var constructor_name = x.cdr.cdr.cdr.car;
  assert(
    isSymbol(constructor_name),
    "expected symbol for constructor_name",
    "define-enumeration"
  );
  constructor_name = constructor_name.name;

  // Define EnumType
  var enum_type = new Enumeration.EnumType(members);

  // Define (color red)
  define_syntax(type_name, function (x) {
    // (color)
    assert(!isNil(x.cdr), "an argument is needed", type_name);

    var arg = x.cdr.car;
    assert_symbol(arg, type_name);

    // Check arg is included in the universe
    assert(
      enum_type.members.includes(arg),
      arg.name +
        " is not included in the universe: " +
        to_write$1(enum_type.members),
      type_name
    );

    return List(Sym("quote"), arg);
  });

  // Define (color-set red black)
  define_syntax(constructor_name, function (x) {
    assert_list(x.cdr, constructor_name);

    var symbols = x.cdr.to_array();

    // Check each argument is included in the universe
    symbols.forEach(function (arg) {
      assert_symbol(arg, constructor_name);
      assert(
        enum_type.members.includes(arg),
        arg.name +
          " is not included in the universe: " +
          to_write$1(enum_type.members),
        constructor_name
      );
    });

    // Create an EnumSet
    return new Enumeration.EnumSet(enum_type, symbols);
  });
});

//
// Chapter 15 Composite library
//
//(rnrs 6) = all - eval - mutable pairs - mutable strings - r5rs compatibility

//
// Chapter 16 eval
//
//(eval expression environment)    procedure
define_libfunc("eval", 1, 1, function (ar, intp) {
  //TODO: environment
  //TODO: this implementation has a bug that
  //  expressions which contains #<undef>, etc. cannot be evaluated.
  var expr = ar[0];
  var intp2 = new Interpreter(intp);
  return intp2.evaluate(to_write$1(expr));
});
//(environment import-spec ...)    procedure

//
// Chapter 17 Mutable pairs
//
//(set-car! pair obj)    procedure
//(set-cdr! pair obj)    procedure

//
// Chapter 18 Mutable strings
//
//(string-set! string k char)    procedure
// (string-fill! string char)    procedure

//
// Chapter 19 R5RS compatibility
//
//(exact->inexact z)    procedure
//(inexact->exact z)    procedure
//
//(null-environment n)    procedure
//(scheme-report-environment n)    procedure

//
// R7RS (TODO: split file?)
//

// R7RS Promise
//
// (delay expression)
define_syntax("delay", function (x) {
  if (x.cdr === nil) {
    throw new BiwaError("malformed delay: no argument");
  }
  if (x.cdr.cdr !== nil) {
    throw new BiwaError("malformed delay: too many arguments: " + write_ss(x));
  }
  var expr = x.cdr.car;
  // Expand into call of internal function
  // ( procedure->promise (lambda () (make-promise expr)))
  return new Pair(
    Sym(" procedure->promise"),
    new Pair(
      new Pair(
        Sym("lambda"),
        new Pair(
          nil,
          new Pair(new Pair(Sym("make-promise"), new Pair(expr, nil)), nil)
        )
      )
    )
  );
});

// (delay-force promise-expr)
define_syntax("delay-force", function (x) {
  if (x.cdr === nil) {
    throw new BiwaError("malformed delay-force: no argument");
  }
  if (x.cdr.cdr !== nil) {
    throw new BiwaError(
      "malformed delay-force: too many arguments: " + write_ss(x)
    );
  }
  var expr = x.cdr.car;
  // Expand into call of internal function
  // ( procedure->promise (lambda () expr))
  return new Pair(
    Sym(" procedure->promise"),
    new Pair(new Pair(Sym("lambda"), new Pair(nil, new Pair(expr, nil))), nil)
  );
});

// (force promise)
var force = function (promise) {
  if (promise.is_done()) {
    return promise.value();
  }
  return new Call(promise.thunk(), [], function (ar) {
    assert_promise(ar[0]);
    var new_promise = ar[0];
    if (promise.is_done()) {
      // reentrant!
      return promise.value();
    } else {
      promise.update_with(new_promise);
      return force(new_promise);
    }
  });
};
define_libfunc("force", 1, 1, function (ar, intp) {
  assert_promise(ar[0]);
  return force(ar[0]);
});

// (promise? obj)
define_libfunc("promise?", 1, 1, function (ar, intp) {
  return ar[0] instanceof BiwaPromise;
});

// (make-promise obj)
define_libfunc("make-promise", 1, 1, function (ar, intp) {
  var obj = ar[0];
  if (obj instanceof BiwaPromise) {
    return obj;
  } else {
    return BiwaPromise.done(obj);
  }
});

// internal function
// ( procedure->promise proc)
// proc must be a procedure with no argument and return a promise
define_libfunc(" procedure->promise", 1, 1, function (ar, intp) {
  assert_procedure(ar[0]);
  return BiwaPromise.fresh(ar[0]);
});

//
// parameterize
//

// (make-parameter init)
// (make-parameter init converter)
define_libfunc("make-parameter", 1, 2, function (ar, intp) {
  let currentValue;
  const converter = ar[1];
  // A parameter is represented by a JS function.
  // (parameterObj)   ;=> Return the current value
  // (parameterObj v) ;=> Set v as the value and return the original value
  const parameterObj = function (ar2) {
    if (ar2.length == 0) {
      // Get
      return currentValue;
    } else {
      // Set
      const origValue = currentValue;
      if (converter) {
        return new Call(converter, [ar2[0]], (ar3) => {
          currentValue = ar3[0];
          return origValue;
        });
      } else {
        currentValue = ar2[0];
        return origValue;
      }
    }
  };

  if (converter) {
    return new Call(converter, [ar[0]], (initialValue) => {
      currentValue = initialValue;
      return parameterObj;
    });
  } else {
    const initialValue = ar[0];
    currentValue = initialValue;
    return parameterObj;
  }
});

// (parameterize ((param val) ...) body ...)
define_syntax("parameterize", function (x) {
  const inits = x.cdr.car.to_array();
  const body = x.cdr.cdr;
  const tmpNames = inits.map(() => gensym());
  // (let ((tmp0 val0) (tmp1 val1) ...)
  //   (dynamic-wind
  //     (lambda () (begin (set! tmp0 (param0 tmp0))) ...)
  //     (lambda () body ...)
  //     (lambda () (begin (set! tmp0 (param0 tmp0))) ...)))
  const givenValues = List(
    ...inits.map((item, i) => List(tmpNames[i], item.cdr.car))
  );
  const updateValues = Cons(
    Sym("begin"),
    List(
      ...inits.map((item, i) =>
        List(Sym("set!"), tmpNames[i], List(item.car, tmpNames[i]))
      )
    )
  );

  const before = List(Sym("lambda"), nil, updateValues);
  const thunk = Cons(Sym("lambda"), Cons(nil, body));
  const after = List(Sym("lambda"), nil, updateValues);
  //return List(Sym("quote"),
  return List(
    Sym("let"),
    givenValues,
    List(Sym("dynamic-wind"), before, thunk, after)
  );
  //)
});

//
// srfi.js - SRFI libraries
//
// should be src/library/srfi/1.js, etc (in the future).
//

//
// srfi-1 (list)
//
// (iota count start? step?)
define_libfunc("iota", 1, 3, function (ar) {
  var count = ar[0];
  var start = ar[1] || 0;
  var step = ar[2] === undefined ? 1 : ar[2];
  assert_integer(count);
  assert_number(start);
  assert_number(step);

  var a = [],
    n = start;
  for (var i = 0; i < count; i++) {
    a.push(n);
    n += step;
  }
  return array_to_list(a);
});

var copy_pair = function (pair) {
  var car = isPair(pair.car) ? copy_pair(pair.car) : pair.car;
  var cdr = isPair(pair.cdr) ? copy_pair(pair.cdr) : pair.cdr;
  return new Pair(car, cdr);
};
// (list-copy list)
define_libfunc("list-copy", 1, 1, function (ar) {
  if (isPair(ar[0])) {
    return copy_pair(ar[0]);
  } else {
    return nil;
  }
});

//
// srfi-6 & Gauche (string port)
//
define_libfunc("open-input-string", 1, 1, function (ar) {
  assert_string(ar[0]);
  return new Port.StringInput(ar[0]);
});
define_libfunc("open-output-string", 0, 0, function (ar) {
  return new Port.StringOutput();
});
define_libfunc("get-output-string", 1, 1, function (ar) {
  assert_port(ar[0]);
  if (!(ar[0] instanceof Port.StringOutput))
    throw new Error(
      "get-output-string: port must be made by 'open-output-string'"
    );
  return ar[0].output_string();
});

//
// srfi-8 (receive)
//

// (receive <formals> <expression> <body>...)
// -> (call-with-values (lambda () expression)
//                        (lambda formals body ...))
define_syntax("receive", function (x) {
  assert(isPair(x.cdr), "missing formals", "receive");
  var formals = x.cdr.car;
  assert(isPair(x.cdr.cdr), "missing expression", "receive");
  var expression = x.cdr.cdr.car;
  var body = x.cdr.cdr.cdr;

  return deep_array_to_list([
    Sym("call-with-values"),
    [Sym("lambda"), nil, expression],
    new Pair(Sym("lambda"), new Pair(formals, body)),
  ]);
});

// srfi-19 (time)
//
//  // constants
//time-duration
//time-monotonic
//time-process
//time-tai
//time-thread
//time-utc
// Current time and clock resolution
// (current-date [tz-offset])
define_libfunc("current-date", 0, 1, function (ar) {
  //todo: tz-offset (ar[1])
  return new Date();
});
//
//current-julian-day -> jdn
//current-modified-julian-day -> mjdn
//current-time [time-type] -> time
//time-resolution [time-type] -> integer
//  // Time object and accessors
//make-time type nanosecond second -> time
//time? object -> boolean
//time-type time -> time-type
//time-nanosecond time -> integer
//time-second time -> integer
//set-time-type! time time-type
//set-time-nanosecond! time integer
//set-time-second! time integer
//copy-time time1 -> time2
//  // Time comparison procedures
//time<=? time1 time2 -> boolean
//time<? time1 time2 -> boolean
//time=? time1 time2 -> boolean
//time>=? time1 time2 -> boolean
//time>? time1 time2 -> boolean
//  // Time arithmetic procedures
//time-difference time1 time2 -> time-duration
//time-difference! time1 time2 -> time-duration
//add-duration time1 time-duration -> time
//add-duration! time1 time-duration -> time
//subtract-duration time1 time-duration -> time
//subtract-duration! time1 time-duration -> time
// Date object and accessors
// make-date
define_libfunc("date?", 1, 1, function (ar) {
  return ar[0] instanceof Date;
});
define_libfunc("date-nanosecond", 1, 1, function (ar) {
  assert_date(ar[0]);
  return ar[0].getMilliseconds() * 1000000;
});
define_libfunc("date-millisecond", 1, 1, function (ar) {
  // not srfi-19
  assert_date(ar[0]);
  return ar[0].getMilliseconds();
});
define_libfunc("date-second", 1, 1, function (ar) {
  assert_date(ar[0]);
  return ar[0].getSeconds();
});
define_libfunc("date-minute", 1, 1, function (ar) {
  assert_date(ar[0]);
  return ar[0].getMinutes();
});
define_libfunc("date-hour", 1, 1, function (ar) {
  assert_date(ar[0]);
  return ar[0].getHours();
});
define_libfunc("date-day", 1, 1, function (ar) {
  assert_date(ar[0]);
  return ar[0].getDate();
});
define_libfunc("date-month", 1, 1, function (ar) {
  assert_date(ar[0]);
  return ar[0].getMonth() + 1; //Jan = 0 in javascript..
});
define_libfunc("date-year", 1, 1, function (ar) {
  assert_date(ar[0]);
  return ar[0].getFullYear();
});
//date-zone-offset
//date-year-day
define_libfunc("date-week-day", 1, 1, function (ar) {
  assert_date(ar[0]);
  return ar[0].getDay();
});
//date-week-number

// Time/Date/Julian Day/Modified Julian Day Converters
// (snipped)

// Date to String/String to Date Converters
// TODO: support locale
//   * date_names
//   * ~f 5.2 sec
//   * ~p AM/PM
//   * ~X 2007/01/01
const date_names = {
  weekday: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  full_weekday: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
  month: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  full_month: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "Octorber",
    "November",
    "December",
  ],
};

const date2string = function (date, format) {
  var zeropad = function (n) {
    return n < 10 ? "0" + n : "" + n;
  };
  var spacepad = function (n) {
    return n < 10 ? " " + n : "" + n;
  };
  var isoweeknum = function (x) {
    var janFour = new Date(x.getFullYear(), 0, 4);
    var weekone = new Date(x.getFullYear(), 0, 4);

    if (janFour.getDay() >= date_names.weekday.indexOf("Thu")) {
      weekone.setDate(janFour.getDate() - (janFour.getDay() + 1));
    } else {
      weekone.setDate(janFour.getDate() + (7 - janFour.getDay() + 1));
    }

    return Math.ceil((x - weekone) / 86400000 / 7);
  };

  var getter = {
    a: function (x) {
      return date_names.weekday[x.getDay()];
    },
    A: function (x) {
      return date_names.full_weekday[x.getDay()];
    },
    b: function (x) {
      return date_names.month[x.getMonth()];
    },
    B: function (x) {
      return date_names.full_month[x.getMonth()];
    },
    c: function (x) {
      return x.toString();
    },
    d: function (x) {
      return zeropad(x.getDate());
    },
    D: function (x) {
      return getter.d(x) + getter.m(x) + getter.y(x);
    },
    e: function (x) {
      return spacepad(x.getDate());
    },
    f: function (x) {
      return x.getSeconds() + x.getMilliseconds() / 1000;
    },
    h: function (x) {
      return date_names.month[x.getMonth()];
    },
    H: function (x) {
      return zeropad(x.getHours());
    },
    I: function (x) {
      var h = x.getHours();
      return zeropad(h < 13 ? h : h - 12);
    },
    j: function (x) {
      throw new Bug("not implemented: day of year");
    },
    k: function (x) {
      return spacepad(x.getHours());
    },
    l: function (x) {
      var h = x.getHours();
      return spacepad(h < 13 ? h : h - 12);
    },
    m: function (x) {
      return zeropad(x.getMonth() + 1);
    },
    M: function (x) {
      return zeropad(x.getMinutes());
    },
    n: function (x) {
      return "\n";
    },
    N: function (x) {
      throw new Bug("not implemented: nanoseconds");
    },
    p: function (x) {
      return x.getHours() < 13 ? "AM" : "PM";
    },
    r: function (x) {
      return (
        getter.I(x) + ":" + getter.M(x) + ":" + getter.S(x) + " " + getter.p(x)
      );
    },
    s: function (x) {
      return Math.floor(x.getTime() / 1000);
    },
    S: function (x) {
      return zeropad(x.getSeconds());
    },
    t: function (x) {
      return "\t";
    },
    T: function (x) {
      return getter.H(x) + ":" + getter.M(x) + ":" + getter.S(x);
    },
    U: function (x) {
      throw new Bug("not implemented: weeknum(0~, Sun)");
    },
    V: function (x) {
      return isoweeknum(x);
    },
    w: function (x) {
      return x.getDay();
    },
    W: function (x) {
      throw new Bug("not implemented: weeknum(0~, Mon)");
    },
    x: function (x) {
      throw new Bug("not implemented: weeknum(1~, Mon)");
    },
    X: function (x) {
      return getter.Y(x) + "/" + getter.m(x) + "/" + getter.d(x);
    },
    y: function (x) {
      return x.getFullYear() % 100;
    },
    Y: function (x) {
      return x.getFullYear();
    },
    z: function (x) {
      throw new Bug("not implemented: time-zone");
    },
    Z: function (x) {
      throw new Bug("not implemented: symbol time zone");
    },
    1: function (x) {
      throw new Bug("not implemented: ISO-8601 year-month-day format");
    },
    2: function (x) {
      throw new Bug(
        "not implemented: ISO-8601 hour-minute-second-timezone format"
      );
    },
    3: function (x) {
      throw new Bug("not implemented: ISO-8601 hour-minute-second format");
    },
    4: function (x) {
      throw new Bug(
        "not implemented: ISO-8601 year-month-day-hour-minute-second-timezone format"
      );
    },
    5: function (x) {
      throw new Bug(
        "not implemented: ISO-8601 year-month-day-hour-minute-second format"
      );
    },
  };

  return format.replace(/~([\w1-5~])/g, function (str, x) {
    var func = getter[x];
    if (func) return func(date);
    else if (x == "~") return "~";
    else return x;
  });
};

// date->string date template
define_libfunc("date->string", 1, 2, function (ar) {
  assert_date(ar[0]);

  if (ar[1]) {
    assert_string(ar[1]);
    return date2string(ar[0], ar[1]);
  } else return ar[0].toString();
});
// string->date

// parse-date date
define_libfunc("parse-date", 1, 1, function (ar) {
  // not srfi-19
  assert_string(ar[0]);
  return new Date(Date.parse(ar[0]));
});

//
// srfi-27
//
define_libfunc("random-integer", 1, 1, function (ar) {
  var n = ar[0];
  assert_integer(n);
  if (n < 0) throw new Error("random-integer: the argument must be >= 0");
  else return Math.floor(Math.random() * ar[0]);
});
define_libfunc("random-real", 0, 0, function (ar) {
  return Math.random();
});

//
// srfi-28 (format)
//

// (format format-str obj1 obj2 ...) -> string
// (format #f format-str ...) -> string
// (format #t format-str ...) -> output to current port
// (format port format-str ...) -> output to the port
//   ~a: display
//   ~s: write
//   ~%: newline
//   ~~: tilde
define_libfunc("format", 1, null, function (ar) {
  if (typeof ar[0] === "string") {
    var port = null,
      format_str = ar.shift();
  } else if (ar[0] === false) {
    ar.shift();
    var port = null,
      format_str = ar.shift();
  } else if (ar[0] === true) {
    ar.shift();
    var port = Port.current_output,
      format_str = ar.shift();
  } else {
    var port = ar.shift(),
      format_str = ar.shift();
    assert_port(port);
  }

  var str = format_str
    .replace(/~[as]/g, function (matched) {
      assert(ar.length > 0, "insufficient number of arguments", "format");
      if (matched == "~a") return to_display(ar.shift());
      else return to_write$1(ar.shift());
    })
    .replace(/~%/, "\n")
    .replace(/~~/, "~");
  if (port) {
    port.put_string(str);
    return undef;
  } else {
    return str;
  }
});

//
// srfi-38 (write/ss)
//
const user_write_ss = function (ar) {
  Console.puts(write_shared(ar[0]), true);
  return undef;
};
define_libfunc("write/ss", 1, 2, user_write_ss);
define_libfunc("write-with-shared-structure", 1, 2, user_write_ss);
define_libfunc("write*", 1, 2, user_write_ss); //from Gauche(STklos)

//
// srfi-43 (vector library)
//
define_libfunc("vector-append", 2, null, function (ar) {
  var vec = [];
  return vec.concat.apply(vec, ar);
});

// (vector-copy vector)
define_libfunc("vector-copy", 1, 1, function (ar) {
  assert_vector(ar[0]);
  return [...ar[0]];
});

//
// see src/library/node_functions.js for:
// - srfi-98 (get-environment-variable)
//

var BiwaScheme = {
  TopEnv,
  CoreEnv,
  nil,
  undef,
  max_trace_size,
  suppress_deprecation_warning,
  Version: VERSION,
  VERSION,
  GitCommit,
  eq,
  eqv,
  equal,
  isNil,
  isUndef,
  isBoolean,
  isString,
  isSymbol,
  isPort,
  isPair,
  isList,
  isVector,
  isHashtable,
  isMutableHashtable,
  isProcedure,
  lt,
  to_write: to_write$1,
  to_display,
  inspect,
  write_ss: write_shared,
  to_write_ss: write_shared, // For backward compatibility
  Call,
  Char,
  isChar,
  Closure,
  isClosure,
  Compiler,
  Enumeration,
  isEnumSet,
  Error: BiwaError,
  Bug,
  UserError,
  Hashtable,
  Interpreter,
  Complex,
  Rational: Rational$1,
  isNumber,
  isComplex,
  isReal,
  isRational,
  isInteger,
  Pair,
  List,
  array_to_list,
  deep_array_to_list,
  Cons,
  Parser,
  Pause,
  Port,
  eof,
  Promise: BiwaPromise,
  isPromise,
  Record,
  isRecord,
  isRecordTD,
  isRecordCD,
  Set: BiwaSet,
  Symbol: BiwaSymbol,
  Sym,
  gensym,
  Syntax,
  Values,
  VMCode,

  define_libfunc,
  define_scmfunc,
  parse_fraction,
  is_valid_integer_notation,
  parse_integer,
  is_valid_float_notation,
  parse_float,

  assert_number,
  assert_integer,
  assert_real,
  assert_between,
  assert_string,
  assert_char,
  assert_symbol,
  assert_port,
  assert_pair,
  assert_list,
  assert_vector,
  assert_hashtable,
  assert_mutable_hashtable,
  assert_promise,
  assert_function,
  assert_closure,
  assert_procedure,
  assert_date,
  assert,
};

Console.puts = function (str, no_newline) {
  Port.current_output.put_string(str + (no_newline ? "" : "\n"));
};

Console.p = function () {
  [].slice.call(arguments).forEach(Port.current_output.put_string);
};

const current_input = new Port.CustomInput(function (callback) {
  var rl = require("readline").createInterface({
    input: process.stdin,
  });
  rl.on("line", function (line) {
    rl.close();
    callback(line);
  });
  rl.setPrompt("", 0);
  rl.prompt();
});

const current_output = new Port.CustomOutput(function (str) {
  process.stdout.write(str);
});

const current_error = current_output;

const run = function (code, opts) {
  opts = opts || {};
  var intp = new Interpreter(function (e) {
    if (!opts["no_print"]) {
      if (e.stack) {
        console.error(e.stack);
      } else {
        console.error(e.toString ? e.toString() : e);
      }
    }

    throw e;
  });
  return intp.evaluate(code);
};

const run_file = function (filename, encoding /*optional*/) {
  var enc = encoding || "utf8";
  var src = require("fs").readFileSync(filename, enc);
  return run(src);
};

//
// Library functions only work on Node.js
// see also: test/node_functions.js
//

const node = {
  fs: require("fs"),
  path: require("path"),
  process: process,
};

//
// Chapter 9 File System
//

//(file-exists? filename)    procedure
define_libfunc("file-exists?", 1, 1, function (ar) {
  assert_string(ar[0]);
  return node.fs.existsSync(ar[0]);
});

//(delete-file filename)    procedure
define_libfunc("delete-file", 1, 1, function (ar) {
  assert_string(ar[0]);
  node.fs.unlinkSync(ar[0]);
  return undef;
});

//
// Chapter 10 Command-line access and exit values
//

//(command-line)    procedure
define_libfunc("command-line", 0, 0, function (ar) {
  return List.apply(null, node.process.argv);
});

//(exit)    procedure
//(exit obj)    procedure
define_libfunc("exit", 0, 1, function (ar) {
  var obj = ar[0];
  var code = typeof obj === "undefined" ? 0 : obj === false ? 1 : Number(obj);

  node.process.exit(code);
});

//
// srfi-98 (get-environment-variable)
//

// (get-environment-variable name) -> string or #f
define_libfunc("get-environment-variable", 1, 1, function (ar) {
  assert_string(ar[0]);
  var val = node.process.env[ar[0]];
  return typeof val === "undefined" ? false : val;
});

// (get-environment-variables) -> alist of string (("key" . "value"))
define_libfunc("get-environment-variables", 0, 0, function (ar) {
  return js_obj_to_alist(node.process.env);
});

//
// Extras
//

// (load scm-path)
define_libfunc("load", 1, 1, function (ar, intp) {
  var path = ar[0];
  assert_string(path);

  var fullpath;
  if (path[0] == "/" || /^\w:/.test(path)) fullpath = path;
  else fullpath = process.cwd() + "/" + path;

  const code = require("fs").readFileSync(fullpath, "utf8");
  intp.parser.insert(code);
  return undef;
});

// (js-load js-path)
define_libfunc("js-load", 1, 1, function (ar) {
  var path = ar[0];
  assert_string(path);

  var fullpath;
  if (path[0] == "/" || /^\w:/.test(path)) fullpath = path;
  else fullpath = process.cwd() + "/" + path;

  return require(fullpath);
});

// (node-require "fs")
define_libfunc("node-require", 1, 1, function (ar) {
  var name = ar[0];
  assert_string(name);

  return require(name);
});

BiwaScheme.on_node = true;
BiwaScheme.Console = Console;
BiwaScheme.Port.current_input = current_input;
BiwaScheme.Port.current_output = current_output;
BiwaScheme.Port.current_error = current_error;
BiwaScheme.run = run;
BiwaScheme.run_file = run_file;

module.exports = BiwaScheme;
